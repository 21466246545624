import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useBem from '../../../Hooks/useBem';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import InputField from '../../../Shared/Components/Form/InputField';
import { ButtonTypes } from '../../../Shared/Components/Button/enums';
import Button from '../../../Shared/Components/Button/Button';
import FillUpField from '../../../Shared/Components/Form/FillUpField';
import handleError from '../../../Shared/Functions/handleError';

import '../../../Shared/Styling/EditAddGrid.scss';
import { postPackagingGroup } from '../../../Shared/Data/PackagingGroupService';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';
import { FillUpSize } from '../../../Shared/Components/Form/enums';

const AddPackagingGroup = () => {
  if (!checkUserRight('entity.packaging_group.POST')) {
    return <NoPermission />;
  }

  const [bemClassName] = useBem('edit-add-grid');
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const submit = useCallback(async (e: any) => {
    const { value } = e.target.title;
    const packagingGroup = {
      title: value,
    };

    postPackagingGroup(packagingGroup as { title: string })
      .catch((err) => setErrorMessage(handleError(err)))
      .then((result: any) => {
        if (result && !errorMessage) {
          navigate(`/beheer/verpakkingsgroepen/`);
        }
      });
  }, []);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await submit(e);
  };

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {}}
            title="Verpakkingsgroep toevoegen"
            showBackButton
          />
          <div className={bemClassName()}>
            <div className={bemClassName('content')}>
              <h1 className={bemClassName('header')}>Gegevens</h1>

              <form
                method="post"
                onSubmit={(e) => submitHandler(e)}
              >
                <ul className={bemClassName('input-list')}>
                  <InputField
                    name="title"
                    label="Naam verpakkingsgroep"
                    placeholder="Verpakkingsgroep"
                  />
                  <FillUpField size={FillUpSize.Small} />

                  {errorMessage && <p className={bemClassName('error-message')}>{errorMessage}</p>}

                  <Button
                    submit
                    theme={ButtonTypes.Solid}
                    title="Verpakkingsgroep toevoegen"
                  />
                </ul>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AddPackagingGroup;
