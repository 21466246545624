import React from 'react';
import { Component } from '../../../../../Models/Types/Component';
import useBem from '../../../../../Hooks/useBem';
import useComponent from '../../../../../Shared/Hooks/useComponent';
import { ProductComponentType } from '../../../../../Models/Types/ProductComponentType';

type PropType = {
  component: ProductComponentType;
  percentage: string;
};

const ComponentRow = (props: PropType) => {
  const { component, percentage } = props;
  const { id } = component.component;
  const { data: componentData, isLoading } = useComponent(id);
  const [bemClassName, bemClassNames] = useBem('form-field');

  return (
    <tr
      className={bemClassName('table-grid-row')}
      key={id}
    >
      {isLoading ? (
        <td>Laden...</td>
      ) : (
        <>
          <td
            className={bemClassNames([bemClassName('table-grid-cell'), bemClassName('table-grid-cell', 'substance')])}
          >
            {(componentData as Component)?.name}
          </td>
          <td
            className={bemClassNames([bemClassName('table-grid-cell'), bemClassName('table-grid-cell', 'cas-number')])}
          >
            {(componentData as Component)?.cas_number}
          </td>
          <td className={bemClassNames([bemClassName('table-grid-cell'), bemClassName('table-grid-cell', 'h-codes')])}>
            {(componentData as Component)?.hazard_statements?.map(({ code }, index) => (
              <span
                key={code}
                className="code-cell"
              >
                {code}
                {index + 1 !== (componentData as Component)?.hazard_statements?.length && ', '}
              </span>
            ))}
          </td>
          <td
            className={bemClassNames([bemClassName('table-grid-cell'), bemClassName('table-grid-cell', 'percentage')])}
          >
            {percentage}
          </td>
        </>
      )}
    </tr>
  );
};

export default ComponentRow;
