import React, { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import useBem from '../../../Hooks/useBem';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import InputField from '../../../Shared/Components/Form/InputField';
import Button from '../../../Shared/Components/Button/Button';
import { ButtonTypes } from '../../../Shared/Components/Button/enums';
import SelectField from '../../../Shared/Components/Form/SelectField';
import { Statement, StatementResponse } from '../../../Models/Types/Statement';
import {
  generateClassSelectFieldList,
  generateStatementResponseSelectFieldList,
  generateStatementSelectFieldList,
} from '../../../Shared/Functions/generateStatements';
import useHazardStatement from '../../../Shared/Hooks/useHazardStatement';
import handleError from '../../../Shared/Functions/handleError';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';
import { SelectItem } from '../../../Models/Types/SelectItem';
import useComponent from '../../../Shared/Hooks/useComponent';
import { Component, ComponentType } from '../../../Models/Types/Component';
import { FillUpSize } from '../../../Shared/Components/Form/enums';
import FillUpField from '../../../Shared/Components/Form/FillUpField';
import { patchComponent } from '../../../Shared/Data/ComponentService';
import { HazardClass, HazardClassResponse } from '../../../Models/Types/HazardClass';
import useHazardClass from '../../../Shared/Hooks/useHazardClass';

import '../../../Shared/Styling/EditAddGrid.scss';

const EditComponent = () => {
  if (!checkUserRight('entity.hazard_class.PATCH')) {
    return <NoPermission />;
  }

  const [bemClassName] = useBem('edit-add-grid');
  const { id } = useParams();
  const { data: componentData, isLoading } = useComponent(id);

  // Hazard statements
  const { data: hazardStatements, isLoading: hazardStatementsLoading } = useHazardStatement();
  const [hazardStatementsOptions, setHazardStatementsOptions] = useState<SelectItem[] | undefined>(undefined);
  const [selectedHazardStatements, setSelectedHazardStatements] = useState<SelectItem[] | undefined>(undefined);

  // Hazard classes
  const { data: hazardClasses, isLoading: hazardClassesLoading } = useHazardClass();
  const [hazardClassOptions, setHazardClassOptions] = useState<SelectItem[] | undefined>(undefined);
  const [selectedHazardClasses, setSelectedHazardClasses] = useState<SelectItem[] | undefined>(undefined);

  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  const submit = useCallback(
    async (e: any) => {
      const hazardStatementsValues = selectedHazardStatements?.map((s) => s.value);

      const { name, cas_number, einics_number } = e.target;
      const component = {
        name: name.value,
        hazard_statements: hazardStatementsValues,
        cas_number: cas_number.value,
        einics_number: einics_number.value,
        hazard_class: selectedHazardClasses?.map((h) => h.value),
      };

      patchComponent(component as ComponentType, id!)
        .catch((err) => {
          setSuccessMessage(undefined);
          setErrorMessage(handleError(err));
        })
        .then((result: any) => {
          if (result) {
            setErrorMessage(undefined);
            setSuccessMessage('Component succesvol aangepast');

            queryClient.invalidateQueries({ queryKey: [`component-${id}`] });
          }
        });
    },
    [selectedHazardStatements, selectedHazardClasses],
  );

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await submit(e);
  };

  const updateHazardStatementList = async (hClasses: SelectItem[] | undefined) => {
    setSelectedHazardClasses(hClasses);
  };

  if (!isLoading && !hazardStatementsLoading && hazardStatementsOptions === undefined) {
    setHazardStatementsOptions(
      generateStatementSelectFieldList((hazardStatements as StatementResponse)?.items as Statement[]),
    );
    setSelectedHazardStatements(
      generateStatementSelectFieldList((componentData as Component)?.hazard_statements as Statement[]),
    );
  }

  if (!isLoading && !hazardClassesLoading && hazardClassOptions === undefined) {
    setHazardClassOptions(generateClassSelectFieldList((hazardClasses as HazardClassResponse).items as HazardClass[]));

    setSelectedHazardClasses(generateClassSelectFieldList((componentData as Component)?.hazard_class as HazardClass[]));
  }

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {}}
            title="Component aanpassen"
            showBackButton
          />
          <div className={bemClassName()}>
            <div className={bemClassName('content')}>
              <h1 className={bemClassName('header')}>Gegevens</h1>

              {isLoading || hazardStatementsLoading ? (
                <p>Loading...</p>
              ) : (
                <form
                  method="post"
                  onSubmit={(e) => submitHandler(e)}
                >
                  <ul className={bemClassName('input-list')}>
                    <InputField
                      name="name"
                      label="Naam component"
                      placeholder="Naam component"
                      value={(componentData as Component).name}
                    />
                    <InputField
                      name="cas_number"
                      label="CAS nummer"
                      placeholder="CAS nummer"
                      value={(componentData as Component).cas_number}
                    />
                    <InputField
                      name="einics_number"
                      label="EINICS nummer"
                      placeholder="EINICS nummer"
                      value={(componentData as Component).einics_number}
                    />
                    <SelectField
                      name="hazard_class"
                      label="Gevarenklasse"
                      placeholder="Begin met typen..."
                      allowMultiSelect
                      options={hazardClassOptions}
                      value={selectedHazardClasses}
                      onChangedValue={(selectedItems) =>
                        selectedItems && updateHazardStatementList(selectedItems as SelectItem[])
                      }
                    />

                    <SelectField
                      name="hazard_statements"
                      allowMultiSelect
                      options={generateStatementResponseSelectFieldList(hazardStatements as StatementResponse)}
                      wide
                      value={selectedHazardStatements}
                      onChangedValue={(selectedOptions) => {
                        setSelectedHazardStatements(selectedOptions as SelectItem[] | undefined);
                      }}
                      label="H-Codes"
                    />
                    <FillUpField size={FillUpSize.Small} />

                    {errorMessage && <p className={bemClassName('error-message')}>{errorMessage}</p>}
                    {successMessage && <p className={bemClassName('success-message')}>{successMessage}</p>}

                    <Button
                      submit
                      theme={ButtonTypes.Solid}
                      title="Component aanpassen"
                    />
                  </ul>
                </form>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default EditComponent;
