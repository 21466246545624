import React from 'react';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import TabBar from '../../../Shared/Components/TabBar/TabBar';
import { TabBarItem } from '../../../Shared/Components/TabBar/types';

type PropType = {
  onHandleTabBarClick: (value: string) => void;
  onHandleSearchRequest: (value: string) => void;
  onHandleEditClick?: () => void;
  showBackButton?: boolean;
  showEditButton?: boolean;
  showSearchBar?: boolean;
  title: string;
  tabBarItems: TabBarItem[];
};
const SubstancesRegisterHeader = (props: PropType) => {
  const {
    onHandleTabBarClick,
    onHandleSearchRequest,
    title,
    tabBarItems,
    showBackButton,
    showEditButton,
    showSearchBar,
    onHandleEditClick,
  } = props;

  return (
    <>
      <TitleWithSearchbar
        title={title}
        onHandleEnterKeyDown={(value) => onHandleSearchRequest(value)}
        showBackButton={showBackButton}
        showEditButton={showEditButton}
        showSearchBar={sessionStorage.getItem('userLogin') ? false : showSearchBar}
        onHandleEditClick={() => onHandleEditClick!()}
      />
      <TabBar
        tabBarItems={tabBarItems}
        onHandleClick={(value) => onHandleTabBarClick(value)}
      />
    </>
  );
};

SubstancesRegisterHeader.defaultProps = {
  showBackButton: false,
  showEditButton: false,
  showSearchBar: false,
  onHandleEditClick: () => {},
};

export default SubstancesRegisterHeader;
