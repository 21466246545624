import React from 'react';

import './Checkbox.scss';
import useBem from '../../../Hooks/useBem';
import CheckboxStyles from './enums';

type CheckboxType = {
  checkboxId: string;
  label: string;
  onToggleChecked: (id: string, checked: boolean) => void;
  boxChecked: boolean;
  parentName?: string;
  theme?: CheckboxStyles;
  showLabel?: boolean;
  imagePath?: string;
};

const Checkbox = ({
                    checkboxId,
                    label,
                    onToggleChecked,
                    boxChecked,
                    parentName = undefined,
                    showLabel = false,
                    theme = CheckboxStyles.Default,
                    imagePath = undefined,
                  }: CheckboxType) => {
  const [bemClassName, bemClassNames] = useBem('checkbox-wrapper');

  const toggleChecked = () => {
    onToggleChecked(checkboxId, !boxChecked);
  };

  return (
    <div className="checkbox-wrapper">
      <input
        className={bemClassNames([bemClassName('checkbox'), bemClassName('checkbox', 'blue', theme === 'blue')])}
        id={parentName === undefined ? checkboxId : `${parentName}-${checkboxId}`}
        type="checkbox"
        checked={boxChecked}
        title={label}
        onChange={() => toggleChecked()}
      />
      {showLabel && (
        <label
          className={bemClassName('label')}
          htmlFor={parentName === undefined ? checkboxId : `${parentName}-${checkboxId}`}
        >
          {imagePath && (
            <img
              src={imagePath}
              alt={label}
            />
          )}
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
