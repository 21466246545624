import React from 'react';
import PrecautionaryStatementsFilterBar from './PrecautionaryStatementsFilterBar';
import PrecautionaryStatementsList from './PrecautionaryStatementsList';

import './PrecautionaryStatementsList.scss';

type PropType = {
  handleOpenModal: (id: string) => void;
};
const PrecautionaryStatementsListContent = (props: PropType) => {
  const { handleOpenModal } = props;

  return (
    <>
      <PrecautionaryStatementsFilterBar />
      <div className="list-container">
        <PrecautionaryStatementsList handleOpenModal={(id) => handleOpenModal(id)} />
      </div>
    </>
  );
};

export default PrecautionaryStatementsListContent;
