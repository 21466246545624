import React, { useState } from 'react';
import useBem from '../../../../../Hooks/useBem';
import Button from '../../../../../Shared/Components/Button/Button';
import { ButtonTypes } from '../../../../../Shared/Components/Button/enums';
import { HospitalProductLocationType } from '../../../../../Models/Types/HospitalProductLocationType';
import LocationTypes from '../../../../../Models/Enums/LocationTypes';
import { FillUpSize } from '../../../../../Shared/Components/Form/enums';
import InputField from '../../../../../Shared/Components/Form/InputField';

import './ListTable.scss';
import LocationSelector from '../../../../../Shared/Components/LocationSelector/LocationSelector';
import { SelectItem } from '../../../../../Models/Types/SelectItem';

type PropType = {
  values?: HospitalProductLocationType[];
  onDelete?: (substanceId: string) => void;
  onAdd: (productLocationItem: HospitalProductLocationType) => void;
  message: string | undefined;
};

const LocationsTable = (props: PropType) => {
  const [bemClassName, bemClassNames] = useBem('form-field');
  const { values, onDelete, onAdd, message } = props;

  const [selectedDepartment, setSelectedDepartment] = useState<SelectItem>();
  const [selectedRoom, setSelectedRoom] = useState<SelectItem>();
  const [selectedCloset, setSelectedCloset] = useState<SelectItem>();
  const [locationAmount, setLocationAmount] = useState<string>();

  function changeProductLocations() {
    const location: string | undefined = selectedCloset?.value || selectedRoom?.value || selectedDepartment?.value;
    if (location) {
      const productLocationItem: HospitalProductLocationType = {
        location,
        location_name: selectedCloset?.label || selectedRoom?.label || selectedDepartment?.label || '',
        location_type:
          (selectedCloset && LocationTypes.Closet) ||
          (selectedRoom && LocationTypes.Room) ||
          (selectedDepartment && LocationTypes.Department) ||
          LocationTypes.Closet,
        amount: locationAmount || '',
        closet: selectedCloset ? { name: selectedCloset?.label } : undefined,
        room: selectedRoom ? { name: selectedRoom?.label } : undefined,
        department: selectedDepartment ? { name: selectedDepartment?.label } : undefined,
      };

      onAdd(productLocationItem);
    }
  }

  return (
    <div className={bemClassName('table', 'components')}>
      <table className={bemClassName('table-grid')}>
        <thead>
          <tr className={bemClassName('table-grid-row')}>
            <th className={bemClassName('table-grid-cell')}>Afdeling</th>
            <th className={bemClassName('table-grid-cell')}>Ruimte</th>
            <th className={bemClassName('table-grid-cell')}>Kast</th>
            <th className={bemClassName('table-grid-cell')}>Hoeveelheid</th>
          </tr>
        </thead>
        <tbody>
          {values?.map(({ location, amount, closet, room, department }: HospitalProductLocationType) => (
            <tr
              className={bemClassName('table-grid-row')}
              key={location}
            >
              <td className={bemClassNames([bemClassName('table-grid-cell')])}>{department?.name}</td>
              <td className={bemClassNames([bemClassName('table-grid-cell')])}>{room?.name}</td>
              <td className={bemClassNames([bemClassName('table-grid-cell')])}>{closet?.name}</td>
              <td className={bemClassName('table-grid-cell', 'delete')}>
                <span>{amount}</span>
                <button
                  type="button"
                  title="verwijderen"
                  aria-label="Delete row"
                  onClick={() => onDelete!(location)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12.269 15.774"
                  >
                    <path
                      fill="#5776b9"
                      d="M.876 14.022a1.758 1.758 0 0 0 1.753 1.753H9.64a1.758 1.758 0 0 0 1.753-1.753V3.505H.876ZM12.269.876H9.2L8.325 0H3.944l-.876.876H0v1.753h12.269Z"
                      data-name="Icon material-delete"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={bemClassName('add')}>
        <LocationSelector
          selectedDepartment={selectedDepartment}
          setSelectedDepartment={setSelectedDepartment}
          selectedRoom={selectedRoom}
          setSelectedRoom={setSelectedRoom}
          selectedCloset={selectedCloset}
          setSelectedCloset={setSelectedCloset}
        />

        <InputField
          name="amount"
          label="Hoeveelheid"
          size={FillUpSize.ExtraSmall}
          onChange={(e) => setLocationAmount(e.target.value)}
        />

        <Button
          title=""
          theme={ButtonTypes.SolidBlue}
          iconPath="/Icons/addItem.svg"
          onHandleClick={() => changeProductLocations()}
        />
      </div>
      {message && <div className={bemClassName('message')}>{message}</div>}
      <p>Kies een locatie, enkel afdeling is verplicht.</p>
    </div>
  );
};

LocationsTable.defaultProps = {
  values: [],
  onDelete: () => {},
};

export default LocationsTable;
