import React from 'react';
import useBem from '../../../Hooks/useBem';
import Checkbox from '../Checkbox/Checkbox';
import CheckboxTypes from '../Checkbox/enums';

import './CheckboxList.scss';

type PropType = {
  name: string;
  label: string;
  selectedOptions: string[];
  options: CheckBoxItem[];
  onChangedSelection?: (selectedOptions: string[]) => void;
};
const CheckBoxList = (props: PropType) => {
  const { name, label, selectedOptions, options, onChangedSelection } = props;
  const [bemClassName] = useBem('checkbox-list');

  const handleToggleChecked = (id: string, checked: boolean) => {
    if (!checked) {
      const index = selectedOptions.indexOf(id);
      if (index !== -1) {
        selectedOptions.splice(index, 1);
      }
    } else {
      selectedOptions.push(id);
    }
    onChangedSelection!(selectedOptions);
  };

  return (
    <div className={bemClassName()}>
      <label
        className={bemClassName('label')}
        htmlFor={name}
      >
        {label}
      </label>
      <div className={bemClassName('checkbox-container')}>
        {options.map((option) => (
          <Checkbox
            key={option.value}
            checkboxId={option.value}
            parentName={name}
            label={option.label}
            boxChecked={!!selectedOptions.find((item) => item === option.value)}
            onToggleChecked={(id, checked) => handleToggleChecked(id, checked)}
            showLabel
            imagePath={option.imagePath}
            theme={CheckboxTypes.Blue}
          />
        ))}
        <input
          type="hidden"
          name={name}
          value={selectedOptions}
        />
      </div>
    </div>
  );
};

CheckBoxList.defaultProps = {
  onChangedSelection: () => {},
};

export default CheckBoxList;
