import http from '../../Utils/HttpClient';

const postFile = async (file: File, name: string, url: string) => {
  const formData = new FormData();
  formData.append('file', file);

  const data = await http.post(url, await file.arrayBuffer(), {
    headers: { 'Content-Disposition': `filename="${name}"`, 'Content-Type': 'application/octet-stream' },
  });

  if (!data) return null;
  return data;
};

export default postFile;
