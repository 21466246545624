import React from 'react';
import ComponentItem from '../../../../Shared/Components/CollapsableContent/SubComponents/ComponentItem';
import {getRoom} from '../../../../Shared/Data/RoomService';
import {getCloset} from '../../../../Shared/Data/ClosetService';

type PropType = {
  amount: string;
  locationId: string;
  location_name: string;
  location_type: string;
};
const Location = (props: PropType) => {
  const {amount, locationId, location_name, location_type} = props;

  const [name, setName] = React.useState<string>(location_name);

  if (location_type === 'room') {
    getRoom(locationId).then((room) => {
      setName(`${room?.name} (Afdeling: ${room?.department.name})`);
    });
  }

  if (location_type === 'closet') {
    getCloset(locationId).then((closet) => {
      setName(`${closet?.name} (Afdeling: ${closet?.room.department.name}, Ruimte: ${closet?.room.name})`);
    });
  }

  return (
    <ComponentItem
      key={locationId}
      item={{
        title: location_name,
        list: [
          `Locatie: ${name}`,
          amount && `Hoeveelheid: ${amount}`
        ],
      }}
    />
  );
};

export default Location;
