import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useBem from '../../../Hooks/useBem';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import InputField from '../../../Shared/Components/Form/InputField';
import { ButtonTypes } from '../../../Shared/Components/Button/enums';
import Button from '../../../Shared/Components/Button/Button';
import TextAreaInputField from '../../../Shared/Components/Form/TextAreaInputField';
import { postHazardStatement } from '../../../Shared/Data/HazardStatementService';
import handleError from '../../../Shared/Functions/handleError';

import '../../../Shared/Styling/EditAddGrid.scss';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';
import { FillUpSize } from '../../../Shared/Components/Form/enums';
import FillUpField from '../../../Shared/Components/Form/FillUpField';
import Checkbox from '../../../Shared/Components/Checkbox/Checkbox';

const AddHazardStatement = () => {
  if (!checkUserRight('entity.hazard_statement.POST')) {
    return <NoPermission />;
  }

  const [bemClassName] = useBem('edit-add-grid');
  const navigate = useNavigate();
  const [cmrChecked, setCmrChecked] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const submit = useCallback(async (e: any) => {
    const hazardStatement = {
      code: e.target.code.value,
      description: e.target.description.value,
      cmr: e.target.cmr.checked,
    };

    postHazardStatement(hazardStatement as { code: string; description: string })
      .catch((err) => setErrorMessage(handleError(err)))
      .then((result: any) => {
        if (result && !errorMessage) {
          navigate(`/beheer/h-zinnen/`);
        }
      });
  }, []);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await submit(e);
  };

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {}}
            title="H/EUH-zin toevoegen"
            showBackButton
          />
          <div className={bemClassName()}>
            <div className={bemClassName('content')}>
              <h1 className={bemClassName('header')}>Gegevens</h1>

              <form
                method="post"
                onSubmit={(e) => submitHandler(e)}
              >
                <ul className={bemClassName('input-list')}>
                  <InputField
                    name="code"
                    label="Naam H/EUH-zin"
                    placeholder="Naam H/EUH-zin"
                  />
                  <TextAreaInputField
                    name="description"
                    label="Beschrijving H/EUH-zin"
                  />
                  <FillUpField size={FillUpSize.Small} />
                  <Checkbox
                    boxChecked={cmrChecked}
                    onToggleChecked={() => setCmrChecked(!cmrChecked)}
                    label="CMR-stof"
                    checkboxId="cmr"
                    showLabel
                  />

                  <FillUpField size={FillUpSize.ExtraLarge} />

                  {errorMessage && <p className={bemClassName('error-message')}>{errorMessage}</p>}

                  <Button
                    submit
                    theme={ButtonTypes.Solid}
                    title="H/EUH-zin toevoegen"
                  />
                </ul>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AddHazardStatement;
