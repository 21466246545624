import { useQuery, UseQueryResult } from '@tanstack/react-query';
import getStatistics from '../Data/SideDashboardDataService';
import { StatisticsData } from '../../Features/SubstancesRegister/types';

const useSideDashboardData = (): UseQueryResult<StatisticsData, Error> =>
  useQuery({
    queryKey: ['statistics'],
    queryFn: () => getStatistics(),
  });

export default useSideDashboardData;
