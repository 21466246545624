import http from '../../Utils/HttpClient';

const getAllRooms = async (url?: URL) => {
  url?.searchParams.append('sort', 'name');
  const { data } = url ? await http.get(url.toString()) : ((await http.get(`room?sort=name`)) as any);

  if (!data) return null;
  return data;
};

const getRoom = async (id: string) => {
  const { data } = (await http.get(`room/${id}`)) as any;

  return data;
};

const patchRoom = async (room: { name: string }, id: string) => {
  const data = await http.patch(`room/${id}`, room);

  if (!data) return null;
  return data;
};

const unpublishRoom = async (id: string) => {
  const data = await http.patch(`room/${id}`, { status: false });

  if (!data) return null;
  return data;
};

const postRoom = async (room: { name: string; department: string }) => {
  const data = await http.post(`room`, room);

  if (!data) return null;
  return data;
};

export { postRoom, patchRoom, getRoom, getAllRooms, unpublishRoom };
