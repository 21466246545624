import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import HeaderSeperationBar from '../../../Shared/Components/HeaderSeperationBar/HeaderSeperationBar';
import OrganisationsListContent from './Components/OrganisationsList/OrganisationsListContent';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';

const Organisations = () => {
  if (!checkUserRight('entity.hospital.GET')) {
    return <NoPermission />;
  }
  const navigate = useNavigate();

  function handleSearchRequest(searchValue: string) {
    if (searchValue === '') {
      navigate(`/beheer/organisaties/`);
      window.location.reload();
    } else {
      navigate(`/beheer/organisaties/search/${searchValue}`);
    }
  }

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            title="Organisaties beheren"
            onHandleEnterKeyDown={(value) => handleSearchRequest(value)}
            showSearchBar
          />
          <HeaderSeperationBar />
          <OrganisationsListContent />
        </section>
      </div>
    </>
  );
};

export default Organisations;
