import React from 'react';
import useBem from '../../../Hooks/useBem';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import ContentCard from '../../../Shared/Components/ContentCard/ContentCard';
import useManual from '../../../Shared/Hooks/useManual';

import './Manuals.scss';
import Loading from '../../../Shared/Components/Loading/Loading';
import { ManualResponseType } from '../../../Models/Types/ManualType';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';

const Manuals = () => {
  if (!checkUserRight('entity.manual.GET')) {
    return <NoPermission />;
  }

  const [bemClassName] = useBem('manuals');
  const { data, isLoading } = useManual();

  if (isLoading) {
    return <Loading />;
  }

  const manuals = (data! as ManualResponseType).items;

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {}}
            title="Hulp & ondersteuning"
          />
          <div className={bemClassName()}>
            <div className={bemClassName('content-top')}>
              <h1 className={bemClassName('header')}>Handleidingen</h1>
            </div>
            <div className={bemClassName('content-bottom')}>
              {manuals.map(({ text, title, id, file }) => (
                <ContentCard
                  key={id}
                  title={title}
                  description={text}
                  url={file?.url}
                />
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Manuals;
