const returnSearchUrl = (api: string, searchvalue?: string) => {
  const base = window.__RUNTIME_CONFIG__.BACKEND_BASEURL;
  const url = new URL(`${base}${api}`);
  url.searchParams.append('page_limit', '20');

  if (searchvalue) url.searchParams.append('search', searchvalue);

  return url;
};

export default returnSearchUrl;
