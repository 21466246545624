import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getSubstanceOrganisationData } from '../Data/SubstanceService';
import { HospitalProductType, HospitalProductTypeResponse } from '../../Models/Types/HospitalProductType';

const useSubstanceOrganisationData = (
  id: string | undefined,
): UseQueryResult<HospitalProductType | HospitalProductTypeResponse, Error> =>
  useQuery({
    queryKey: [`substance-${id}-organisation-data`],
    queryFn: () => (id ? getSubstanceOrganisationData(id) : null),
  });

export default useSubstanceOrganisationData;
