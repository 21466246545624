import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { SelectItem } from '../../../Models/Types/SelectItem';
import { getAllDepartments } from '../../Data/DepartmentService';
import { Department } from '../../../Models/Types/Department';
import { Room } from '../../../Models/Types/Room';
import { Closet } from '../../../Models/Types/Closet';

import './LocationSelector.scss';
import useBem from '../../../Hooks/useBem';

enum SelectSubject {
  DEPARTMENT = 'department',
  ROOM = 'room',
  CLOSET = 'closet',
}

type LocationSelectorType = {
  setSelectedDepartment: (value?: SelectItem) => void;
  setSelectedRoom: (value?: SelectItem) => void;
  setSelectedCloset: (value?: SelectItem) => void;
  selectedDepartment: SelectItem | undefined;
  selectedRoom: SelectItem | undefined;
  selectedCloset: SelectItem | undefined;
};

const LocationSelector = ({
  selectedDepartment,
  setSelectedDepartment,
  selectedRoom,
  setSelectedRoom,
  selectedCloset,
  setSelectedCloset,
}: LocationSelectorType) => {
  const base = window.__RUNTIME_CONFIG__.BACKEND_BASEURL;
  const [departmentOptions, setDepartmentOptions] = useState<SelectItem[]>();
  const [roomOptions, setRoomOptions] = useState<SelectItem[]>();
  const [closetOptions, setClosetOptions] = useState<SelectItem[]>();
  const [bemClassName] = useBem('location-selector');

  // Gets the selectoptions for the given selectSubject, searchValue is used to filter the results.
  // Returns an array of SelectItems for the given selectSubject.
  const getSelectOptions = (selectSubject: SelectSubject, searchValue?: string) => {
    const url = new URL(`${base}${selectSubject}`);
    const options: SelectItem[] = [];
    url.searchParams.append('page_limit', '10');

    if (searchValue) {
      url.searchParams.append('search', searchValue);
    }

    if (selectSubject !== SelectSubject.DEPARTMENT && selectedDepartment) {
      url.searchParams.append('department', selectedDepartment.value);
    }

    if (selectSubject === SelectSubject.CLOSET && selectedRoom) {
      url.searchParams.append('room', selectedRoom.value);
    }

    getAllDepartments(url).then((response) => {
      response?.items.forEach((item: Department | Room | Closet) => {
        const { id, name } = item;
        options.push({ value: id, label: name });
      });

      switch (selectSubject) {
        case SelectSubject.DEPARTMENT:
          setDepartmentOptions(options);
          break;
        case SelectSubject.ROOM:
          setRoomOptions(options);
          break;
        case SelectSubject.CLOSET:
          setClosetOptions(options);
          break;
        default:
          break;
      }
    });
  };

  // Load the departments on the first render.
  useEffect(() => {
    getSelectOptions(SelectSubject.DEPARTMENT);
  }, []);

  useEffect(() => {
    if (selectedDepartment) {
      getSelectOptions(SelectSubject.ROOM);
    }
  }, [selectedDepartment]);

  useEffect(() => {
    if (selectedRoom) {
      getSelectOptions(SelectSubject.CLOSET);
    }
  }, [selectedRoom]);

  return (
    <div className={bemClassName()}>
      <strong>Locatie</strong>
      <div className={bemClassName('selects')}>
        <Select
          id="department-select"
          classNamePrefix="select-box"
          placeholder="Afdeling"
          options={departmentOptions}
          onInputChange={(value) => {
            getSelectOptions(SelectSubject.DEPARTMENT, value);
          }}
          onChange={(selectedItem) => {
            setSelectedDepartment(selectedItem || undefined);
            setSelectedCloset(undefined);
            setSelectedRoom(undefined);
          }}
          isClearable
        />
        {selectedDepartment && (
          <Select
            classNamePrefix="select-box"
            placeholder="Ruimte"
            options={roomOptions}
            onInputChange={(value) => {
              getSelectOptions(SelectSubject.ROOM, value);
            }}
            onChange={(selectedItem) => {
              setSelectedRoom(selectedItem || undefined);
              setSelectedCloset(undefined);
            }}
            value={(selectedRoom && selectedRoom) || null}
            isClearable
          />
        )}
        {selectedDepartment && selectedRoom && (
          <Select
            classNamePrefix="select-box"
            placeholder="Kast"
            options={closetOptions}
            onInputChange={(value) => {
              getSelectOptions(SelectSubject.CLOSET, value);
            }}
            onChange={(selectedItem) => {
              setSelectedCloset(selectedItem || undefined);
            }}
            value={(selectedCloset && selectedCloset) || null}
            isClearable
          />
        )}
      </div>
    </div>
  );
};

export default LocationSelector;
