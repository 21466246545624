import React from 'react';
import UsersFilterBar from './UsersListFilterBar';
import UsersList from './UsersList';
import { FilterListItem } from '../../../../SubstancesRegister/types';

import './UsersList.scss';

type PropType = {
  onHandleNewFilterList: (filterList: FilterListItem[]) => void;
  handleOpenModal: (id: string) => void;
};

const UsersListContent = (props: PropType) => {
  const { onHandleNewFilterList, handleOpenModal } = props;

  return (
    <>
      <UsersFilterBar onFilterListChange={(filterList) => onHandleNewFilterList(filterList)} />
      <div className="list-container">
        <UsersList handleOpenModal={(id) => handleOpenModal(id)} />
      </div>
    </>
  );
};

export default UsersListContent;
