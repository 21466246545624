import React from 'react';
import { useParams } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import { useReactToPrint } from 'react-to-print';
import useBem from '../../../../../Hooks/useBem';
import Button from '../../../../../Shared/Components/Button/Button';
import { ButtonTypes } from '../../../../../Shared/Components/Button/enums';

import './ShareSubstance.scss';
import { Substance } from '../../../../../Models/Types/Substance';

type PropType = {
  substanceData: Substance;
};
const ShareSubstance = ({ substanceData }: PropType) => {
  const [bemClassName] = useBem('share-substance');
  const { host } = window.location;
  const { id } = useParams();

  const contentToPrint = React.useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: 'QR code',
    removeAfterPrint: true,
  });

  return (
    <div className={bemClassName()}>
      <div className={bemClassName('content-top')}>
        <h1 className={bemClassName('header')}>Deelbare link genereren</h1>
      </div>
      <div className={bemClassName('content-bottom')}>
        <p>Deze link is voor iedereen te gebruiken.</p>
        <Button
          title="Kopieer link"
          theme={ButtonTypes.Solid}
          onHandleClick={() => {
            navigator.clipboard.writeText(`${host}/productenregister/detail/${id}`);
          }}
        />
      </div>

      <div
        ref={contentToPrint}
        className={bemClassName('qr-code')}
      >
        <h2>QR code voor {substanceData.name}</h2>
        <QRCodeSVG
          value={`${window.location.href}/EHBO`}
          size={512}
        />
      </div>
      <Button
        title="Print QR code"
        theme={ButtonTypes.Solid}
        onHandleClick={() => handlePrint(null, () => contentToPrint.current)}
      />
    </div>
  );
};

export default ShareSubstance;
