import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useBem from '../../../Hooks/useBem';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import InputField from '../../../Shared/Components/Form/InputField';
import { ButtonTypes } from '../../../Shared/Components/Button/enums';
import Button from '../../../Shared/Components/Button/Button';
import SelectField from '../../../Shared/Components/Form/SelectField';
import handleError from '../../../Shared/Functions/handleError';

import '../../../Shared/Styling/EditAddGrid.scss';
import { FileTypes, FillUpSize } from '../../../Shared/Components/Form/enums';
import UploadField from '../../../Shared/Components/Form/UploadField';
import FillUpField from '../../../Shared/Components/Form/FillUpField';
import { postSymbol } from '../../../Shared/Data/SymbolService';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';

const AddSymbol = () => {
  if (!checkUserRight('entity.symbol.POST')) {
    return <NoPermission />;
  }

  const [bemClassName] = useBem('edit-add-grid');
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const submit = useCallback(async (e: any) => {
    const { title, type, image } = e.target;
    const symbol = {
      title: title.value,
      type: type.value,
      image: image.value,
    };

    postSymbol(symbol as { title: string; type: string; image: string })
      .catch((err) => setErrorMessage(handleError(err)))
      .then((result: any) => {
        if (result && !errorMessage) {
          navigate(`/beheer/symbolen/`);
        }
      });
  }, []);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await submit(e);
  };

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {}}
            title="Symbool toevoegen"
            showBackButton
          />
          <div className={bemClassName()}>
            <div className={bemClassName('content')}>
              <h1 className={bemClassName('header')}>Gegevens</h1>

              <form
                method="post"
                onSubmit={(e) => submitHandler(e)}
              >
                <ul className={bemClassName('input-list')}>
                  <InputField
                    name="title"
                    label="Naam symbool"
                    placeholder="Naam symbool"
                  />
                  <SelectField
                    name="type"
                    label="Symbool type"
                    options={[
                      { value: 'ghs', label: 'GHS' },
                      { value: 'adr', label: 'ADR' },
                      { value: 'pbm', label: 'PBM' },
                    ]}
                  />
                  <FillUpField size={FillUpSize.Small} />
                  <UploadField
                    name="image"
                    label="Symbool"
                    type={FileTypes.Image}
                    showPreview
                    url="symbol/image"
                  />
                  <FillUpField size={FillUpSize.ExtraLarge} />
                  <Button
                    submit
                    theme={ButtonTypes.Solid}
                    title="Symbool toevoegen"
                  />
                </ul>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AddSymbol;
