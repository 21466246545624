import http from '../../Utils/HttpClient';
import { File } from '../../Models/Types/File';

const getAllHospitals = async (url: URL) => {
  const { data } = (await http.get(url.toString())) as any;

  return data;
};

const getHospital = async (id: string) => {
  const { data } = (await http.get(`hospital/${id}`)) as any;

  return data;
};

const postHospital = async (hospital: { name: string; logo: File }) => {
  const data = await http.post(`hospital`, hospital);

  if (!data) return null;
  return data;
};

export { getAllHospitals, getHospital, postHospital };
