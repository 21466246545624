import React, { SyntheticEvent, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CollapsableFormGroup from '../../../../Shared/Components/CollapsableContent/CollapsableComponentGroup';
import InputField from '../../../../Shared/Components/Form/InputField';
import TextAreaInputField from '../../../../Shared/Components/Form/TextAreaInputField';
import Button from '../../../../Shared/Components/Button/Button';
import { ButtonTypes } from '../../../../Shared/Components/Button/enums';
import { checkUserRight } from '../../../../Shared/Data/UserService';
import LocationsTable from './FormComponents/LocationsTable';
import useSubstanceOrganisationData from '../../../../Shared/Hooks/useSubstanceOrganistion';
import { HospitalProductLocationType } from '../../../../Models/Types/HospitalProductLocationType';
import NoPermission from '../../../../Shared/Components/NoPermission/NoPermission';
import { HospitalProductType } from '../../../../Models/Types/HospitalProductType';
import {
  patchSubstanceOrganisationData,
  postSubstanceOrganisationData,
} from '../../../../Shared/Data/SubstanceService';
import { FileTypes } from '../../../../Shared/Components/Form/enums';
import UploadField from '../../../../Shared/Components/Form/UploadField';

const GeneralInformation = () => {
  if (!checkUserRight('entity.hospital_product.PATCH')) {
    return <NoPermission />;
  }
  const { id } = useParams();
  const navigate = useNavigate();
  const { data } = useSubstanceOrganisationData(id);
  const [locations, setLocations] = useState<HospitalProductLocationType[] | undefined>(undefined);
  const [locationMessage, setLocationMessage] = useState<string | undefined>();

  const submit = useCallback(
    async (e: any, foundData: boolean) => {
      const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : undefined;
      const organisation = user?.hospital;

      if (foundData) {
        await postSubstanceOrganisationData(e, id!, organisation.id, locations).then((result: any) => {
          if (result) {
            navigate(`/productenregister/detail/${id}`);
          }
        });
      } else {
        await patchSubstanceOrganisationData(
          e,
          id!,
          (data as HospitalProductType).id!,
          organisation.id,
          locations,
        ).then((result: any) => {
          if (result) {
            navigate(`/productenregister/detail/${id}`);
          }
        });
      }
    },
    [locations],
  );

  const submitHandler = async (e: SyntheticEvent) => {
    e.preventDefault();
    submit(e, data === undefined);
  };

  if (locations === undefined && data) {
    setLocations((data as HospitalProductType)?.locations);
  }

  function addLocationItem(locationItem: HospitalProductLocationType) {
    if (locations?.some((l) => l.location === locationItem.location)) {
      setLocationMessage('Deze locatie is al toegevoegd.');
      return;
    }
    setLocationMessage(undefined);

    if (locations) {
      setLocations([...locations, locationItem]);
    } else {
      setLocations([locationItem]);
    }
  }

  return (
    <form
      method="post"
      onSubmit={(event) => submitHandler(event)}
    >
      <ul className="information-list">
        <CollapsableFormGroup
          title="1. Organisatiespecifieke informatie"
          open
        >
          <InputField
            name="name"
            label="Eigen artikelnaam"
            value={(data as HospitalProductType)?.name}
          />
          <TextAreaInputField
            name="waste_information_remarks"
            label="Afval informatie"
            value={(data as HospitalProductType)?.waste_information_remarks}
          />
          <UploadField
            name="document"
            label="Eigen document"
            type={FileTypes.Pdf}
            url="hospital-product/waste-information"
            document={(data as HospitalProductType)?.document}
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup
          title="2. Locaties"
          open
        >
          <LocationsTable
            values={locations}
            onDelete={(locationItem) => {
              setLocations(locations?.filter((m) => m.location !== locationItem));
            }}
            onAdd={(locationItem) => {
              addLocationItem(locationItem);
            }}
            message={locationMessage}
          />
        </CollapsableFormGroup>
      </ul>
      <div className="form-buttons">
        {checkUserRight('entity.hospital_product.POST') && (
          <Button
            submit
            theme={ButtonTypes.Solid}
            title="Wijzigingen opslaan"
          />
        )}
      </div>
    </form>
  );
};

export default GeneralInformation;
