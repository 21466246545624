import React, { useRef } from 'react';

import { useParams } from 'react-router-dom';
import { toODataString } from '@progress/kendo-data-query';
import { getAllSubstances } from '../../../../Shared/Data/SubstanceService';
import LoadingPanel from '../../../../Shared/Components/LoadingPanel/LoadingPanel';
import { ItemLoaderType } from '../../../../Models/Types/ItemLoaderType';
import { FilterListItem } from '../../types';
import { TabBarItem } from '../../../../Shared/Components/TabBar/types';
import { ProductTypes } from '../../enums';

const SubstancesLoader = (props: ItemLoaderType) => {
  const params = useParams();
  const { dataState, onDataReceived, onError } = props;
  const base = window.__RUNTIME_CONFIG__.BACKEND_BASEURL;
  const filterList = sessionStorage.filterList ? JSON.parse(sessionStorage.filterList) : [];
  const productTypes = sessionStorage.substanceRegisterCorrectTabBarItems
    ? JSON.parse(sessionStorage.substanceRegisterCorrectTabBarItems)
    : [];

  const url =
    params.search ||
    filterList.length > 0 ||
    // @ts-ignore
    productTypes.find(({ tab, active }: TabBarItem) => tab !== ProductTypes.All && active === true)
      ? new URL(`${base}search/product`)
      : new URL(`${base}search/product`);

  const lastSuccess = useRef<string>('');
  const pending = useRef<string>('');

  url.searchParams.append('page', dataState.skip ? (dataState.skip / 10).toString() : '0');
  url.searchParams.append('page_limit', '10');

  if (params.search) {
    url.searchParams.append('search', params.search);
  }

  if (filterList.length > 0) {
    filterList.forEach(({ filter, name }: FilterListItem) => {
      if (filter !== null) {
        const { value } = filter;
        url.searchParams.append(name, value);
      }
    });
  }

  if (dataState.sort && dataState.sort[0]) {
    const { field, dir } = dataState.sort[0];
    url.searchParams.append('sort', field);
    url.searchParams.append('order', dir ?? 'asc');
  }

  const userLogin = sessionStorage.getItem('userLogin');
  if (userLogin) {
    url.searchParams.append('userlogin', userLogin);
  }

  productTypes.forEach(({ tab, active }: TabBarItem) => {
    if (active) {
      // @ts-ignore
      if (tab !== ProductTypes.All) {
        url.searchParams.append('product_type', tab);
      }
    }
  });

  const requestDataIfNeeded = () => {
    if (pending.current || toODataString(dataState) === lastSuccess.current) {
      return;
    }

    pending.current = toODataString(dataState);
    getAllSubstances(url)
      .catch((error) => {
        switch (error.status) {
          case 500:
            onDataReceived.call(undefined, {
              data: [],
              total: 0,
            });

            onError([
              'Er ging iets fout op onze server.',
              'Probeer het later opnieuw of neem contact op met de beheerder.',
            ]);

            break;
          case 403:
            onDataReceived.call(undefined, {
              data: [],
              total: 0,
            });

            onError(['Authenticatie is vereist.', 'Log opnieuw in of gebruik een account met meer rechten. ']);

            break;
          default:
            onError(['Er ging iets fout.', 'Probeer het later opnieuw of neem contact op met de beheerder.']);
            break;
        }
      })
      .then((response) => {
        lastSuccess.current = pending.current;
        pending.current = '';
        if (response !== undefined) {
          if (toODataString(dataState) === lastSuccess.current) {
            onDataReceived.call(undefined, {
              data: response.items,
              total: response.total,
            });

            if (response.total === 0) {
              onError(['Geen data gevonden...']);
            }
          } else {
            requestDataIfNeeded();
          }
        }
      });
  };

  requestDataIfNeeded();
  return pending.current ? <LoadingPanel /> : null;
};

export default SubstancesLoader;
