import http from '../../Utils/HttpClient';
import { FilterListItem } from '../../Features/SubstancesRegister/types';

const getExportFields = async () => {
  const { data } = (await http.get('export/fields')) as any;
  return data;
};

const getExportProducts = async (fields?: string[]) => {
  const parsedFilters: object[] = [];
  if (sessionStorage.filterList) {
    const filterList = JSON.parse(sessionStorage.filterList);
    filterList.forEach((filter: FilterListItem) => {
      parsedFilters.push({ name: filter.name, value: filter.filter.value });
    });
  }

  const settings = window.btoa(JSON.stringify({ fields, filters: parsedFilters }));
  const { data } = (await http.get(`export?settings=${settings}`, { responseType: 'blob' })) as any;
  return data;
};

export { getExportFields, getExportProducts };
