import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import useBem from '../../../../../Hooks/useBem';
import Button from '../../../../../Shared/Components/Button/Button';
import { ButtonTypes } from '../../../../../Shared/Components/Button/enums';

import '../../../../../Shared/Styling/PdfView.scss';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

type PropType = {
  downloadUrl: string;
};

const SourceDocument = (props: PropType) => {
  const { downloadUrl } = props;
  const [bemClassName] = useBem('pdf-view');
  const [file, setFile] = useState<string | File | null>(downloadUrl);
  const [numPages, setNumPages] = useState<number>();

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.mjs`;

  function onFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;

    if (files && files[0]) {
      setFile(files[0] || null);
    }
  }

  // TODO: fix the any should be PDFDocumentProxy.
  function onDocumentLoadSuccess(item: any) {
    setNumPages(item.numPages);
  }

  return (
    <>
      <div className={bemClassName('content-top')}>
        <h1 className={bemClassName('header')}>Brondocument</h1>
        <Button
          theme={ButtonTypes.Solid}
          title="Downloaden"
          iconPath="/Icons/save.svg"
          onHandleClick={() => window.open(downloadUrl)}
        />
      </div>
      <div className={bemClassName('content-middle')}>
        <input
          onChange={(event) => onFileChange(event)}
          type="file"
        />
      </div>
      <div className={bemClassName('content-bottom')}>
        <Document
          file={file}
          onLoadSuccess={(ref) => onDocumentLoadSuccess(ref)}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>
      </div>
    </>
  );
};

export default SourceDocument;
