import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { Substance } from '../../Models/Types/Substance';
import { getSubstanceRevision } from '../Data/SubstanceService';

const useSubstance = (id: string, rev_id: number): UseQueryResult<Substance, Error> =>
  useQuery({
    queryKey: [`substance-${id}`],
    queryFn: () => getSubstanceRevision(id, rev_id),
  });

export default useSubstance;
