import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getAllRooms, getRoom } from '../Data/RoomService';

import { Room, RoomResponse } from '../../Models/Types/Room';

const useRoom = (id?: string, hospitalId?: string): UseQueryResult<Room | RoomResponse, Error> => {
  if (id) {
    return useQuery({
      queryKey: [`room-${id}`, 'rooms'],
      queryFn: () => getRoom(id),
      ...{ staleTime: undefined, cacheTime: 10 },
    });
  }

  return useQuery({
    queryKey: [`room-${hospitalId}`, 'rooms'],
    queryFn: () => getAllRooms(),
    ...{ staleTime: undefined, cacheTime: 10 }
  });
};

export default useRoom;
