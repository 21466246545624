import React from 'react';
import useBem from '../../../Hooks/useBem';

import './ContentCard.scss';

type PropType = {
  title: string;
  url: string;
  description: string;
};

const ContentCard = (props: PropType) => {
  const { title, url, description } = props;
  const [bemClassName] = useBem('content-card');

  return (
    <a
      className={bemClassName()}
      href={url}
      download={title}
      target="_blank"
      rel="noreferrer"
    >
      <div className={bemClassName('content')}>
        <h4 className={bemClassName('header')}>{title}</h4>
        <p className={bemClassName('description')}>{description}</p>
        <img
          className={bemClassName('arrow')}
          src="/Icons/openContentArrow.svg"
          alt="Naar handleiding"
        />
      </div>
    </a>
  );
};

export default ContentCard;
