import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useBem from '../../../Hooks/useBem';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import InputField from '../../../Shared/Components/Form/InputField';
import { ButtonTypes } from '../../../Shared/Components/Button/enums';
import Button from '../../../Shared/Components/Button/Button';
import handleError from '../../../Shared/Functions/handleError';

import '../../../Shared/Styling/EditAddGrid.scss';
import SelectField from '../../../Shared/Components/Form/SelectField';
import useHazardStatement from '../../../Shared/Hooks/useHazardStatement';
import { StatementResponse } from '../../../Models/Types/Statement';
import { postHazardClass } from '../../../Shared/Data/HazardClassService';
import { generateStatementResponseSelectFieldList } from '../../../Shared/Functions/generateStatements';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';
import { FillUpSize } from '../../../Shared/Components/Form/enums';
import FillUpField from '../../../Shared/Components/Form/FillUpField';

const AddHazardClass = () => {
  if (!checkUserRight('entity.hazard_class.POST')) {
    return <NoPermission />;
  }

  const [bemClassName] = useBem('edit-add-grid');
  const { data: hazardStatements, isLoading } = useHazardStatement();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const submit = useCallback(async (e: any) => {
    const { hazard_statements, title } = e.target;
    const hazardStatement = {
      title: title.value,
      hazard_statements: [hazard_statements.value],
    };

    postHazardClass(hazardStatement as { title: string; hazard_statements: string[] })
      .catch((err) => setErrorMessage(handleError(err)))
      .then((result: any) => {
        if (result && !errorMessage) {
          navigate(`/beheer/gevarenklassen/`);
        }
      });
  }, []);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await submit(e);
  };

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {}}
            title="Gevarenklasse toevoegen"
            showBackButton
          />
          <div className={bemClassName()}>
            <div className={bemClassName('content')}>
              <h1 className={bemClassName('header')}>Gegevens</h1>

              {isLoading ? (
                <p>Loading...</p>
              ) : (
                <form
                  method="post"
                  onSubmit={(e) => submitHandler(e)}
                >
                  <ul className={bemClassName('input-list')}>
                    <InputField
                      name="title"
                      label="Naam gevarenklasse"
                      placeholder="Naam gevarenklasse"
                    />
                    <SelectField
                      name="hazard_statements"
                      options={generateStatementResponseSelectFieldList(hazardStatements as StatementResponse)}
                      label="H-Code"
                    />
                    <FillUpField size={FillUpSize.Small} />

                    {errorMessage && <p className={bemClassName('error-message')}>{errorMessage}</p>}

                    <Button
                      submit
                      theme={ButtonTypes.Solid}
                      title="Gevarenklasse toevoegen"
                    />
                  </ul>
                </form>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AddHazardClass;
