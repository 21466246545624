import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Component, ComponentResponse } from '../../Models/Types/Component';
import { getComponent } from '../Data/ComponentService';

const useComponent = (id?: string): UseQueryResult<ComponentResponse | Component, Error> =>
  useQuery({
    queryKey: [`component-${id}`],
    queryFn: () => (id ? getComponent(id) : null),
  });
export default useComponent;
