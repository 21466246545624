import React from 'react';
import ProductConceptFilterBar from './ProductApprovalFilterBar';
import ProductApprovalList from './ProductApprovalList';

import './ProductConceptList.scss';

type PropType = {
  handleOpenModal: (id: string) => void;
};
const ProductApprovalListContent = (props: PropType) => {
  const { handleOpenModal } = props;

  return (
    <>
      <ProductConceptFilterBar />
      <div className="list-container">
        <ProductApprovalList handleOpenModal={(id) => handleOpenModal(id)} />
      </div>
    </>
  );
};

export default ProductApprovalListContent;
