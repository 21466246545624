import http from '../../Utils/HttpClient';

const getAllPrecautionaryStatements = async () => {
  const { data } = (await http.get(`precautionary-statement?page_limit=2500`)) as any;

  return data;
};

const getPrecautionaryStatement = async (id: string) => {
  const { data } = (await http.get(`precautionary-statement/${id}`)) as any;

  return data;
};

const patchPrecautionaryStatement = async (statement: { code: string; description: string }, id: string) => {
  const data = await http.patch(`precautionary-statement/${id}`, statement);

  if (!data) return null;
  return data;
};

const postPrecautionaryStatement = async (statement: any) => {
  const data = await http.post(`precautionary-statement`, statement);

  if (!data) return null;
  return data;
};

export {
  getAllPrecautionaryStatements,
  getPrecautionaryStatement,
  patchPrecautionaryStatement,
  postPrecautionaryStatement,
};
