import React from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../../Shared/Components/Button/Button';
import { ButtonTypes } from '../../../../../Shared/Components/Button/enums';
import useBem from '../../../../../Hooks/useBem';
import { SelectItem } from '../../../../../Models/Types/SelectItem';

const SubstancesRegisterList = () => {
  const [bemClassName] = useBem('filter-bar');
  const navigate = useNavigate();
  const symbolTypeOptions: SelectItem[] = [
    { label: 'ADR', value: 'adr' },
    {
      label: 'GHS',
      value: 'ghs',
    },
    { label: 'PBM', value: 'pbm' },
  ];

  const updateSymbolFilter = (symboTypeFilter?: string) => {
    if (symboTypeFilter) {
      localStorage.setItem('symboTypeFilter', symboTypeFilter);
      window.location.reload();
    } else {
      localStorage.removeItem('symboTypeFilter');
      window.location.reload();
    }
  };

  return (
    <div className="filter-bar">
      <div className={bemClassName('filters')}>
        <p>Filteren op:</p>
        <Select
          classNamePrefix="select-box"
          placeholder="Symbooltype"
          options={symbolTypeOptions}
          defaultValue={symbolTypeOptions.find((option) => option.value === localStorage.getItem('symboTypeFilter'))}
          onChange={(value) => updateSymbolFilter((value as SelectItem)?.value)}
          isClearable
        />
      </div>

      <div className={bemClassName('actions')}>
        <Button
          title="Symbool toevoegen"
          onHandleClick={() => navigate('/beheer/symbolen/toevoegen')}
          theme={ButtonTypes.Solid}
        />
      </div>
    </div>
  );
};

export default SubstancesRegisterList;
