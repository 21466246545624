export enum ButtonTypes {
  Solid = 'solid',
  SolidBlue = 'solid-blue',
  Bordered = 'bordered',
  BorderedGreen = 'bordered-green',
}

export enum ButtonSizes {
  Auto = 'auto',
  Full = 'full',
}
