import React, { useState } from 'react';

import AnimateHeight, { Height } from 'react-animate-height';
import useBem from '../../../Hooks/useBem';

import './CollapsableContent.scss';

type PropType = {
  title: string;
  open?: boolean;
  children?: JSX.Element | JSX.Element[];
};

const CollapsableComponentGroup = (props: PropType) => {
  const { title, children, open } = props;

  const [bemClassName, bemClassNames] = useBem('collapsable-information-field');
  const [showInformationField, setShowInformationField] = useState<boolean>(open!);
  const [height, setHeight] = useState<Height>(open! ? 'auto' : 0);

  return (
    <li key={title}>
      <button
        className={bemClassNames([
          bemClassName('toggle-bar'),
          bemClassName('toggle-bar', 'active', showInformationField),
        ])}
        type="button"
        title={title}
        onClick={() => {
          setShowInformationField(!showInformationField);
          setHeight(height === 0 ? 'auto' : 0);
        }}
      >
        <span>{title}</span>

        <img
          className="toggle-arrow"
          src="/Icons/menuArrow.svg"
          alt="menu icon"
        />
      </button>
      <AnimateHeight
        duration={500}
        height={height}
      >
        <div className={bemClassNames([bemClassName('information'), bemClassName('information', 'form')])}>
          {children}
        </div>
      </AnimateHeight>
    </li>
  );
};

CollapsableComponentGroup.defaultProps = {
  open: false,
  children: null,
};

export default CollapsableComponentGroup;
