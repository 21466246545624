import React from 'react';
import useBem from '../../../../Hooks/useBem';
import { InformationItem } from '../types';

type PropType = {
  item: InformationItem;
};

const ComponentItem = (props: PropType) => {
  const { item } = props;
  const [bemClassName] = useBem('information-row');

  return (
    <li
      className={bemClassName('text')}
      key={item.title}
    >
      <span className={bemClassName('text', 'title')}>{item.title}</span>
      {item.description && <span className={bemClassName('text', 'description')}>{item.description}</span>}

      {item.list && (
        <div className={bemClassName('list')}>
          {item.list.map((listItem) => (
            <span
              className={bemClassName('text')}
              key={listItem}
            >
              {listItem}
            </span>
          ))}
        </div>
      )}
    </li>
  );
};

export default ComponentItem;
