import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Statement, StatementResponse } from '../../Models/Types/Statement';
import { getAllPrecautionaryStatements, getPrecautionaryStatement } from '../Data/PrecautionaryStatementService';

const usePrecautionary = (id?: string): UseQueryResult<StatementResponse | Statement, Error> =>
  useQuery({
    queryKey: [`precautionary-${id}`],
    queryFn: () => (id ? getPrecautionaryStatement(id) : getAllPrecautionaryStatements()),
  });

export default usePrecautionary;
