import React from 'react';
import SubstancesRegisterFilterBar from './SubstancesRegisterFilterBar';
import { FilterListItem } from '../../types';
import SubstancesList from './SubstancesList';

import './SubstancesRegisterList.scss';

type PropType = {
  onHandleNewFilterList: (filterList: FilterListItem[]) => void;
};

const SubstancesRegisterList = (props: PropType) => {
  const { onHandleNewFilterList } = props;
  const [listTotal, setListTotal] = React.useState<number>(0);

  return (
    <>
      <SubstancesRegisterFilterBar
        results={listTotal}
        onFilterListChange={(filterList) => onHandleNewFilterList(filterList)}
      />
      <div className="list-container">
        <SubstancesList onTotalUpdated={(total) => setListTotal(total)} />
      </div>
    </>
  );
};

export default SubstancesRegisterList;
