import { ExtinguishingMedia } from '../../Models/Types/ExtinguishingMedia';
import { SelectItem } from '../../Models/Types/SelectItem';

function generateIdTitleSelectItemList(data: ExtinguishingMedia[]) {
  const list: SelectItem[] = [];
  data?.forEach((item: any) => {
    list.push({
      label: item.title,
      value: item.id,
    });
  });
  return list;
}

export default generateIdTitleSelectItemList;
