import React from 'react';
import { useLocation } from 'react-router-dom';
import Navigation from '../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import useBem from '../../Hooks/useBem';

import './Error.scss';

const NotFound = () => {
  const [bemClassName] = useBem('error');
  const { pathname } = useLocation();

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {}}
            title="Pagina niet gevonden"
            showBackButton
          />
          <div className={bemClassName()}>
            <div className={bemClassName('content')}>
              <h1 className={bemClassName('header')}>404.</h1>
              <p className={bemClassName('text')}>
                We kunnen <b>{pathname}</b> niet vinden.
              </p>

              <p className={bemClassName('text')}>
                Als u denkt dat dit te wijten is aan een fout op de site, neem dan contact op met de beheerder.
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default NotFound;
