import React, { useState } from 'react';
import useBem from '../../../../../Hooks/useBem';
import SelectField from '../../../../../Shared/Components/Form/SelectField';
import InputField from '../../../../../Shared/Components/Form/InputField';
import { FillUpSize } from '../../../../../Shared/Components/Form/enums';
import Button from '../../../../../Shared/Components/Button/Button';
import { ButtonTypes } from '../../../../../Shared/Components/Button/enums';
import { SelectItem } from '../../../../../Models/Types/SelectItem';
import { ProductComponentType } from '../../../../../Models/Types/ProductComponentType';
import { getAllComponents } from '../../../../../Shared/Data/ComponentService';
import { ComponentType } from '../../../../../Models/Types/Component';

import './ListTable.scss';

type PropType = {
  values?: ProductComponentType[];
  message: string | undefined;
  onAdd?: (mixtureItem: ProductComponentType) => void;
  onDelete?: (substanceId: string) => void;
};

const SubstancesTable = (props: PropType) => {
  const [bemClassName, bemClassNames] = useBem('form-field');
  const { values, message, onAdd, onDelete } = props;
  const [options, setOptions] = useState<SelectItem[]>([]);
  const [mixtureItem, setMixtureItem] = useState<ProductComponentType>({
    component: { id: '', name: '' },
    percentage: '',
  });

  async function buildOptionsList(searchValue: string) {
    const base = window.__RUNTIME_CONFIG__.BACKEND_BASEURL;

    const url = new URL(`${base}component`);

    url.searchParams.append('page_limit', '20');
    url.searchParams.append('search', searchValue);

    await getAllComponents(url).then((response) => {
      const optionsList = response.items.map(({ id, name }: ComponentType) => ({
        label: name,
        value: id,
      }));
      setOptions(optionsList);
    });
  }

  function updateNewMixtureProperties(value: SelectItem | string, item: string) {
    let { component, percentage } = { ...mixtureItem };
    if (item === 'id') {
      component = { id: (value as SelectItem).value, name: (value as SelectItem).label };
    } else {
      percentage = value as string;
    }

    setMixtureItem({ component, percentage });
  }

  function changeSubstancesMixture() {
    if (mixtureItem.component.id !== '' && mixtureItem.percentage !== '') {
      onAdd!({ ...mixtureItem });
    }
  }

  return (
    <div className={bemClassName('table')}>
      <span className={bemClassName('label')}>Samenstelling</span>
      <table className={bemClassName('table-grid')}>
        <thead>
          <tr className={bemClassName('table-grid-row')}>
            <th
              className={bemClassNames([bemClassName('table-grid-cell'), bemClassName('table-grid-cell', 'substance')])}
            >
              Stof
            </th>
            <th className={bemClassName('table-grid-cell')}>%</th>
          </tr>
        </thead>
        <tbody>
          {values?.map(({ percentage, component: { id, name } }) => (
            <tr
              className={bemClassName('table-grid-row')}
              key={id}
            >
              <td
                className={bemClassNames([
                  bemClassName('table-grid-cell'),
                  bemClassName('table-grid-cell', 'substance'),
                ])}
              >
                {name}
              </td>
              <td className={bemClassName('table-grid-cell', 'delete')}>
                <span>{percentage}%</span>
                <button
                  type="button"
                  title="verwijderen"
                  onClick={() => onDelete!(id)}
                  aria-label="Delete row"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12.269 15.774"
                  >
                    <path
                      fill="#5776b9"
                      d="M.876 14.022a1.758 1.758 0 0 0 1.753 1.753H9.64a1.758 1.758 0 0 0 1.753-1.753V3.505H.876ZM12.269.876H9.2L8.325 0H3.944l-.876.876H0v1.753h12.269Z"
                      data-name="Icon material-delete"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={bemClassName('add')}>
        <SelectField
          name="substance"
          options={options}
          label="Stof toevoegen"
          placeholder="Begin met typen..."
          onInputChange={(searchValue) => buildOptionsList(searchValue)}
          onChangedValue={(selectedValue) =>
            updateNewMixtureProperties(selectedValue ? (selectedValue as SelectItem) : { value: '', label: '' }, 'id')
          }
        />
        <InputField
          name="amount"
          label="Percentage"
          size={FillUpSize.ExtraSmall}
          onChange={(e) => updateNewMixtureProperties(e.target.value, 'amount')}
        />
        <Button
          onHandleClick={() => changeSubstancesMixture()}
          title=""
          theme={ButtonTypes.SolidBlue}
          iconPath="/Icons/addItem.svg"
        />
      </div>
      {message && <div className={bemClassName('message')}>{message}</div>}
    </div>
  );
};

SubstancesTable.defaultProps = {
  values: [],
  onAdd: () => {},
  onDelete: () => {},
};

export default SubstancesTable;
