import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import HeaderSeperationBar from '../../../Shared/Components/HeaderSeperationBar/HeaderSeperationBar';
import ItemsListContent from './Components/ItemList/ItemsListContent';
import Loading from '../../../Shared/Components/Loading/Loading';
import Modal from '../../../Shared/Components/Modal/Modal';
import { Department } from '../../../Models/Types/Department';
import useDepartment from '../../../Shared/Hooks/useDepartment';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';
import { unpublishRoom } from '../../../Shared/Data/RoomService';
import handleError from '../../../Shared/Functions/handleError';

const Rooms = () => {
  if (!checkUserRight('entity.room.GET')) {
    return <NoPermission />;
  }

  const { departmentId } = useParams();
  const { data: department, isLoading } = useDepartment(departmentId);
  const [showModal, setShowDModal] = useState(false);
  const [selectedRoomId, setSelectedRoomId] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  function openModal(id: string) {
    setShowDModal(true);
    setSelectedRoomId(id);
  }

  async function removeRoom(id: string | undefined) {
    if (id) {
      unpublishRoom(id!)
        .catch((err) => {
          setErrorMessage(handleError(err));
        })
        .then((result: any) => {
          if (result) {
            window.location.reload();
          }
        });
    }
  }

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <TitleWithSearchbar
                title={`(${(department as Department)?.name}) Ruimtes beheren`}
                onHandleEnterKeyDown={() => {
                }}
                showBackButton
              />
              <HeaderSeperationBar />
              {errorMessage && <p className="message error">{errorMessage}</p>}
              <ItemsListContent handleOpenModal={(id) => openModal(id)} />
            </>
          )}
        </section>
      </div>
      <Modal
        showModal={showModal}
        modalTitle="Kamer verwijderen"
        modalDescription="Wilt u deze kamer definitief verwijderen?"
        modalButtonLeftText="Annuleren"
        modalButtonRightText="Verwijderen"
        rightButtonEnabled
        onLeftAction={() => setShowDModal(false)}
        onRightAction={() => removeRoom(selectedRoomId)}
        onHandleClose={() => setShowDModal(false)}
      />
    </>
  );
};

export default Rooms;
