import React, { useState } from 'react';
import { TabBarItem } from '../../Shared/Components/TabBar/types';
import Navigation from '../../Shared/Components/Navigation/Navigation';
import SubstancesRegisterHeader from './Components/SubstancesRegisterHeader';
import SubstancesRegisterEditFormList from './Components/EditForm/SubstancesRegisterEditFormList';
import { SubstanceRegisterEditFormListTabs } from './enums';
import { checkUserRight } from '../../Shared/Data/UserService';
import NoPermission from '../../Shared/Components/NoPermission/NoPermission';

type PropType = {
  type: SubstanceRegisterEditFormListTabs;
};
const SubstancesRegisterEditForm = (props: PropType) => {
  if (!checkUserRight('entity.hospital_product.PATCH')) {
    return <NoPermission />;
  }

  const { type } = props;

  const [tabBarItems, setTabBarItems] = useState<TabBarItem[]>(
    type === SubstanceRegisterEditFormListTabs.General
      ? [{ title: 'Algemene informatie wijzigen', tab: SubstanceRegisterEditFormListTabs.General, active: true }]
      : [
          {
            title: 'Organisatie specifieke informatie wijzigen',
            tab: SubstanceRegisterEditFormListTabs.Organisation,
            active: true,
          },
        ],
  );

  function handleTabBarClick(selectedTab: string) {
    tabBarItems.forEach((tabBarItem) => {
      // eslint-disable-next-line no-param-reassign
      tabBarItem.active = tabBarItem.tab === selectedTab;
    });
    setTabBarItems([...tabBarItems]);
  }

  function handleSearchRequest(searchValue: string) {
    // TODO: Implement search request
  }

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <SubstancesRegisterHeader
            title="Product wijzigen"
            tabBarItems={tabBarItems}
            onHandleTabBarClick={(selectedTab) => handleTabBarClick(selectedTab)}
            onHandleSearchRequest={(searchValue) => handleSearchRequest(searchValue)}
            showBackButton
          />
          <SubstancesRegisterEditFormList activeTab={tabBarItems.find((tabBarItem) => tabBarItem.active)?.tab} />
        </section>
      </div>
    </>
  );
};

export default SubstancesRegisterEditForm;
