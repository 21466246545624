import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { DataResult, State } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords,
} from '@progress/kendo-react-grid';
import UsersLoader from './UsersLoader';
import { checkUserRole } from '../../../../../Shared/Data/UserService';
import useUserRole from '../../../../../Shared/Hooks/useUserRole';
import { UserRole } from '../../../../../Models/Types/UserRole';
import { SelectItem } from '../../../../../Models/Types/SelectItem';

type PropType = {
  handleOpenModal: (id: string) => void;
};

const UsersList = (props: PropType) => {
  const { search } = useParams();
  const { handleOpenModal } = props;
  const navigate = useNavigate();
  const [products, setProducts] = useState<DataResult>({
    data: [],
    total: 0,
  });
  const { data: userRoleData, isLoading: isLoadingUserRoleData } = useUserRole();
  const [roleOptions, setRoleOptions] = useState<SelectItem[] | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string[]>(['Laden...']);
  const [searchValue, setSearchValue] = useState<string | undefined>(search);
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0,
  });

  if (!isLoadingUserRoleData && roleOptions === undefined) {
    const optionsList = (userRoleData as UserRole[]).map(({ id: userRoleId, name }: UserRole) => ({
      label: name,
      value: userRoleId,
    }));

    setRoleOptions(optionsList);
  }

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState({ ...e.dataState });
  };

  if (search !== searchValue) {
    setSearchValue(search);
    if (search === undefined) {
      delete dataState.filter;
    } else {
      dataState.skip = 0;
      dataState.filter = {
        logic: 'and',
        filters: [
          {
            field: 'title',
            operator: 'contains',
            value: search,
          },
        ],
      };
    }
    setDataState({ ...dataState });
  }

  const dataReceived = (productList: DataResult) => {
    setProducts(productList);
  };

  const hospitalCell = (subCategoryProps: GridCellProps) => {
    const { dataItem } = subCategoryProps;

    if (dataItem.hospital) {
      const { name } = dataItem.hospital;
      return <td className="userOrganisation">{name}</td>;
    }
    return <td className="userOrganisation">Geen organisatie</td>;
  };

  const roleCell = (subCategoryProps: GridCellProps) => {
    const { dataItem } = subCategoryProps;

    if (dataItem.roles) {
      const { roles } = dataItem;
      const role = roleOptions?.find((r) => r.value === roles[0]);
      return <td className="userOrganisation">{role?.label}</td>;
    }
    return <td className="userOrganisation">Geen organisatie</td>;
  };

  const actionsCell = (subCategoryProps: GridCellProps) => {
    const { dataItem } = subCategoryProps;

    return (
      <td className="userActions">
        <button
          type="button"
          className="stripped-button"
          onClick={() => navigate(`/beheer/gebruikers/detail/${dataItem.id}/`)}
        >
          Wijzigen
        </button>
        -
        <button
          type="button"
          className="stripped-button"
          onClick={() => handleOpenModal(dataItem.id)}
        >
          Verwijderen
        </button>
      </td>
    );
  };

  function handleError(error: string[]) {
    setErrorMessage(error);
  }

  return (
    <>
      <Grid
        sortable
        pageable
        {...dataState}
        data={products}
        onDataStateChange={dataStateChange}
      >
        <GridNoRecords>
          <div className="error-message">
            {errorMessage.length > 0 ? (
              errorMessage.map((message) => (
                <span
                  key={message}
                  className="error-message__content"
                >
                  {message}
                </span>
              ))
            ) : (
              <span className="error-message__content">Geen data gevonden...</span>
            )}
          </div>
        </GridNoRecords>

        <Column
          field="name"
          title="Gebruikersnaam"
          className="userName"
          headerClassName="userName"
        />
        <Column
          field="mail"
          title="Email"
          className="userEmail"
          headerClassName="userEmail"
        />
        {checkUserRole('administrator') && (
          <Column
            field="hospital"
            cell={hospitalCell}
            title="Organisatie"
            className="userOrganisation"
            headerClassName="userOrganisation"
          />
        )}
        <Column
          field="roles"
          cell={roleCell}
          title="Rol"
          className="userRole"
          headerClassName="userRole"
        />
        <Column
          cell={actionsCell}
          sortable={false}
          title="Acties"
          className="userActions"
          headerClassName="userActions"
        />
      </Grid>
      <UsersLoader
        dataState={dataState}
        onDataReceived={dataReceived}
        onError={(error) => handleError(error)}
      />
    </>
  );
};

export default UsersList;
