import React from 'react';
import {Substance} from '../../../../Models/Types/Substance';
import './EHBOInformation.scss';
import useBem from '../../../../Hooks/useBem';
import generateSymbolList from '../../../../Shared/Functions/generateSymbolList';
import {generateFlatStatements} from "../../../../Shared/Functions/generateStatements";
import ComponentItem from "../../../../Shared/Components/CollapsableContent/SubComponents/ComponentItem";

type EHBOInformationItemType = {
  title: string;
  description: string;
};

const EHBOInformationItem = ({title, description}: EHBOInformationItemType) => {
  // length > 1 to prevent just a dash.
  if (description && description.length > 1) {
    return (
      <p><strong>{title}</strong>: {description}</p>
    );
  }

  return null;
};

type EHBOInformationType = {
  substanceData: Substance;
};

const EHBOInformation = ({substanceData}: EHBOInformationType) => {
  const [bemClassName] = useBem('ehbo-information');
  const dangerSymbols = generateSymbolList(substanceData.symbol_ghs);
  const preventionSymbols = generateSymbolList(substanceData.symbol_pbm);

  return (
    <div className={bemClassName()}>
      <div className={bemClassName('card')}>
        <div className={bemClassName('header')}>
          <h2>Risico</h2>
          {dangerSymbols && dangerSymbols.length > 0 && (
            <div className={bemClassName('symbols')}>
              {dangerSymbols.map((dangerSymbol) => (
                <img
                  src={dangerSymbol.path}
                  alt={dangerSymbol.title}
                />
              ))}
            </div>
          )}
        </div>
        <EHBOInformationItem
          title="Gevaren"
          description={substanceData.exposure_hazards_and_protection}
        />
        <ComponentItem item={{title: 'H-Zinnen', list: generateFlatStatements(substanceData.hazard_statements)}}/>
        <ComponentItem item={{title: 'P-Zinnen', list: generateFlatStatements(substanceData.precautionary_statements)}}/>
      </div>
      <div className={bemClassName('card')}>
        <div className={bemClassName('header')}>
          <h2>Preventie</h2>
          {preventionSymbols && preventionSymbols.length > 0 && (
            <div className={bemClassName('symbols')}>
              {preventionSymbols.map((dangerSymbol) => (
                <img
                  src={dangerSymbol.path}
                  alt={dangerSymbol.title}
                />
              ))}
            </div>
          )}
        </div>
        <EHBOInformationItem
          title="Persoonlijke voorzorgsmaatregelen"
          description={substanceData.personal_precautions}
        />
        <EHBOInformationItem
          title="Reinigingsmethoden"
          description={substanceData.cleaning_methods}
        />
        <EHBOInformationItem
          title="Bescherming ademhalingsorganen"
          description={substanceData.respiratory_protection}
        />
        <EHBOInformationItem
          title="Bescherming handen"
          description={substanceData.hand_protection}
        />
        <EHBOInformationItem
          title="Bescherming ogen"
          description={substanceData.eye_protection}
        />
        <EHBOInformationItem
          title="Bescherming huid"
          description={substanceData.skin_protection}
        />
        <EHBOInformationItem
          title="Bescherming inslikken"
          description={substanceData.ingestion_protection}
        />
        <EHBOInformationItem
          title="Beheersing beroepsmatige blootstelling"
          description={substanceData.control_occupational_exposure}
        />
        <EHBOInformationItem
          title="Overige beheersmaatregelen"
          description={substanceData.other_control_measures}
        />
      </div>

      <div className={bemClassName('card')}>
        <div className={bemClassName('header')}>
          <h2>Symptomen</h2>
          <div className={bemClassName('symbols')}>
            <img
              src="/Images/Symtoms.png"
              alt="Symptomen"
            />
          </div>
        </div>
        <EHBOInformationItem
          title="Inademen"
          description={substanceData.inhalation}
        />
        <EHBOInformationItem
          title="Huid"
          description={substanceData.skin}
        />
        <EHBOInformationItem
          title="Handen"
          description={substanceData.hands}
        />
        <EHBOInformationItem
          title="Ogen"
          description={substanceData.eyes}
        />
        <EHBOInformationItem
          title="Inslikken"
          description={substanceData.ingestion}
        />
        <EHBOInformationItem
          title="Overige toxicologische informatie"
          description={substanceData.other_toxicological_information}
        />
      </div>
      <div className={bemClassName('card')}>
        <div className={bemClassName('header')}>
          <h2>Eerste hulp</h2>
          <div className={bemClassName('symbols')}>
            <img
              src="/Images/EHBO.png"
              alt="Symptomen"
            />
          </div>
        </div>
        <EHBOInformationItem
          title="Inademing"
          description={substanceData.first_aid_inhalation}
        />
        <EHBOInformationItem
          title="Inslikken"
          description={substanceData.first_aid_ingestion}
        />
        <EHBOInformationItem
          title="Huidcontact"
          description={substanceData.first_aid_skin_contact}
        />
        <EHBOInformationItem
          title="Handcontact"
          description={substanceData.first_aid_hand_contact}
        />
        <EHBOInformationItem
          title="Oogcontact"
          description={substanceData.first_aid_eye_contact}
        />
      </div>
    </div>
  );
};

export default EHBOInformation;
