import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useBem from '../../../Hooks/useBem';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import InputField from '../../../Shared/Components/Form/InputField';
import { ButtonTypes } from '../../../Shared/Components/Button/enums';
import Button from '../../../Shared/Components/Button/Button';
import { postSupplier } from '../../../Shared/Data/SupplierService';
import handleError from '../../../Shared/Functions/handleError';

import '../../../Shared/Styling/EditAddGrid.scss';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';
import TextAreaInputField from '../../../Shared/Components/Form/TextAreaInputField';
import { FillUpSize } from '../../../Shared/Components/Form/enums';
import FillUpField from '../../../Shared/Components/Form/FillUpField';

const AddSupplier = () => {
  if (!checkUserRight('entity.company.POST')) {
    return <NoPermission />;
  }

  const [bemClassName] = useBem('edit-add-grid');
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const submit = useCallback(async (e: any) => {
    const { name, contact } = e.target;
    const editedSupplier = {
      name: name.value,
      contact: contact.value,
    };

    postSupplier(editedSupplier as { name: string; contact: string })
      .catch((err) => setErrorMessage(handleError(err)))
      .then((result: any) => {
        if (result && !errorMessage) {
          navigate(`/beheer/leveranciers/`);
        }
      });
  }, []);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await submit(e);
  };

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {}}
            title="Leverancier toevoegen"
            showBackButton
          />
          <div className={bemClassName()}>
            <div className={bemClassName('content')}>
              <h1 className={bemClassName('header')}>Gegevens</h1>

              <form
                method="post"
                onSubmit={(e) => submitHandler(e)}
              >
                <ul className={bemClassName('input-list')}>
                  <InputField
                    name="name"
                    label="Naam leverancier"
                    placeholder="Naam leverancier"
                  />
                  <TextAreaInputField
                    name="contact"
                    label="NAW gegevens"
                  />
                  <FillUpField size={FillUpSize.Small} />

                  {errorMessage && <p className={bemClassName('error-message')}>{errorMessage}</p>}

                  <Button
                    submit
                    theme={ButtonTypes.Solid}
                    title="Leverancier toevoegen"
                  />
                </ul>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AddSupplier;
