import http from '../../Utils/HttpClient';

const getAllSuppliers = async (url?: URL) => {
  const { data } = url ? await http.get(url.toString()) : ((await http.get(`company`)) as any);

  return data;
};

const getSupplier = async (id: string) => {
  const { data } = (await http.get(`company/${id}`)) as any;

  return data;
};

const patchSupplier = async (supplier: { name: string; contact: string }, id: string) => {
  const data = await http.patch(`company/${id}`, supplier);

  if (!data) return null;
  return data;
};

const postSupplier = async (supplier: any) => {
  const data = await http.post(`company`, supplier);

  if (!data) return null;
  return data;
};

export { getAllSuppliers, getSupplier, patchSupplier, postSupplier };
