import React, { useState } from 'react';
import classNames from 'classnames';
import AnimateHeight, { Height } from 'react-animate-height';
import { MenuItem } from './types';

type PropType = {
  title: string;
  path: string;

  subItems?: MenuItem[];
};

const NavigationItem = (props: PropType) => {
  const { title, path, subItems } = props;
  const [showSubitems, setShowSubitems] = useState<boolean>(false);
  const [height, setHeight] = useState<Height>(0);

  return (
    <li key={title + path}>
      {subItems && subItems.length > 0 ? (
        <button
          className={classNames('sub-menu-toggle-trigger', {
            active: showSubitems,
          })}
          type="button"
          title={title}
          onClick={() => {
            setShowSubitems(!showSubitems);
            setHeight(height === 0 ? 'auto' : 0);
          }}
        >
          <img
            className="menu-icon"
            src="/Icons/menuIcon.svg"
            alt="menu icon"
          />
          <span>{title}</span>

          <img
            className="toggle-arrow"
            src="/Icons/menuArrow.svg"
            alt="menu icon"
          />
        </button>
      ) : (
        <a
          className="menu-link"
          type="button"
          title={title}
          href={path}
        >
          <img
            className="menu-icon"
            src="/Icons/menuIcon.svg"
            alt="menu icon"
          />
          <span>{title}</span>
        </a>
      )}

      {subItems && subItems.length > 0 && (
        <AnimateHeight
          duration={300}
          height={height}
        >
          <ul className="sub-menu">
            {subItems.map((subItem) => (
              <li key={subItem.title}>
                <a
                  className="menu-link"
                  type="button"
                  title={subItem.title}
                  href={subItem.path}
                >
                  <div className="sub-menu-icon" />
                  <span>{subItem.title}</span>
                </a>
              </li>
            ))}
          </ul>
        </AnimateHeight>
      )}
    </li>
  );
};

NavigationItem.defaultProps = {
  subItems: undefined,
};

export default NavigationItem;
