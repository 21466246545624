import React, { useState } from 'react';

import { PDFViewer } from '@react-pdf/renderer';
import CollapsableComponentGroup from '../../../../Shared/Components/CollapsableContent/CollapsableComponentGroup';
import InputField from '../../../../Shared/Components/Form/InputField';
import CheckboxList from '../../../../Shared/Components/Form/CheckboxList';
import { SettingTypes } from '../../enums';
import WorkInstructionCardPDF from '../../../../Shared/Components/PdfGenerator/PdfGeneratorWorkInstructionCard';
import { Substance } from '../../../../Models/Types/Substance';
import TextAreaInputField from '../../../../Shared/Components/Form/TextAreaInputField';
import Button from '../../../../Shared/Components/Button/Button';
import { ButtonSizes, ButtonTypes } from '../../../../Shared/Components/Button/enums';
import { generateStatements } from '../../../../Shared/Functions/generateStatements';
import generateFlatTitleList from '../../../../Shared/Functions/generateFlatList';
import generateSymbolList from '../../../../Shared/Functions/generateSymbolList';
import { PdfListItem } from '../../../../Shared/Components/PdfGenerator/types';

type PropType = {
  substanceData: Substance;
};

const WorkInstructionCard = (props: PropType) => {
  const { substanceData } = props;
  const {
    name,
    hazard_statements,
    precautionary_statements,
    first_aid_inhalation,
    first_aid_ingestion,
    first_aid_skin_contact,
    first_aid_eye_contact,
    personal_precautions,
    environmental_precautions,
    cleaning_methods,
    handling,
    storage,
    hand_protection,
    eye_protection,
    skin_protection,
    color,
    odor,
    other_information,
    mpz_number,
    physical_state,
    purpose,
    eyes,
    hands,
    first_aid_hand_contact,
    respiratory_protection,
    ingestion,
    skin,
    suitable_extinguishing_media,
    unsuitable_extinguishing_media,
    exposure_hazards_and_protection,
    environmental_risks,
    vib_revision_date,
    symbol_ghs,
    symbol_pbm,
    symbol_adr,
    hazard_class,
    components,
    ingestion_protection,
    un_number,
    proper_shipping_name,
    packaging_group,
    inhalation
  } = substanceData;

  const [settings, setSettings] = useState({
    selectedOptions: ['1', '2', '3', '4', '5', '6', '7'],
    amount: 1,
    emergencyNumber: '',
    department: '',
    notes: other_information,
  });

  const [updatedSettings, setUpdatedSettings] = useState(settings);

  function updateSettings(value: string | string[] | number, type: SettingTypes) {
    switch (type) {
      case SettingTypes.Amount:
        setSettings({ ...settings, amount: value as number });
        break;
      default:
        setSettings({ ...settings, [type]: value === undefined ? [] : value });
        break;
    }
  }

  return (
    <ul className="information-list">
      <CollapsableComponentGroup title="Instellingen">
        <CheckboxList
          name="selected"
          label="Componenten"
          selectedOptions={settings.selectedOptions}
          options={[
            { value: '1', label: 'Risico + Veiligheidsinformatie' },
            { value: '2', label: 'Bij brand' },
            { value: '3', label: 'Bij calamiteiten' },
            { value: '4', label: 'Milieu' },
            { value: '5', label: 'Opslag' },
            { value: '6', label: 'Vervoersinformatie' },
            { value: '7', label: 'Opmerkingen' },
          ]}
          onChangedSelection={(selectedOptions) => updateSettings(selectedOptions, SettingTypes.SelectedOptions)}
        />
        <InputField
          name="emergencyNumber"
          label="Noodnummer"
          type="phone"
          onChange={(e) => updateSettings(e.target.value, SettingTypes.EmergencyNumber)}
        />
        <InputField
          name="department"
          label="Afdeling"
          onChange={(e) => updateSettings(e.target.value, SettingTypes.Department)}
        />
        <TextAreaInputField
          name="notes"
          label="Opmerkingen"
          onChange={(e) => updateSettings(e.target.value, SettingTypes.Notes)}
        />
        <Button
          title="WIK genereren"
          theme={ButtonTypes.Solid}
          onHandleClick={() => setUpdatedSettings(settings)}
          width={ButtonSizes.Auto}
        />
      </CollapsableComponentGroup>
      <CollapsableComponentGroup
        title="PDF"
        open
      >
        <PDFViewer>
          <WorkInstructionCardPDF
            selectedOptions={updatedSettings.selectedOptions}
            amount={updatedSettings.amount}
            productTitle={name}
            generalInofrmationItems={[
              { name: 'Afdeling', value: updatedSettings.department },
              {
                name: 'MPZ nummer',
                value: mpz_number,
              },
              { name: 'Noodnummer', value: updatedSettings.emergencyNumber },
              { name: 'Verschijningsvorm', value: physical_state?.title },
              {
                name: 'Kleur',
                value: color,
              },
              { name: 'Geur', value: odor },
            ]}
            compoundItems={components.map(
              (component): PdfListItem => ({
                name: component.component.name,
                value: `${component.percentage.toString()}%`,
              }),
            )}
            purposesOfUse={purpose}
            dangerSymbols={generateSymbolList(symbol_ghs)}
            pbmSymbols={generateSymbolList(symbol_pbm)}
            adrSymbols={generateSymbolList(symbol_adr)}
            hazardClasses={hazard_class}
            hStatementList={generateStatements(hazard_statements)}
            pStatementList={generateStatements(precautionary_statements)}
            actionList={[
              {
                riskAndSymptom: {
                  name: 'Ogen',
                  value: eyes,
                },
                prevention: {
                  preventionList: [eye_protection],
                  symbol: undefined,
                },
                EHBO: [first_aid_eye_contact],
              },
              {
                riskAndSymptom: {
                  name: 'Inademing',
                  value: inhalation,
                },
                prevention: {
                  preventionList: [respiratory_protection],
                  symbol: undefined,
                },
                EHBO: [first_aid_inhalation],
              },
              {
                riskAndSymptom: {
                  name: 'Inslikken',
                  value: ingestion,
                },
                prevention: {
                  preventionList: [ingestion_protection],
                  symbol: undefined,
                },
                EHBO: [first_aid_ingestion],
              },
              {
                riskAndSymptom: {
                  name: 'Huid',
                  value: skin,
                },
                prevention: {
                  preventionList: [skin_protection],
                  symbol: undefined,
                },
                EHBO: [first_aid_skin_contact],
              },
              {
                riskAndSymptom: {
                  name: 'Handen',
                  value: hands,
                },
                prevention: {
                  preventionList: [hand_protection],
                  symbol: undefined,
                },
                EHBO: [first_aid_hand_contact],
              },
            ]}
            fireInformationList={[
              {
                dangers: exposure_hazards_and_protection,
                suitable: generateFlatTitleList(suitable_extinguishing_media),
                unsuitable: generateFlatTitleList(unsuitable_extinguishing_media),
              },
            ]}
            handlingList={handling ? handling.split(',') : []}
            storageList={storage ? storage.split(',') : []}
            personalPrecautions={personal_precautions}
            cleaningMethods={cleaning_methods}
            environmentalRisks={environmental_risks}
            environmentalPrecautions={environmental_precautions}
            otherInformation={updatedSettings.notes}
            lastModifiedDate={vib_revision_date}
            unNumber={un_number || ''}
            properShippingName={proper_shipping_name || ''}
            packagingGroup={packaging_group || ''}
          />
        </PDFViewer>
      </CollapsableComponentGroup>
    </ul>
  );
};
export default WorkInstructionCard;
