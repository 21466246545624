import http from '../../Utils/HttpClient';

const getAllPhases = async (url?: URL) => {
  const { data } = url ? await http.get(url.toString()) : ((await http.get(`phase`)) as any);

  return data;
};

const getPhase = async (id: string) => {
  const { data } = (await http.get(`phase/${id}`)) as any;

  return data;
};

const patchPhase = async (packagingGroup: { title: string }, id: string) => {
  const data = await http.patch(`phase/${id}`, packagingGroup);

  if (!data) return null;
  return data;
};

const postPhase = async (packagingGroup: any) => {
  const data = await http.post(`phase`, packagingGroup);

  if (!data) return null;
  return data;
};

export { getAllPhases, getPhase, patchPhase, postPhase };
