/**
 * React hook that helps to create class names according to the BEM naming convention.
 * @param blockClassName The block class name.
 */
const useBem = (blockClassName: string) => {
  /**
   * Creates a BEM class name.
   *
   * @param element string (optional) The element.
   * @param modifier (optional) The modifier.
   * @param condition (optional) The condition.
   * */
  const bemClassName = (
    element: string | null = null,
    modifier: string | null = null,
    condition: boolean = true,
  ): string => {
    if (condition) {
      let className = blockClassName;

      if (element) {
        className += `__${element}`;
      }

      if (modifier) {
        className += `--${modifier}`;
      }

      return className;
    }

    return '';
  };

  /**
   * Creates a joined string of BEM class names.
   * @param classNames The class names.
   */
  const bemClassNames = (classNames: string[]) => classNames.join(' ');

  /**
   * Creates a BEM class name prefixed with the block class name.
   *
   * @param element string (optional) The element.
   * @param modifier (optional) The modifier.
   * @param condition (optional) The condition.
   * */
  const bemClassNameWithBase = (
    element: string | null = null,
    modifier: string | null = null,
    condition: boolean = true,
  ) => `${blockClassName} ${bemClassName(element, modifier, condition)}`;

  return [bemClassName, bemClassNames, bemClassNameWithBase] as const;
};

export default useBem;
