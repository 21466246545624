import React, { KeyboardEvent, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import './TitleWithSearchbar.scss';
import useBem from '../../../Hooks/useBem';
import Button from '../Button/Button';
import { ButtonTypes } from '../Button/enums';

type TitleWithSearchbarType = {
  title: string;
  onHandleEnterKeyDown: (value: string) => void;
  onHandleEditClick?: () => void;
  showBackButton?: boolean;
  showSearchBar?: boolean;
  showEditButton?: boolean;
};

const TitleWithSearchbar = ({
                              title,
                              onHandleEnterKeyDown,
                              onHandleEditClick = undefined,
                              showBackButton = false,
                              showSearchBar = false,
                              showEditButton = false,
                            }: TitleWithSearchbarType) => {
  const { search } = useParams();
  const [bemClassName] = useBem('title-with-searchbar');
  const navigate = useNavigate();
  const searchInput = useRef<HTMLInputElement>(null);

  function handleOnKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      onHandleEnterKeyDown((event.target as HTMLInputElement).value);
    }
  }

  const handleSearchButtonClick = () => {
    if (searchInput && searchInput.current) {
      onHandleEnterKeyDown(searchInput.current.value);
    }
  };

  return (
    <div className={bemClassName()}>
      <div className={bemClassName('title')}>
        {showBackButton && (
          <button
            className={bemClassName('back-button')}
            type="button"
            onClick={() => navigate(-1)}
          >
            <img
              src="/Icons/arrowBack.svg"
              alt="Terug"
            />
          </button>
        )}
        <h1>{title}</h1>
      </div>
      <div className={bemClassName('searchbar')}>
        {showEditButton && (
          <Button
            onHandleClick={() => onHandleEditClick!()}
            title=""
            theme={ButtonTypes.Solid}
            iconPath="/Icons/editIcon.svg"
          />
        )}
        {showSearchBar && (
          <div className="searchbar-wrapper">
            <input
              ref={searchInput}
              className="search-input"
              placeholder="Zoeken"
              defaultValue={search}
              onKeyDown={(event) => handleOnKeyDown(event)}
            />
            <button onClick={handleSearchButtonClick} aria-label="Zoeken" className="button button--solid">
              <img
                src="/Icons/search-white.svg"
                alt="Zoeken"
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TitleWithSearchbar;