import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import UsersListContent from './Components/UsersList/UsersListContent';
import { FilterListItem } from '../../SubstancesRegister/types';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import HeaderSeperationBar from '../../../Shared/Components/HeaderSeperationBar/HeaderSeperationBar';
import Modal from '../../../Shared/Components/Modal/Modal';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';

const Users = () => {
  if (!checkUserRight('entity.user.GET')) {
    return <NoPermission />;
  }
  const { organisationId } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowDModal] = useState(false);
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : undefined;
  const organisation = user?.hospital;

  function handleSearchRequest(searchValue: string) {
    if (organisation) {
      if (searchValue === '') {
        navigate(`/beheer/gebruikers/organisatie/${organisationId}`);
        window.location.reload();
      } else {
        navigate(`/beheer/gebruikers/organisatie/${organisationId}/${searchValue}`);
      }
    } else if (searchValue === '') {
      navigate(`/beheer/gebruikers/`);
      window.location.reload();
    } else {
      navigate(`/beheer/gebruikers/${searchValue}`);
    }
  }

  function handleNewFilterList(newFilterList: FilterListItem[]) {
    if (localStorage.usersFilterList) {
      localStorage.usersFilterList = JSON.stringify(newFilterList);
    } else {
      localStorage.setItem('usersFilterList', JSON.stringify(newFilterList));
    }
    navigate(0);
  }

  function openModal(id: string) {
    setShowDModal(true);
    setUserId(id);
  }

  function removeUser(id: string | undefined) {
    // TODO: Implement remove user functionality
    setShowDModal(false);

    // eslint-disable-next-line no-console
    console.log(`Remove user with id: ${id}`);
  }

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            title="Gebruikers beheren"
            onHandleEnterKeyDown={(value) => handleSearchRequest(value)}
            showSearchBar
          />
          <HeaderSeperationBar />
          <UsersListContent
            handleOpenModal={(id) => openModal(id)}
            onHandleNewFilterList={(newFilterList) => handleNewFilterList(newFilterList)}
          />
        </section>
      </div>
      <Modal
        showModal={showModal}
        modalTitle="Gebruiker verwijderen"
        modalDescription="Wilt u deze gebruiker definitief verwijderen?"
        modalButtonLeftText="Annuleren"
        modalButtonRightText="Verwijderen"
        rightButtonEnabled
        onLeftAction={() => setShowDModal(false)}
        onRightAction={() => removeUser(userId)}
        onHandleClose={() => setShowDModal(false)}
      />
    </>
  );
};

export default Users;
