import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getAllUserRoles } from '../Data/UserService';
import { UserRole } from '../../Models/Types/UserRole';

const useUserRole = (): UseQueryResult<UserRole[], Error> =>
  useQuery({
    queryKey: [`userRoles`],
    queryFn: () => getAllUserRoles(),
  });
export default useUserRole;
