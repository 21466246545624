import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getAllPackagingGroups, getPackagingGroup } from '../Data/PackagingGroupService';
import { PackagingGroup, PackagingGroupResponse } from '../../Models/Types/PackagingGroup';

const usePackagingGroup = (id?: string): UseQueryResult<PackagingGroupResponse | PackagingGroup, Error> =>
  useQuery({
    queryKey: [`packaginggroup-${id}`], 
    queryFn: () => (id ? getPackagingGroup(id) : getAllPackagingGroups()),
  });
export default usePackagingGroup;
