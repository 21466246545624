import http from '../../Utils/HttpClient';

const getUser = async (id?: string) => {
  const { data } = (await http.get(id === undefined ? `user/me` : `user/${id}`)) as any;
  return data;
};

const getAllUsers = async (url?: URL) => {
  const { data } = url ? await http.get(url.toString()) : ((await http.get(`user`)) as any);

  return data;
};

const getAllUserRoles = async (url?: URL) => {
  const { data } = url
    ? await http.get(url.toString())
    : ((await http.get(`user-role?exclude_locked_roles=true`)) as any);

  if (data) {
    return Object.keys(data).map((key) => ({
      id: key,
      name: data[key],
    }));
  }

  return null;
};

const postUser = async (user: { name: string; mail: string; hospital: string; roles: string; pass: string }) => {
  const data = await http.post(`user`, user);

  if (!data) return null;
  return data;
};

const patchUser = async (
  user: {
    name: string;
    mail: string;
    hospital: string;
    roles: string[];
    current_pass?: string;
    pass?: string;
  },
  id: string,
) => {
  const data = await http.patch(`user/${id}`, user);

  if (!data) return null;
  return data;
};

export function checkUserRight(right: string) {
  const userData = localStorage.getItem('user');

  if (userData !== null) {
    const { rights } = JSON.parse(userData);
    return rights?.find((r: string) => r === right) !== undefined;
  }

  return false;
}

export function checkUserRole(role: string) {
  const userData = localStorage.getItem('user');

  if (userData !== null) {
    const { roles } = JSON.parse(userData);
    return roles?.find((r: string) => r === role) !== undefined;
  }

  return false;
}

export { getAllUsers, getUser, patchUser, postUser, getAllUserRoles };
