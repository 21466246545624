import React from 'react';
import SuppliersFilterBar from './OrganisationsFilterBar';
import OrganisationsList from './OrganisationsList';

import './OrganisationsList.scss';


const OrganisationsListContent = () => (
  <>
    <SuppliersFilterBar />
    <div className="list-container">
      <OrganisationsList />
    </div>
  </>
);

export default OrganisationsListContent;
