import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import HeaderSeperationBar from '../../../Shared/Components/HeaderSeperationBar/HeaderSeperationBar';
import ItemsListContent from './Components/ItemList/ItemsListContent';
import Loading from '../../../Shared/Components/Loading/Loading';
import Modal from '../../../Shared/Components/Modal/Modal';
import useRoom from '../../../Shared/Hooks/useRoom';
import { Room } from '../../../Models/Types/Room';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';
import handleError from '../../../Shared/Functions/handleError';
import { unpublishCloset } from '../../../Shared/Data/ClosetService';

const Closets = () => {
  if (!checkUserRight('entity.closet.GET')) {
    return <NoPermission />;
  }

  const { roomId } = useParams();
  const { data: room, isLoading } = useRoom(roomId);
  const [showModal, setShowDModal] = useState(false);
  const [selectedClosetId, setSelectedClosetId] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  function openModal(id: string) {
    setShowDModal(true);
    setSelectedClosetId(id);
  }

  async function removeCloset(id: string | undefined) {
    if (id) {
      unpublishCloset(id!)
        .catch((err) => {
          setErrorMessage(handleError(err));
        })
        .then((result: any) => {
          if (result) {
            window.location.reload();
          }
        });
    }
  }

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <TitleWithSearchbar
                title={`(${(room as Room)?.name}) Kasten beheren`}
                onHandleEnterKeyDown={() => {
                }}
                showBackButton
              />
              <HeaderSeperationBar />
              {errorMessage && <p className="message error">{errorMessage}</p>}
              <ItemsListContent handleOpenModal={(id) => openModal(id)} />
            </>
          )}
        </section>
      </div>
      <Modal
        showModal={showModal}
        modalTitle="Ruimte verwijderen"
        modalDescription="Wilt u deze ruimte definitief verwijderen?"
        modalButtonLeftText="Annuleren"
        modalButtonRightText="Verwijderen"
        rightButtonEnabled
        onLeftAction={() => setShowDModal(false)}
        onRightAction={() => removeCloset(selectedClosetId)}
        onHandleClose={() => setShowDModal(false)}
      />
    </>
  );
};

export default Closets;
