import React from 'react';

import { Text, View, StyleSheet } from '@react-pdf/renderer';

type PropType = {
  text: string;
};

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 10,
  },
  bullet: {
    height: '100%',
  },
});

const ListItem = (props: PropType) => {
  const { text } = props;
  return (
    <View style={styles.row}>
      <View style={styles.bullet}>
        <Text>{`\u2022  `}</Text>
      </View>
      <Text>{text}</Text>
    </View>
  );
};

export default ListItem;
