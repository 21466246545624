import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getAllExtinguishingMedia, getExtinguishingMedia } from '../Data/ExtinguishingMediaService';
import { ExtinguishingMedia, ExtinguishingMediaResponse } from '../../Models/Types/ExtinguishingMedia';

const useExtinguishingMedia = (id?: string): UseQueryResult<ExtinguishingMediaResponse | ExtinguishingMedia, Error> =>
  useQuery({
    queryKey: [`extinguishingmedia-${id}`],
    queryFn: () => (id ? getExtinguishingMedia(id) : getAllExtinguishingMedia()),
  });
export default useExtinguishingMedia;
