import React from 'react';

import './SubstancesRegisterSideDashboard.scss';
import classNames from 'classnames';
import DataCard from '../../../Shared/Components/DataCard/DataCard';
import useSideDashboardData from '../../../Shared/Hooks/useSideDashboardData';

type PropType = {
  onToggleSideMenu: (active: boolean) => void;
  activeSideMenu: boolean;
};
const SubstancesRegisterSideDashboard = (props: PropType) => {
  const { onToggleSideMenu, activeSideMenu } = props;
  const { data: dashboardData } = useSideDashboardData();

  if (!dashboardData) return null;

  const { total_in_hospital, total, changed_last_month, created_last_month } = dashboardData!;

  return (
    <div
      className={classNames('side-dashboard', {
        'side-dashboard--active': activeSideMenu,
      })}
    >
      <div className="side-dashboard__header">
        <h3>Dashboard</h3>
        <button
          type="button"
          className="toggle-side-dashboard"
          onClick={() => onToggleSideMenu(!activeSideMenu)}
        >
          <img
            src="/Icons/closeList.svg"
            alt="Close list"
          />
        </button>
      </div>
      <div className="side-dashboard__content">
        <p className="last-modified-date">Laatst bijgewerkt: 14-01-2023 </p>
        <DataCard
          number={total}
          explanation="Stoffen in de databank"
        />
        <DataCard
          number={total_in_hospital}
          explanation="Stoffen actief in de organisatie"
        />
        <DataCard
          number={created_last_month}
          explanation="Stoffen toegevoegd aflopen maand"
        />
        <DataCard
          number={changed_last_month}
          explanation="Stoffen geactualiseerd afgelopen maand"
        />
      </div>
      <div className="side-dashboard__footer">
        <img
          src="/Images/mpzLogo.svg"
          alt="Arrow left"
        />
      </div>
    </div>
  );
};

export default SubstancesRegisterSideDashboard;
