import React from 'react';
import useBem from '../../../Hooks/useBem';
import Button from '../Button/Button';
import { Logout } from '../../Data/LoginService';

import { ButtonSizes, ButtonTypes } from '../Button/enums';

import './NavigationProfile.scss';

const NavigationProfile = () => {
  const [bemClassName] = useBem('navigation-profile');
  const userName = localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user')!).name : 'Gast';
  const userRole = localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user')!).roles : null;
  let roleDisplayText: string = 'Rol onbekend';

  if (userRole) {
    switch (userRole[0]) {
      case 'hospital_administrator':
        roleDisplayText = 'Ziekenhuis administrator';
        break;
      case 'administrator':
        roleDisplayText = 'Administrator';
        break;
      case 'viewer_plus':
        roleDisplayText = 'Raadpleger plus';
        break;
      case 'viewer':
        roleDisplayText = 'Raadpleger';
        break;
      default:
        roleDisplayText = 'Rol onbekend';
        break;
    }
  }

  return (
    <>
      <div className={bemClassName()}>
        <div className={bemClassName('image-container')}>
          <div
            className={bemClassName('image')}
            style={{ backgroundImage: "url('/Images/userIcon.svg" }}
          />
        </div>
        <div className={bemClassName('name')}>
          <p>
            <b>{userName}</b>
          </p>
          <p>{roleDisplayText}</p>
        </div>
      </div>
      <Button
        title="Uitloggen"
        onHandleClick={() => {
          Logout();
        }}
        theme={ButtonTypes.Bordered}
        iconPath="/Icons/logout.svg"
        width={ButtonSizes.Full}
      />
    </>
  );
};

NavigationProfile.defaultProps = {
  loggedIn: false,
};

export default NavigationProfile;
