import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import { PdfListItem } from './types';
import { SymbolListItemType, InformationItem } from '../CollapsableContent/types';
import styling from './Components/VibStyling';

import './PdfGenerator.scss';

import PdfSection from './Components/PdfSection';
import { HazardClass } from '../../../Models/Types/HazardClass';

type PropType = {
  amount: number;
  chapterOne: InformationItem[];
  chapterTwo: InformationItem[];
  chapterThree: InformationItem[];
  chapterFour: InformationItem[];
  chapterFive: InformationItem[];
  chapterSix: InformationItem[];
  chapterSeven: InformationItem[];
  chapterEight: InformationItem[];
  chapterNine: InformationItem[];
  chapterTen: InformationItem[];
  chapterEleven: InformationItem[];
  chapterTwelve: InformationItem[];
  chapterThirteen: InformationItem[];
  chapterFourteen: InformationItem[];
  chapterFifteen: InformationItem[];
  dangerSymbols: SymbolListItemType[];
  pbmSymbols: SymbolListItemType[];
  adrSymbols: SymbolListItemType[];
  hazardClasses: HazardClass[];
  hStatements: PdfListItem[];
  otherInformation: string;
  lastModifiedDate: string;
};

const SafetyInformationCardPDF = (props: PropType) => {
  const {
    amount,
    chapterOne,
    chapterTwo,
    chapterThree,
    chapterFour,
    chapterFive,
    chapterSix,
    chapterSeven,
    chapterEight,
    chapterNine,
    chapterTen,
    chapterEleven,
    chapterTwelve,
    chapterThirteen,
    chapterFourteen,
    chapterFifteen,
    dangerSymbols,
    hazardClasses,
    hStatements,
    lastModifiedDate,
    otherInformation,
    pbmSymbols,
    adrSymbols,
  } = props;

  const {
    page,
    title,
    subtitle,
    image,
    titleSection,
    sectionHeader,
    sectionHeaderText,
    tableContainer,
    tableSection,
    tableRow,
    tableCell,
    tableCellBorder,
    tableCellLarge,
    tableCellMedium,
    tableCellTitle,
    tableCellImageContainer,
    tableCellImage,
    codeList,
    codeItem,
    codeItemName,
    codeItemValue,
    noteText,
    footNote,
    footNoteDate,
  } = styling;

  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : undefined;
  const organisation = user?.hospital;

  return (
    <Document>
      {Array.from({ length: amount }, (_, i) => (
        <Page
          key={i}
          size="A4"
          style={page}
        >
          <View
            fixed
            style={titleSection}
          >
            <View>
              <Text style={title}>Veiligheidsinformatieblad</Text>
              <Text style={subtitle}>{chapterOne[0].description}</Text>
            </View>
            <View>
              <Image
                src={
                  organisation?.logo?.styles?.png !== undefined ? organisation.logo.styles.png : '/Images/mpzLogo.png'
                }
                style={image}
              />
            </View>
          </View>
          <PdfSection
            title="1. Identificatie van de stof"
            chapter={chapterOne}
          />
          <View style={tableSection}>
            <View
              style={sectionHeader}
              fixed
            >
              <Text style={sectionHeaderText}>Wettelijke informatie</Text>
            </View>
            <View style={tableContainer}>
              <View style={tableRow}>
                <View style={[tableCell, tableCellMedium, tableCellBorder]}>
                  <Text style={tableCellTitle}>Gevarenklassen</Text>
                  <View style={codeList}>
                    {hazardClasses.map((item) => (
                      <View
                        key={item.title}
                        style={codeItem}
                      >
                        <Text style={codeItemValue}>{item.title}</Text>
                      </View>
                    ))}
                  </View>
                  <View style={tableCellImageContainer}>
                    {dangerSymbols.map((symbol) => (
                      <Image
                        key={symbol.title}
                        src={symbol.path}
                        style={tableCellImage}
                      />
                    ))}
                  </View>
                </View>
                <View style={[tableCell, tableCellLarge]}>
                  <Text style={tableCellTitle}>H-zinnen</Text>
                  <View style={codeList}>
                    {hStatements.map((item) => (
                      <View
                        key={item.name}
                        style={codeItem}
                      >
                        <Text style={codeItemName}>{item.name}</Text>
                        <Text style={codeItemValue}>{item.value}</Text>
                      </View>
                    ))}
                  </View>
                </View>
              </View>
            </View>
          </View>
          {pbmSymbols.length > 0 && (
            <View style={tableSection}>
              <View
                style={sectionHeader}
                wrap={false}
                fixed
              >
                <Text style={sectionHeaderText}>Persoonlijke beschermingsmiddelen</Text>
              </View>
              <View
                wrap={false}
                style={tableContainer}
              >
                <View style={tableRow}>
                  <View style={[tableCell, tableCellMedium]}>
                    <View style={tableCellImageContainer}>
                      {pbmSymbols.map((symbol) => (
                        <Image
                          key={symbol.title}
                          src={symbol.path}
                          style={tableCellImage}
                        />
                      ))}
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )}
          <PdfSection
            title="2. Gevaren"
            chapter={chapterTwo}
          />
          <PdfSection
            title="3. Samenstelling"
            chapter={chapterThree}
          />
          <PdfSection
            title="4. EHBO"
            chapter={chapterFour}
          />
          <PdfSection
            title="5. Brandbestrijding"
            chapter={chapterFive}
          />
          <PdfSection
            title="6. Voorzorg"
            chapter={chapterSix}
          />
          <PdfSection
            title="7. Hantering en opslag"
            chapter={chapterSeven}
          />
          <PdfSection
            title="8. Beheersing blootstelling"
            chapter={chapterEight}
          />
          <PdfSection
            title="9. Fysische en chemische eigenschappen"
            chapter={chapterNine}
          />
          <PdfSection
            title="10. Reactiviteit"
            chapter={chapterTen}
          />
          <PdfSection
            title="11. Toxische informatie"
            chapter={chapterEleven}
          />
          <PdfSection
            title="12. Milieu"
            chapter={chapterTwelve}
          />
          <PdfSection
            title="13. Verpakking"
            chapter={chapterThirteen}
          />
          <PdfSection
            title="14. Transport"
            chapter={chapterFourteen}
            symbols={adrSymbols}
            symbolTitle="ADR symbolen"
          />
          <PdfSection
            title="15. Regelgeving"
            chapter={chapterFifteen}
          />
          <View style={tableSection}>
            <View
              style={sectionHeader}
              fixed
            >
              <Text style={sectionHeaderText}>Opmerkingen</Text>
            </View>
            <Text style={noteText}>{otherInformation}</Text>
          </View>
          <View
            fixed
            style={footNote}
          >
            <Text style={footNoteDate}>Bijgewerkt tot: {lastModifiedDate}</Text>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default SafetyInformationCardPDF;
