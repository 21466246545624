import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Statement, StatementResponse } from '../../Models/Types/Statement';
import { getAllHazardStatements, getHazardStatement } from '../Data/HazardStatementService';

const useHazardStatement = (id?: string): UseQueryResult<StatementResponse | Statement, Error> =>
  useQuery({
    queryKey: [`hazard-${id}`],
    queryFn: () => (id ? getHazardStatement(id) : getAllHazardStatements()),
  });
export default useHazardStatement;
