import React from 'react';

import './Navigation.scss';
import NavigationItem from './NavigationItem';
import NavigationProfile from './NavigationProfile';
import useBem from '../../../Hooks/useBem';
import { checkUserRight, checkUserRole } from '../../Data/UserService';
import Button from '../Button/Button';
import { ButtonTypes } from '../Button/enums';

const Navigation = () => {
  const [bemClassName] = useBem('navigation-content');
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : undefined;
  const organisation = user?.hospital;
  const mpzPath = `/MPZ/?userlogin=${sessionStorage.getItem('userLogin')}`;
  const [isSmallMenu, setSmallMenu] = React.useState(window.innerWidth < 768);

  return (
    <nav className={bemClassName('wrapper', 'is-small', isSmallMenu)}>
      <div className={bemClassName('top')}>
        <div className={bemClassName('button-wrapper')}>
          <Button
            iconPath="/Icons/openContentArrow-White.svg"
            theme={ButtonTypes.Solid}
            onHandleClick={() => setSmallMenu(!isSmallMenu)}
          />
        </div>
        <div className="company-logo">
          <img
            src={
              organisation?.logo?.styles?.png !== undefined ? organisation?.logo?.styles?.png : '/Images/mpzLogo.png'
            }
            alt="Bedrijfslogo"
          />
        </div>
        <ul>
          {!user && sessionStorage.getItem('userLogin') && (
            <NavigationItem
              title="Mijn overzicht"
              path={mpzPath}
            />
          )}
          {user && (
            <NavigationItem
              title="Gevaarlijke stoffen"
              path="/productenregister"
            />
          )}
          {user && (
            <NavigationItem
              title="Rapportages"
              path="/"
              subItems={[
                {
                  title: 'Rapportage 1',
                  path: '',
                },
              ]}
            />
          )}
          {user && (
            <NavigationItem
              title="E-learning"
              path="https://milieuplatformzorg.nl/instrumenten/gevaarlijke-stoffen/e-learning-gevaarlijke-stoffen/ "
            />
          )}
          {checkUserRight('entity.manual.GET') && !checkUserRole('administrator') && (
            <NavigationItem
              title="Hulp & ondersteuning"
              path=""
              subItems={[
                {
                  title: 'Handleidingen',
                  path: '/handleidingen',
                },
              ]}
            />
          )}
          {checkUserRole('administrator') && (
            <NavigationItem
              title="Productbeheer"
              path=""
              subItems={[
                {
                  title: 'Toevoegen',
                  path: 'productbeheer/toevoegen',
                },
                {
                  title: 'Concepten',
                  path: 'productbeheer/concepten',
                },
                {
                  title: 'Ter acceptatie',
                  path: 'productbeheer/ter-acceptatie',
                },
              ]}
            />
          )}
          {checkUserRole('administrator') && (
            <>
              <NavigationItem
                title="Organisatiebeheer"
                path="productbeheer/componenten"
                subItems={[
                  {
                    title: 'Gebruikers',
                    path: '/beheer/gebruikers',
                  },
                  {
                    title: 'Organisatiestructuur',
                    path: '/beheer/organisaties',
                  },
                ]}
              />
              <NavigationItem
                title="Stamgegevens"
                path="/beheer"
                subItems={[
                  {
                    title: 'Componenten',
                    path: '/productbeheer/componenten',
                  },
                  {
                    title: 'Leveranciers',
                    path: '/beheer/leveranciers',
                  },
                  {
                    title: 'Gevarenklassen',
                    path: '/beheer/gevarenklassen',
                  },
                  {
                    title: 'H/EUH-zinnen',
                    path: '/beheer/h-zinnen',
                  },
                  {
                    title: 'P-zinnen',
                    path: '/beheer/p-zinnen',
                  },
                  {
                    title: 'Blusmiddelen',
                    path: '/beheer/blusmiddelen',
                  },
                  {
                    title: 'Verpakkingsgroepen',
                    path: '/beheer/verpakkingsgroepen',
                  },
                  {
                    title: 'Verschijningsvormen',
                    path: '/beheer/verschijningsvormen',
                  },
                  {
                    title: 'Symbolen',
                    path: '/beheer/symbolen',
                  },
                  {
                    title: 'Handleidingen',
                    path: '/beheer/handleidingen',
                  },
                ]}
              />
            </>
          )}
          {checkUserRole('hospital_administrator') && organisation?.id !== undefined && (
            <NavigationItem
              title="Productbeheer"
              path=""
              subItems={[
                {
                  title: 'Toevoegen',
                  path: 'productbeheer/toevoegen',
                },
                {
                  title: 'Mijn concepten',
                  path: 'productbeheer/concepten',
                },
              ]}
            />
          )}
          {checkUserRole('hospital_administrator') && organisation?.id !== undefined && (
            <NavigationItem
              title="Instellingen"
              path="/instellingen"
              subItems={[
                {
                  title: 'Huisstijl Organisatie',
                  path: '/beheer/huisstijl-organisatie',
                },
                {
                  title: 'Organisatiestructuur',
                  path: `/beheer/organisatiestructuur/${organisation.id}`,
                },
                {
                  title: 'Gebruikers',
                  path: `/beheer/gebruikers`,
                },
              ]}
            />
          )}
          {/* Disable the contact form for now. */}
          {false && !checkUserRole('administrator') && checkUserRight('contact.POST') && (
            <NavigationItem
              title="Contact"
              path="/contact"
            />
          )}
        </ul>
      </div>
      <div className={bemClassName('bottom')}>
        <NavigationProfile />
      </div>
    </nav>
  );
};

export default Navigation;
