import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import useBem from '../../../Hooks/useBem';
import InputField from '../../../Shared/Components/Form/InputField';
import Button from '../../../Shared/Components/Button/Button';
import { ButtonTypes } from '../../../Shared/Components/Button/enums';
import SelectField from '../../../Shared/Components/Form/SelectField';
import { SelectItem } from '../../../Models/Types/SelectItem';
import { getAllHospitals } from '../../../Shared/Data/HospitalService';
import { Hospital, HospitalResponse } from '../../../Models/Types/Hospital';
import handleError from '../../../Shared/Functions/handleError';
import useOrganisation from '../../../Shared/Hooks/useOrganisation';
import { checkUserRight, postUser } from '../../../Shared/Data/UserService';

import '../../../Shared/Styling/EditAddGrid.scss';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';
import { UserRole } from '../../../Models/Types/UserRole';
import useUserRole from '../../../Shared/Hooks/useUserRole';

const AddUser = () => {
  if (!checkUserRight('entity.user.POST')) {
    return <NoPermission />;
  }

  const [bemClassName] = useBem('edit-add-grid');
  const navigate = useNavigate();
  const { data, isLoading } = useOrganisation();
  const { data: userRoleData, isLoading: isLoadingUserRoleData } = useUserRole();
  const [options, setOptions] = useState<SelectItem[] | undefined>(undefined);
  const [roleOptions, setRoleOptions] = useState<SelectItem[] | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  if (!isLoading && options === undefined) {
    const optionsList = (data as HospitalResponse).items.map(({ id, name }: Hospital) => ({
      label: name,
      value: id,
    }));

    setOptions(optionsList);
  }

  async function buildOptionsList(searchValue: string) {
    const base = window.__RUNTIME_CONFIG__.BACKEND_BASEURL;

    const url = new URL(`${base}hospital`);

    url.searchParams.append('page_limit', '20');
    url.searchParams.append('search', searchValue);

    await getAllHospitals(url).then((response) => {
      const optionsList = response.items.map(({ id, name }: Hospital) => ({
        label: name,
        value: id,
      }));
      setOptions(optionsList);
    });
  }

  if (!isLoadingUserRoleData && roleOptions === undefined) {
    const optionsList = (userRoleData as UserRole[]).map(({ id: userRoleId, name }: UserRole) => ({
      label: name,
      value: userRoleId,
    }));

    setRoleOptions(optionsList);
  }

  const submit = useCallback(async (e: any) => {
    const { mail, name, hospital, roles, pass } = e.target;
    const user = {
      name: name.value,
      mail: mail.value,
      hospital: hospital.value,
      roles: roles.value,
      pass: pass.value,
    };
    postUser(user as { name: string; mail: string; hospital: string; roles: string; pass: string })
      .catch((err) => setErrorMessage(handleError(err)))
      .then((result: any) => {
        if (result && !errorMessage) {
          navigate(`/beheer/gebruikers/`);
        }
      });
  }, []);

  const submitHandler = async (e: any) => {
    e.preventDefault();

    const { pass, passCheck } = e.target;
    if (pass.value !== passCheck.value) {
      setErrorMessage(handleError(null, 'Wachtwoorden komen niet overeen'));
    } else {
      await submit(e);
    }
  };

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {}}
            title="Gebruiker toevoegen"
            showBackButton
          />
          <div className={bemClassName()}>
            <div className={bemClassName('content')}>
              <h1 className={bemClassName('header')}>Gegevens</h1>

              {isLoading ? (
                <p>Loading...</p>
              ) : (
                <form
                  method="post"
                  onSubmit={(event) => submitHandler(event)}
                >
                  <ul className={bemClassName('input-list')}>
                    <InputField
                      name="name"
                      label="Gebruikersnaam"
                      placeholder="Gebruikersnaam"
                    />
                    <InputField
                      name="mail"
                      label="Email"
                      type="email"
                      placeholder="Email"
                    />

                    <SelectField
                      name="hospital"
                      label="Organisatie"
                      options={options!}
                      onInputChange={(searchValue) => buildOptionsList(searchValue)}
                    />
                    <SelectField
                      name="roles"
                      label="Rol"
                      options={roleOptions!}
                    />
                    <InputField
                      name="pass"
                      label="Wachtwoord"
                      type="password"
                    />
                    <InputField
                      name="passCheck"
                      label="Herhaal wachtwoord"
                      type="password"
                    />

                    {errorMessage && <p className={bemClassName('error-message')}>{errorMessage}</p>}

                    <Button
                      submit
                      theme={ButtonTypes.Solid}
                      title="Gebruiker toevoegen"
                    />
                  </ul>
                </form>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AddUser;
