import http from '../../Utils/HttpClient';

const getAllHazardClasses = async (url: URL) => {
  const { data } = (await http.get(url.toString())) as any;

  return data;
};

const getHazardClasses = async () => {
  const { data } = (await http.get('hazard-class?page_limit=2500')) as any;

  return data;
};

const getHazardClass = async (id: string) => {
  const { data } = (await http.get(`hazard-class/${id}`)) as any;

  return data;
};

const patchHazardClass = async (hazardClass: { title: string; hazard_statements: string[] }, id: string) => {
  const data = await http.patch(`hazard-class/${id}`, hazardClass);

  if (!data) return null;
  return data;
};

const postHazardClass = async (hazardClass: any) => {
  const data = await http.post(`hazard-class`, hazardClass);

  if (!data) return null;
  return data;
};

export { getAllHazardClasses, getHazardClasses, getHazardClass, patchHazardClass, postHazardClass };
