import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

import App from './App';
import SubstancesRegister from './Features/SubstancesRegister/SubstancesRegister';
import SubstancesRegisterDetail from './Features/SubstancesRegister/SubstancesRegisterDetail';
import SubstancesRegisterEditForm from './Features/SubstancesRegister/SubstancesRegisterEditForm';
import Login from './Features/Login/Login';
import Contact from './Features/Contact/Contact';
import NotFound from './Features/Error/NotFound';
import ErrorPage from './Features/Error/ErrorPage';
import Users from './Features/Management/Users/Users';
import EditUser from './Features/Management/Users/EditUser';
import AddUser from './Features/Management/Users/AddUser';
import Suppliers from './Features/Management/Suppliers/Suppliers';
import EditSupplier from './Features/Management/Suppliers/EditSupplier';
import AddSupplier from './Features/Management/Suppliers/AddSupplier';
import ExtinguishingAgents from './Features/Management/ExtinguishingAgents/ExtinguishingAgents';
import AddExtinguishingAgent from './Features/Management/ExtinguishingAgents/AddExtinguishingAgent';
import EditExtinguishingAgent from './Features/Management/ExtinguishingAgents/EditExtinguishingAgent';
import HazardClasses from './Features/Management/HazardClasses/HazardClasses';
import AddHazardClass from './Features/Management/HazardClasses/AddHazardClass';
import EditHazardClass from './Features/Management/HazardClasses/EditHazardClass';
import HazardStatements from './Features/Management/HazardStatements/HazardStatements';
import AddHazardStatement from './Features/Management/HazardStatements/AddHazardStatement';
import EditHazardStatement from './Features/Management/HazardStatements/EditHazardStatement';
import PrecautionaryStatements from './Features/Management/PrecautionaryStatements/PrecautionaryStatements';
import AddPrecautionaryStatement from './Features/Management/PrecautionaryStatements/AddPrecautionaryStatement';
import EditPrecautionaryStatement from './Features/Management/PrecautionaryStatements/EditPrecautionaryStatement';
import Appearances from './Features/Management/Appearances/Appearances';
import AddAppearance from './Features/Management/Appearances/AddAppearance';
import EditAppearance from './Features/Management/Appearances/EditAppearance';
import PackagingGroups from './Features/Management/PackagingGroups/PackagingGroups';
import AddPackagingGroup from './Features/Management/PackagingGroups/AddPackagingGroup';
import EditPackagingGroup from './Features/Management/PackagingGroups/EditPackagingGroup';
import Symbols from './Features/Management/Symbols/Symbols';
import AddSymbol from './Features/Management/Symbols/AddSymbol';
import EditSymbol from './Features/Management/Symbols/EditSymbol';
import Organisations from './Features/Management/Organisations/Organisations';
import AddOrganisation from './Features/Management/Organisations/AddOrganisation';
import EditOrganisation from './Features/Management/Organisations/EditOrganisation';
import Departments from './Features/Management/OrganisationStructure/Departments';
import AddDepartment from './Features/Management/OrganisationStructure/AddDepartment';
import EditDepartment from './Features/Management/OrganisationStructure/EditDepartment';
import Manuals from './Features/HelpAndSupport/Manuals/Manuals';
import ShowManual from './Features/HelpAndSupport/Manuals/ShowManual';
import Rooms from './Features/Management/OrganisationStructure/Rooms';
import EditRoom from './Features/Management/OrganisationStructure/EditRoom';
import AddRoom from './Features/Management/OrganisationStructure/AddRoom';
import Closets from './Features/Management/OrganisationStructure/Closets';
import AddCloset from './Features/Management/OrganisationStructure/AddCloset';
import EditCloset from './Features/Management/OrganisationStructure/EditCloset';
import { SubstanceRegisterEditFormListTabs } from './Features/SubstancesRegister/enums';
import ManualManagement from './Features/Management/Manuals/ManualManagement';
import AddManual from './Features/Management/Manuals/AddManual';
import EditManual from './Features/Management/Manuals/EditManual';
import AddProduct from './Features/Management/Products/AddProduct';
import ProductConcepts from './Features/Management/Products/ProductConcepts';

import './index.scss';
import AllComponents from './Features/Management/Components/AllComponents';
import AddComponent from './Features/Management/Components/AddComponent';
import EditComponent from './Features/Management/Components/EditComponent';
import ProductWaitingForApproval from './Features/Management/Products/ProductWaitingForApproval';
import LoginWithUrl from './Shared/Components/LoginWithUrl/LoginWithUrl';

const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : undefined;
const organisationId = user?.hospital ? user.hospital.id : undefined;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
      {
        path: '/productenregister/search?/:search?',
        element: <SubstancesRegister />,
      },
      {
        path: '/MPZ',
        element: <LoginWithUrl />,
      },
      {
        path: '/productenregister/detail/:id/:tab?',
        element: <SubstancesRegisterDetail />,
      },
      {
        path: '/productenregister/detail/:id/r/:rev_id',
        element: <SubstancesRegisterDetail />,
      },
      {
        path: '/productenregister/detail/:id/algemene-informatie-wijzigen',
        element: <SubstancesRegisterEditForm type={SubstanceRegisterEditFormListTabs.General} />,
      },
      {
        path: '/productenregister/detail/:id/:rev_id/algemene-informatie-wijzigen',
        element: <SubstancesRegisterEditForm type={SubstanceRegisterEditFormListTabs.General} />,
      },
      {
        path: '/productenregister/detail/:id/organisatie-specifieke-informatie-wijzigen',
        element: <SubstancesRegisterEditForm type={SubstanceRegisterEditFormListTabs.Organisation} />,
      },
      {
        path: '/productenregister/detail/:id/:rev_id/organisatie-specifieke-informatie-wijzigen',
        element: <SubstancesRegisterEditForm type={SubstanceRegisterEditFormListTabs.Organisation} />,
      },
      {
        path: '/handleidingen',
        element: <Manuals />,
      },
      {
        path: '/handleidingen/:manualId',
        element: <ShowManual />,
      },
      {
        path: '/beheer/gebruikers',
        element: <Users />,
      },
      {
        path: '/beheer/gebruikers/:search?',
        element: <Users />,
      },
      {
        path: '/beheer/gebruikers/toevoegen',
        element: <AddUser />,
      },
      {
        path: '/beheer/gebruikers/detail/:id',
        element: <EditUser />,
      },
      {
        path: '/beheer/leveranciers/:search?',
        element: <Suppliers />,
      },
      {
        path: '/beheer/leveranciers/toevoegen',
        element: <AddSupplier />,
      },
      {
        path: '/beheer/leveranciers/detail/:id',
        element: <EditSupplier />,
      },
      {
        path: '/beheer/blusmiddelen',
        element: <ExtinguishingAgents />,
      },
      {
        path: '/beheer/blusmiddelen/toevoegen',
        element: <AddExtinguishingAgent />,
      },
      {
        path: '/beheer/blusmiddelen/detail/:id',
        element: <EditExtinguishingAgent />,
      },
      {
        path: '/beheer/gevarenklassen',
        element: <HazardClasses />,
      },
      {
        path: '/beheer/gevarenklassen/toevoegen',
        element: <AddHazardClass />,
      },
      {
        path: '/beheer/gevarenklassen/detail/:id',
        element: <EditHazardClass />,
      },
      {
        path: '/beheer/h-zinnen',
        element: <HazardStatements />,
      },
      {
        path: '/beheer/h-zinnen/toevoegen',
        element: <AddHazardStatement />,
      },
      {
        path: '/beheer/h-zinnen/detail/:id',
        element: <EditHazardStatement />,
      },
      {
        path: '/beheer/p-zinnen',
        element: <PrecautionaryStatements />,
      },
      {
        path: '/beheer/p-zinnen/toevoegen',
        element: <AddPrecautionaryStatement />,
      },
      {
        path: '/beheer/p-zinnen/detail/:id',
        element: <EditPrecautionaryStatement />,
      },
      {
        path: '/beheer/verschijningsvormen',
        element: <Appearances />,
      },
      {
        path: '/beheer/verschijningsvormen/toevoegen',
        element: <AddAppearance />,
      },
      {
        path: '/beheer/verschijningsvormen/detail/:id',
        element: <EditAppearance />,
      },
      {
        path: '/beheer/verpakkingsgroepen',
        element: <PackagingGroups />,
      },
      {
        path: '/productbeheer/toevoegen',
        element: <AddProduct />,
      },
      {
        path: '/productbeheer/concepten',
        element: <ProductConcepts />,
      },
      {
        path: '/productbeheer/ter-acceptatie',
        element: <ProductWaitingForApproval />,
      },
      {
        path: '/productbeheer/componenten',
        element: <AllComponents />,
      },
      {
        path: '/productbeheer/componenten/:search?',
        element: <AllComponents />,
      },
      {
        path: '/productbeheer/componenten/toevoegen',
        element: <AddComponent />,
      },
      {
        path: '/productbeheer/componenten/detail/:id',
        element: <EditComponent />,
      },
      {
        path: '/beheer/verpakkingsgroepen/toevoegen',
        element: <AddPackagingGroup />,
      },
      {
        path: '/beheer/verpakkingsgroepen/detail/:id',
        element: <EditPackagingGroup />,
      },
      {
        path: '/beheer/labelklassen',
        element: <Symbols />,
      },
      {
        path: '/beheer/labelklassen/toevoegen',
        element: <AddSymbol />,
      },
      {
        path: '/beheer/labelklassen/detail/:id',
        element: <EditSymbol />,
      },
      {
        path: '/beheer/organisaties/search?/:search?',
        element: <Organisations />,
      },
      {
        path: '/beheer/organisaties/toevoegen',
        element: <AddOrganisation />,
      },
      {
        path: '/beheer/organisaties/detail/:id',
        element: <EditOrganisation />,
      },
      {
        path: '/beheer/huisstijl-organisatie',
        element: <EditOrganisation organisationId={organisationId} />,
      },
      {
        path: '/beheer/organisatiestructuur/:organisationId/',
        element: <Departments />,
      },
      {
        path: '/beheer/organisatiestructuur/:organisationId/toevoegen/',
        element: <AddDepartment />,
      },
      {
        path: '/beheer/organisatiestructuur/:organisationId/afdelingen/:departmentId/wijzigen/',
        element: <EditDepartment />,
      },
      {
        path: '/beheer/organisatiestructuur/:organisationId/afdelingen/:departmentId/',
        element: <Rooms />,
      },
      {
        path: '/beheer/organisatiestructuur/:organisationId/afdelingen/:departmentId/toevoegen',
        element: <AddRoom />,
      },
      {
        path: '/beheer/organisatiestructuur/:organisationId/afdelingen/:departmentId/kamers/:roomId/wijzigen',
        element: <EditRoom />,
      },
      {
        path: '/beheer/organisatiestructuur/:organisationId/afdelingen/:departmentId/kamers/:roomId',
        element: <Closets />,
      },
      {
        path: '/beheer/organisatiestructuur/:organisationId/afdelingen/:departmentId/kamers/:roomId/toevoegen',
        element: <AddCloset />,
      },
      {
        path: '/beheer/organisatiestructuur/:organisationId/afdelingen/:departmentId/kamers/:roomId/kasten/:closetId/wijzigen',
        element: <EditCloset />,
      },
      {
        path: '/beheer/symbolen',
        element: <Symbols />,
      },
      {
        path: '/beheer/symbolen/toevoegen',
        element: <AddSymbol />,
      },
      {
        path: '/beheer/symbolen/detail/:id',
        element: <EditSymbol />,
      },
      {
        path: '/beheer/handleidingen',
        element: <ManualManagement />,
      },
      {
        path: '/beheer/handleidingen/toevoegen',
        element: <AddManual />,
      },
      {
        path: '/beheer/handleidingen/detail/:id',
        element: <EditManual />,
      },
      {
        path: '/contact',
        element: <Contact />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
    errorElement: <ErrorPage />,
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
