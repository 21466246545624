import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import styling from './VibStyling';
import { InformationItem, SymbolListItemType } from '../../CollapsableContent/types';

type PropType = {
  title: string;
  chapter: InformationItem[];
  symbols?: SymbolListItemType[];
  symbolTitle?: string;
};

const PdfSection = (props: PropType) => {
  const { title, chapter, symbols, symbolTitle } = props;

  const {
    sectionHeader,
    sectionHeaderText,
    tableContainer,
    tableSection,
    tableRow,
    tableCell,
    tableCellMedium,
    tableCellImageContainer,
    codeList,
    codeItem,
    codeItemValue,
    listItem,
    listItemName,
    listItemValue,
    tableCellTitle,
    tableCellImage,
  } = styling;

  return (
    <View
      style={tableSection}
      wrap={false}
    >
      <View
        style={sectionHeader}
        fixed
      >
        <Text style={sectionHeaderText}>{title}</Text>
      </View>
      <View style={tableContainer}>
        <View style={tableRow}>
          <View style={[tableCell, tableCellMedium]}>
            <View style={tableCellImageContainer}>
              {chapter.map(({ description, list, title: title1 }) => (
                <>
                  {description !== undefined && (
                    <View
                      key={title1}
                      style={listItem}
                    >
                      <Text style={listItemName}>{title1}</Text>
                      <Text style={listItemValue}>{description}</Text>
                    </View>
                  )}
                  {list && (
                    <View
                      key={title1}
                      style={listItem}
                    >
                      <Text style={listItemName}>{title1}</Text>
                      <View style={codeList}>
                        {list.map((value) => (
                          <View
                            style={codeItem}
                            key={value}
                          >
                            <Text style={codeItemValue}>{value}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </>
              ))}
            </View>
          </View>
        </View>
        {symbols && (
          <View style={tableRow}>
            <View style={[tableCell, tableCellMedium]}>
              {symbolTitle && <Text style={tableCellTitle}>{symbolTitle}</Text>}
              <View style={tableCellImageContainer}>
                {symbols.map((symbol) => (
                  <Image
                    key={symbol.title}
                    src={symbol.path}
                    style={tableCellImage}
                  />
                ))}
              </View>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

export default PdfSection;

PdfSection.defaultProps = {
  symbols: undefined,
  symbolTitle: undefined,
};
