import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import HeaderSeperationBar from '../../../Shared/Components/HeaderSeperationBar/HeaderSeperationBar';
import ItemsListContent from './Components/ItemList/ItemsListContent';
import useOrganisation from '../../../Shared/Hooks/useOrganisation';
import Loading from '../../../Shared/Components/Loading/Loading';
import { Hospital } from '../../../Models/Types/Hospital';
import Modal from '../../../Shared/Components/Modal/Modal';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';
import { unpublishDepartment } from '../../../Shared/Data/DepartmentService';
import handleError from '../../../Shared/Functions/handleError';

const Departments = () => {
  if (!checkUserRight('entity.department.GET')) {
    return <NoPermission />;
  }

  const params = useParams();
  const { data: organisation, isLoading } = useOrganisation(params.organisationId);
  const [showModal, setShowDModal] = useState(false);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const queryClient = useQueryClient();

  function openModal(id: string) {
    setShowDModal(true);
    setSelectedDepartmentId(id);
  }

  async function removeDepartment(id: string | undefined) {
    if (id) {
      unpublishDepartment(id!)
        .catch((err) => {
          setErrorMessage(handleError(err));
        })
        .then((result: any) => {
          if (result) {
            queryClient.invalidateQueries({ queryKey: [`department-${id}`] });
            window.location.reload();
          }
        });
    }
  }

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <TitleWithSearchbar
                title={`(${(organisation as Hospital)?.name}) Afdelingen beheren`}
                onHandleEnterKeyDown={() => {
                }}
                showBackButton
              />
              <HeaderSeperationBar />
              {errorMessage && <p className="message error">{errorMessage}</p>}
              <ItemsListContent handleOpenModal={(id) => openModal(id)} />
            </>
          )}
        </section>
      </div>
      <Modal
        showModal={showModal}
        modalTitle="Afdeling verwijderen"
        modalDescription="Wilt u deze afdeling definitief verwijderen?"
        modalButtonLeftText="Annuleren"
        modalButtonRightText="Verwijderen"
        rightButtonEnabled
        onLeftAction={() => setShowDModal(false)}
        onRightAction={() => removeDepartment(selectedDepartmentId)}
        onHandleClose={() => setShowDModal(false)}
      />
    </>
  );
};

export default Departments;
