import React, { useState } from 'react';

import AnimateHeight, { Height } from 'react-animate-height';
import { SymbolListItemType } from './types';
import useBem from '../../../Hooks/useBem';

import './CollapsableContent.scss';

type PropType = {
  title: string;
  open?: boolean;
  children?: JSX.Element | JSX.Element[];
  symbols?: SymbolListItemType[];
  signalWords?: string;
};

const CollapsableInformationField = (props: PropType) => {
  const { title, children, symbols, open, signalWords } = props;

  const [bemClassName, bemClassNames] = useBem('collapsable-information-field');
  const [bemInfomrationClassName] = useBem('information-row');
  const [showInformationField, setShowInformationField] = useState<boolean>(open!);
  const [height, setHeight] = useState<Height>(open! ? 'auto' : 0);

  return (
    <li key={title}>
      <button
        className={bemClassNames([
          bemClassName('toggle-bar'),
          bemClassName('toggle-bar', 'active', showInformationField),
        ])}
        type="button"
        title={title}
        onClick={() => {
          setShowInformationField(!showInformationField);
          setHeight(height === 0 ? 'auto' : 0);
        }}
      >
        <span>{title}</span>

        <img
          className="toggle-arrow"
          src="/Icons/menuArrow.svg"
          alt="menu icon"
        />
      </button>
      <AnimateHeight
        duration={500}
        height={height}
      >
        <div
          className={bemClassNames([
            bemClassName('information'),
            bemClassName('information', 'wide', symbols === undefined),
          ])}
        >
          <ul>{children}</ul>
          {symbols && symbols.length > 0 && (
            <div className={bemInfomrationClassName('danger-symbols')}>
              {symbols.map((dangerSymbol) => (
                <div
                  className={bemInfomrationClassName('danger-symbols', 'item')}
                  key={dangerSymbol.title}
                >
                  <img
                    className=""
                    src={dangerSymbol.path}
                    alt={dangerSymbol.title}
                  />
                  <span>
                    <b>{dangerSymbol.title}</b>
                  </span>
                </div>
              ))}
              <div className={bemInfomrationClassName('danger-symbols', 'word')}>{signalWords}</div>
            </div>
          )}
        </div>
      </AnimateHeight>
    </li>
  );
};

CollapsableInformationField.defaultProps = {
  open: false,
  symbols: undefined,
  signalWords: undefined,
  children: null,
};

export default CollapsableInformationField;
