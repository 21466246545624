import React from 'react';
import SuppliersFilterBar from './SuppliersFilterBar';
import SuppliersList from './SuppliersList';

import './SuppliersList.scss';

type PropType = {
  handleOpenModal: (id: string) => void;
};

const SuppliersListContent = (props: PropType) => {
  const { handleOpenModal } = props;

  return (
    <>
      <SuppliersFilterBar />
      <div className="list-container">
        <SuppliersList handleOpenModal={(id) => handleOpenModal(id)} />
      </div>
    </>
  );
};

export default SuppliersListContent;
