import React, {useState} from 'react';

import {PDFViewer} from '@react-pdf/renderer';
import {SelectItem} from '../../../../Models/Types/SelectItem';
import PdfGeneratorLabel from '../../../../Shared/Components/PdfGenerator/PdfGeneratorLabel';
import CollapsableComponentGroup from '../../../../Shared/Components/CollapsableContent/CollapsableComponentGroup';
import SelectField from '../../../../Shared/Components/Form/SelectField';
import PageSize from '../../../../Shared/Components/PdfGenerator/enums';
import {Substance} from '../../../../Models/Types/Substance';
import {generateFlatStatements} from '../../../../Shared/Functions/generateStatements';
import generateSymbolList from '../../../../Shared/Functions/generateSymbolList';

type PropType = {
  substanceData: Substance;
};

const PrintLabel = (props: PropType) => {
  const {
    substanceData: {hazard_statements, precautionary_statements, name, signal_words, symbol_ghs},
  } = props;
  const [settings, setSettings] = useState({size: 'small', amount: 1});

  function updateSettings(value: string | number, type: string) {
    if (type === 'amount' && typeof value === 'number') {
      setSettings({...settings, [type]: value > 50 ? 50 : value});
    } else {
      setSettings({...settings, [type]: value === undefined ? 'small' : value});
    }
  }

  return (
    <ul className="information-list">
      <CollapsableComponentGroup title="Instellingen">
        <SelectField
          name="size"
          label="Formaat"
          options={[
            {value: 'small', label: 'A7 (105 x 74 mm)'},
            {value: 'large', label: 'A8 (74 x 52 mm)'},
          ]}
          onChangedValue={(e) => updateSettings((e as SelectItem)?.value, 'size')}
        />
      </CollapsableComponentGroup>
      <CollapsableComponentGroup
        title="Pdf"
        open
      >
        <PDFViewer>
          <PdfGeneratorLabel
            size={settings.size === 'small' ? PageSize.A7 : PageSize.A8}
            amount={settings.amount}
            generalInformation={{
              name,
              hStatements: generateFlatStatements(hazard_statements, true),
              pStatements: generateFlatStatements(precautionary_statements, true),
              dangerSymbols: generateSymbolList(symbol_ghs),
              dangerClass: signal_words,
            }}
          />
        </PDFViewer>
      </CollapsableComponentGroup>
    </ul>
  );
};
export default PrintLabel;
