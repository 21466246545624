import React from 'react';
import { InfinitySpin } from 'react-loader-spinner';

import './Loading.scss';

const Loading = () => (
  <div className="loading-container">
    <InfinitySpin
      width="100"
      color="#5776B0"
    />
  </div>
);

export default Loading;
