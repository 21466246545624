import React from 'react';
import useBem from '../../../../../Hooks/useBem';
import { ProductComponentType } from '../../../../../Models/Types/ProductComponentType';
import ComponentRow from './Component';

import './ComponentList.scss';

type PropType = {
  substanceComponents: ProductComponentType[];
};

const ComponentList = (props: PropType) => {
  const { substanceComponents } = props;
  const [bemClassName, bemClassNames] = useBem('form-field');

  return (
    <div className={bemClassName('table', 'components')}>
      <span className={bemClassName('label')}>Samenstelling (Componenten)</span>
      <table className={bemClassName('table-grid')}>
        <thead>
          <tr className={bemClassName('table-grid-row')}>
            <th
              className={bemClassNames([bemClassName('table-grid-cell'), bemClassName('table-grid-cell', 'substance')])}
            >
              Component
            </th>
            <th
              className={bemClassNames([
                bemClassName('table-grid-cell'),
                bemClassName('table-grid-cell', 'cas-number'),
              ])}
            >
              Cas nummer
            </th>
            <th
              className={bemClassNames([bemClassName('table-grid-cell'), bemClassName('table-grid-cell', 'h-codes')])}
            >
              H-zinnen
            </th>
            <th className={bemClassName('table-grid-cell')}>%</th>
          </tr>
        </thead>
        <tbody>
          {substanceComponents?.map((component) => {
            const { id } = component.component;
            return (
              <ComponentRow
                component={component}
                key={id}
                percentage={component.percentage}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ComponentList;
