import React from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { SelectItem } from '../../../../../Models/Types/SelectItem';
import useOrgansation from '../../../../../Shared/Hooks/useOrganisation';
import { Department, DepartmentResponse } from '../../../../../Models/Types/Department';
import { Room, RoomResponse } from '../../../../../Models/Types/Room';
import { Closet, ClosetResponse } from '../../../../../Models/Types/Closet';
import { FilterListItem } from '../../../../SubstancesRegister/types';
import Button from '../../../../../Shared/Components/Button/Button';
import { ButtonTypes } from '../../../../../Shared/Components/Button/enums';
import { checkUserRole } from '../../../../../Shared/Data/UserService';
import { Hospital, HospitalResponse } from '../../../../../Models/Types/Hospital';

type PropType = {
  onFilterListChange: (value: FilterListItem[]) => void;
};

const SubstancesRegisterList = (props: PropType) => {
  const { onFilterListChange } = props;
  const navigate = useNavigate();
  const usersFilterList: FilterListItem[] = localStorage.usersFilterList
    ? JSON.parse(localStorage.usersFilterList)
    : [];
  const { isLoading: isLoadingOrganisations, data: organisations } = useOrgansation();

  function updateFilterList(value: SelectItem, filter: string) {
    const filterPresent = usersFilterList.find((filterItem) => filterItem.name === filter);

    if (filterPresent !== undefined) {
      filterPresent.filter = value;
    } else {
      usersFilterList.push({ name: filter, filter: value });
    }

    onFilterListChange([...usersFilterList]);

    if (localStorage.usersFilterList) {
      localStorage.usersFilterList = JSON.stringify([...usersFilterList]);
    } else {
      localStorage.setItem('usersFilterList', JSON.stringify([...usersFilterList]));
    }
  }

  function retrieveFilterValue(filter: string) {
    if (localStorage.usersFilterList) {
      return JSON.parse(localStorage.usersFilterList).find((filterItem: FilterListItem) => filterItem.name === filter)
        ? JSON.parse(localStorage.usersFilterList).find((filterItem: FilterListItem) => filterItem.name === filter)
            .filter
        : undefined;
    }
    return undefined;
  }

  function buildOptionsList(
    list: HospitalResponse | DepartmentResponse | RoomResponse | ClosetResponse | undefined,
  ): SelectItem[] {
    const options: SelectItem[] = [];

    list?.items.forEach((item: Hospital | Department | Room | Closet) => {
      const { id, name } = item;
      options.push({ value: id, label: name });
    });

    return options;
  }

  return (
    <div className="filter-bar">
      <div className="filter-bar__filters">
        {checkUserRole('administrator') && (
          <>
            <p>Filteren op:</p>
            {!isLoadingOrganisations && (
              <Select
                classNamePrefix="select-box"
                placeholder="Organisatie"
                options={buildOptionsList(organisations as HospitalResponse)}
                defaultValue={retrieveFilterValue('organisation')}
                onChange={(value) => updateFilterList(value as SelectItem, 'organisation')}
                isClearable
              />
            )}
          </>
        )}
      </div>

      <div className="filter-bar__actions">
        <Button
          title="Gebruiker toevoegen"
          onHandleClick={() => navigate('/beheer/gebruikers/toevoegen')}
          theme={ButtonTypes.Solid}
        />
      </div>
    </div>
  );
};

export default SubstancesRegisterList;
