import React from 'react';
import HazardClassesFilterBar from './ComponentFilterBar';
import ComponentList from './ComponentList';

import './ComponentList.scss';

type PropType = {
  handleOpenModal: (id: string) => void;
};
const ComponentListContent = (props: PropType) => {
  const { handleOpenModal } = props;

  return (
    <>
      <HazardClassesFilterBar />
      <div className="list-container">
        <ComponentList handleOpenModal={(id) => handleOpenModal(id)} />
      </div>
    </>
  );
};

export default ComponentListContent;
