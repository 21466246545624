import http from '../../Utils/HttpClient';

const getAllPackagingGroups = async (url?: URL) => {
  const { data } = url ? await http.get(url.toString()) : ((await http.get(`packaging-group`)) as any);

  return data;
};

const getPackagingGroup = async (id: string) => {
  const { data } = (await http.get(`packaging-group/${id}`)) as any;

  return data;
};

const patchPackagingGroup = async (packagingGroup: { title: string }, id: string) => {
  const data = await http.patch(`packaging-group/${id}`, packagingGroup);

  if (!data) return null;
  return data;
};

const postPackagingGroup = async (packagingGroup: any) => {
  const data = await http.post(`packaging-group`, packagingGroup);

  if (!data) return null;
  return data;
};

export { getAllPackagingGroups, getPackagingGroup, patchPackagingGroup, postPackagingGroup };
