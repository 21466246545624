export enum ProductTypes {
  All = 'all',
  Product = 'product',
  Preparation = 'preparation',
}

export enum SubstanceRegisterEditFormListTabs {
  General = 'general',
  Organisation = 'organisation',
}

export enum SubstancesRegisterDetailInformationTabs {
  General = 'generalInformation',
  GeneralAdmin = 'generalInformationAdmin',
  OrganisationSpecific = 'organisationSpecificInformation',
  WorkInstructionCard = 'workInstructionCard',
  PrintLabel = 'printLabel',
  VIB = 'VIB',
  EHBO = 'EHBO',
  SourceDocument = 'sourceDocument',
  Share = 'share',
}

export enum SettingTypes {
  Amount = 'amount',
  SelectedOptions = 'selectedOptions',
  Department = 'department',
  EmergencyNumber = 'emergencyNumber',
  Notes = 'notes',
}

export enum StatusTypes {
  SaveAsDraft = 'draft',
  SubmitForApproval = 'approval',
  SaveAndPublish = 'published',
}
