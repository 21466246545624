import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { SymbolResponseType, SymbolType } from '../../Models/Types/SymbolType';
import { getAllSymbols, getSymbol } from '../Data/SymbolService';

const useSymbol = (id?: string): UseQueryResult<SymbolType | SymbolResponseType, Error> =>
  useQuery({
    queryKey: [id ? `symbol-${id}` : `symbols`], 
    queryFn: () => (id ? getSymbol(id) : getAllSymbols())
  });

export default useSymbol;
