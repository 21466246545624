import React, { useState } from 'react';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import HeaderSeperationBar from '../../../Shared/Components/HeaderSeperationBar/HeaderSeperationBar';
import PackagingGroupsListContent from './Components/AppearancesList/PackagingGroupsListContent';
import Modal from '../../../Shared/Components/Modal/Modal';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';

const PackagingGroups = () => {
  if (!checkUserRight('entity.packaging_group.GET')) {
    return <NoPermission />;
  }

  const [showModal, setShowDModal] = useState(false);
  const [selectedPackagingId, setSelectedPackagingId] = useState<string | undefined>(undefined);

  function openModal(id: string) {
    setShowDModal(true);
    setSelectedPackagingId(id);
  }

  function removePackagingGroup(id: string | undefined) {
    // TODO: Implement remove packagingGroup functionality
    setShowDModal(false);

    // eslint-disable-next-line no-console
    console.log(`Remove packaging Group with id: ${id}`);
  }

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            title="Verpakkingsgroepen beheren"
            onHandleEnterKeyDown={() => {}}
            showSearchBar
          />
          <HeaderSeperationBar />
          <PackagingGroupsListContent handleOpenModal={(id) => openModal(id)} />
        </section>
      </div>
      <Modal
        showModal={showModal}
        modalTitle="Verpakkingsgroep verwijderen"
        modalDescription="Wilt u deze verpakkingsgroep definitief verwijderen?"
        modalButtonLeftText="Annuleren"
        modalButtonRightText="Verwijderen"
        rightButtonEnabled
        onLeftAction={() => setShowDModal(false)}
        onRightAction={() => removePackagingGroup(selectedPackagingId)}
        onHandleClose={() => setShowDModal(false)}
      />
    </>
  );
};

export default PackagingGroups;
