import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getAllOrganisations, getOrganisation } from '../Data/OrganisationService';
import { Hospital, HospitalResponse } from '../../Models/Types/Hospital';

const useOrganisation = (id?: string): UseQueryResult<HospitalResponse | Hospital, Error> =>
  useQuery({
    queryKey: [`organisation-${id}`],
    queryFn: () => (id ? getOrganisation(id) : getAllOrganisations()),
  });

export default useOrganisation;
