import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { DataResult, State } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords,
} from '@progress/kendo-react-grid';
import HazardClassesLoader from './HazardClassesLoader';

import './HazardClassesList.scss';
import { generateFlatStatements } from '../../../../../Shared/Functions/generateStatements';

type PropType = {
  handleOpenModal: (id: string) => void;
};

const HazardClassesList = (props: PropType) => {
  const { handleOpenModal } = props;
  const { search } = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = React.useState<DataResult>({
    data: [],
    total: 0,
  });
  const [errorMessage, setErrorMessage] = useState<string[]>(['Laden...']);
  const [searchValue, setSearchValue] = useState<string | undefined>(search);
  const [dataState, setDataState] = React.useState<State>({
    take: 10,
    skip: 0,
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState({ ...e.dataState });
  };

  if (searchValue !== search) {
    setSearchValue(search);
    if (search === undefined) {
      delete dataState.filter;
    } else {
      dataState.skip = 0;
      dataState.filter = {
        logic: 'and',
        filters: [
          {
            field: 'title',
            operator: 'contains',
            value: search,
          },
        ],
      };
    }
    setDataState({ ...dataState });
  }

  const dataReceived = (productList: DataResult) => {
    setProducts(productList);
  };

  const hazardClassStatementCell = (subCategoryProps: GridCellProps) => {
    const {
      dataItem: { hazard_statements },
    } = subCategoryProps;

    return <td className="dangerClass">{generateFlatStatements(hazard_statements)}</td>;
  };

  const actionsCell = (subCategoryProps: GridCellProps) => {
    const {
      dataItem: { id },
    } = subCategoryProps;

    return (
      <td className="hazardClassActions">
        <button
          type="button"
          className="stripped-button"
          onClick={() => navigate(`/beheer/gevarenklassen/detail/${id}`)}
        >
          Wijzigen
        </button>
        -
        <button
          type="button"
          className="stripped-button"
          onClick={() => handleOpenModal(id)}
        >
          Verwijderen
        </button>
      </td>
    );
  };

  function handleError(error: string[]) {
    setErrorMessage(error);
  }

  return (
    <>
      <Grid
        sortable
        pageable
        {...dataState}
        data={products}
        onDataStateChange={dataStateChange}
      >
        <GridNoRecords>
          <div className="error-message">
            {errorMessage.length > 0 ? (
              errorMessage.map((message) => (
                <span
                  key={message}
                  className="error-message__content"
                >
                  {message}
                </span>
              ))
            ) : (
              <span className="error-message__content">Geen data gevonden...</span>
            )}
          </div>
        </GridNoRecords>

        <Column
          field="title"
          title="Gevarenklasse"
          className="hazardClassTitle"
          headerClassName="hazardClassTitle"
        />
        <Column
          field="hazard_statements"
          cell={hazardClassStatementCell}
          title="H/EUH-zin"
          className="hazardClassStatement"
          headerClassName="hazardClassStatement"
        />
        <Column
          cell={actionsCell}
          sortable={false}
          title="Acties"
          className="hazardActions"
          headerClassName="hazardActions"
        />
      </Grid>
      <HazardClassesLoader
        dataState={dataState}
        onDataReceived={dataReceived}
        onError={(error) => handleError(error)}
      />
    </>
  );
};

export default HazardClassesList;
