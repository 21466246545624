import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TabBarItem } from '../../Shared/Components/TabBar/types';
import Navigation from '../../Shared/Components/Navigation/Navigation';
import SubstancesRegisterHeader from './Components/SubstancesRegisterHeader';
import SubstancesRegisterDetailInformation from './Components/Detail/SubstancesRegisterDetailInformation';
import Modal from '../../Shared/Components/Modal/Modal';
import useSubstance from '../../Shared/Hooks/useSubstance';
import { Substance } from '../../Models/Types/Substance';
import { SubstancesRegisterDetailInformationTabs } from './enums';
import { checkUserRight, checkUserRole } from '../../Shared/Data/UserService';
import Loading from '../../Shared/Components/Loading/Loading';
import useSubstanceRevision from '../../Shared/Hooks/useSubstanceRevision';

function buildTabbarItems(tab: SubstancesRegisterDetailInformationTabs) {
  let tabBarItemsList: TabBarItem[] = [
    {
      title: 'Algemeen',
      tab: SubstancesRegisterDetailInformationTabs.General,
      active: tab === undefined || tab === SubstancesRegisterDetailInformationTabs.General,
    },
  ];

  if (checkUserRole('hospital_administrator') || checkUserRole('viewer_plus')) {
    tabBarItemsList = [
      ...tabBarItemsList,
      {
        title: 'Organisatie specifieke informatie',
        tab: SubstancesRegisterDetailInformationTabs.OrganisationSpecific,
        active: tab === SubstancesRegisterDetailInformationTabs.OrganisationSpecific,
      },
    ];
  }

  tabBarItemsList = [
    ...tabBarItemsList,
    {
      title: 'EHBO',
      tab: SubstancesRegisterDetailInformationTabs.EHBO,
      active: tab === SubstancesRegisterDetailInformationTabs.EHBO,
    },
    {
      title: 'WIK',
      tab: SubstancesRegisterDetailInformationTabs.WorkInstructionCard,
      active: tab === SubstancesRegisterDetailInformationTabs.WorkInstructionCard,
    },
    {
      title: 'VIB',
      tab: SubstancesRegisterDetailInformationTabs.VIB,
      active: tab === SubstancesRegisterDetailInformationTabs.VIB,
    },
    {
      title: 'Etiket',
      tab: SubstancesRegisterDetailInformationTabs.PrintLabel,
      active: tab === SubstancesRegisterDetailInformationTabs.PrintLabel,
    },
    {
      title: 'Brondocument',
      tab: SubstancesRegisterDetailInformationTabs.SourceDocument,
      active: tab === SubstancesRegisterDetailInformationTabs.SourceDocument,
    },
    { title: 'Delen / QR', tab: SubstancesRegisterDetailInformationTabs.Share },
  ];

  return tabBarItemsList;
}

const SubstancesRegisterDetail = () => {
  const { id, rev_id, tab } = useParams();
  const navigate = useNavigate();

  let substance: Substance | undefined;
  let isLoading;

  if (rev_id !== undefined) {
    const { data: revision, isLoading: revisionLoading } = useSubstanceRevision(id!, parseInt(rev_id, 10));
    substance = revision;
    isLoading = revisionLoading;
  } else {
    const { data: published, isLoading: publishedLoading } = useSubstance(id!);
    substance = published;
    isLoading = publishedLoading;
  }

  const [showModal, setShowModal] = useState(false);
  const [tabBarItems, setTabBarItems] = useState<TabBarItem[]>(
    buildTabbarItems(tab as SubstancesRegisterDetailInformationTabs),
  );

  function handleTabBarClick(selectedTab: string) {
    tabBarItems.forEach((tabBarItem) => {
      // eslint-disable-next-line no-param-reassign
      tabBarItem.active = tabBarItem.tab === selectedTab;
    });
    setTabBarItems([...tabBarItems]);
  }

  const subtext = {
    __html:
      '<b>Let op!</b> Het wijzigen van algemene informatie gaat ter goedkeuring naar de Gevaarlijke Stoffen Applicatie beheerder.',
  };

  const navigateToOrganisationSpecifics = () => {
    const { revision_id } = substance!;
    navigate(`/productenregister/detail/${id}/${revision_id}/organisatie-specifieke-informatie-wijzigen`);
  };

  const navigateToProductInformation = () => {
    const { revision_id } = substance!;
    navigate(`/productenregister/detail/${id}/${revision_id}/algemene-informatie-wijzigen`);
  };

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <SubstancesRegisterHeader
                title={substance!.name}
                tabBarItems={tabBarItems}
                onHandleTabBarClick={(selectedTab) => handleTabBarClick(selectedTab)}
                onHandleSearchRequest={() => {}}
                onHandleEditClick={() => {
                  if (checkUserRight('entity.hospital_product.PATCH')) {
                    setShowModal(true);
                  } else {
                    navigateToOrganisationSpecifics();
                  }
                }}
                showBackButton
                showEditButton={checkUserRight('entity.hospital_product.PATCH')}
              />
              {substance ? (
                <SubstancesRegisterDetailInformation
                  activeTab={
                    tabBarItems.find((tabBarItem) => tabBarItem.active)?.tab as SubstancesRegisterDetailInformationTabs
                  }
                  substanceData={substance}
                />
              ) : (
                <ul className="information-list">
                  <div>Er is geen informatie gevonden voor dit product.</div>
                </ul>
              )}
            </>
          )}
        </section>
      </div>
      <Modal
        showModal={showModal}
        modalTitle="Stof wijzigen"
        modalDescription="Welke informatie wilt u wijzigen?"
        modalButtonLeftText="Algemene informatie"
        modalButtonRightText="Organisatie specifiek"
        subText={subtext}
        rightButtonEnabled
        onLeftAction={() => navigateToProductInformation()}
        onRightAction={() => navigateToOrganisationSpecifics()}
        onHandleClose={() => setShowModal(false)}
      />
    </>
  );
};

export default SubstancesRegisterDetail;
