import React from 'react';
import Navigation from '../Navigation/Navigation';

const Loading = () => (
  <>
    <Navigation />
    <div className="content-container">
      <div className="list-container">
        <p>Je hebt niet de juiste rechten</p>
      </div>
    </div>
  </>
);

export default Loading;
