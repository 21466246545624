import React, { useRef } from 'react';

import { useParams } from 'react-router-dom';
import { toODataString } from '@progress/kendo-data-query';
import LoadingPanel from '../../../../../Shared/Components/LoadingPanel/LoadingPanel';
import { ItemLoaderType } from '../../../../../Models/Types/ItemLoaderType';
import { getAllUsers } from '../../../../../Shared/Data/UserService';

const UsersLoader = (props: ItemLoaderType) => {
  const { search } = useParams();
  const { dataState, onDataReceived, onError } = props;
  const base = window.__RUNTIME_CONFIG__.BACKEND_BASEURL;

  function selectedHospitalId(): string | undefined {
    const filterList = localStorage.usersFilterList ? JSON.parse(localStorage.usersFilterList) : undefined;
    let filterId: string | undefined;
    if (filterList && filterList[0].filter !== null) {
      filterId = filterList[0].filter.value;
    }
    return filterId;
  }

  const url =
    selectedHospitalId() !== undefined
      ? new URL(`${base}hospital/${selectedHospitalId()}/user`)
      : new URL(`${base}user`);

  const lastSuccess = useRef<string>('');
  const pending = useRef<string>('');

  url.searchParams.append('page', dataState.skip ? (dataState.skip / 10).toString() : '0');
  url.searchParams.append('page_limit', '10');

  if (search) {
    url.searchParams.append('search', search);
  }

  const requestDataIfNeeded = () => {
    if (pending.current || toODataString(dataState) === lastSuccess.current) {
      return;
    }
    pending.current = toODataString(dataState);
    getAllUsers(url)
      .catch((error) => {
        switch (error.status) {
          case 500:
            onDataReceived.call(undefined, {
              data: [],
              total: 0,
            });

            onError([
              'Er ging iets fout op onze server.',
              'Probeer het later opnieuw of neem contact op met de beheerder.',
            ]);

            break;
          case 403:
            onDataReceived.call(undefined, {
              data: [],
              total: 0,
            });

            onError(['Authenticatie is vereist.', 'Log opnieuw in of gebruik een account met meer rechten. ']);

            break;
          default:
            onError(['Er ging iets fout.', 'Probeer het later opnieuw of neem contact op met de beheerder.']);
            break;
        }
      })
      .then((response) => {
        lastSuccess.current = pending.current;
        pending.current = '';
        if (response !== undefined) {
          if (toODataString(dataState) === lastSuccess.current) {
            onDataReceived.call(undefined, {
              data: response.items,
              total: response.total,
            });

            if (response.total === 0) {
              onError(['Geen data gevonden...']);
            }
          } else {
            requestDataIfNeeded();
          }
        }
      });
  };

  requestDataIfNeeded();
  return pending.current ? <LoadingPanel /> : null;
};

export default UsersLoader;
