import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../../Shared/Components/Button/Button';
import { ButtonTypes } from '../../../../../Shared/Components/Button/enums';
import useBem from '../../../../../Hooks/useBem';

const SubstancesRegisterList = () => {
  const [bemClassName] = useBem('filter-bar');
  const { organisationId, departmentId, roomId } = useParams();
  const navigate = useNavigate();

  let buttonTitle: string = 'Afdeling toevoegen';

  if (organisationId && departmentId) {
    buttonTitle = 'Ruimte toevoegen';
  }

  if (organisationId && departmentId && roomId) {
    buttonTitle = 'Kast toevoegen';
  }

  const addUrl = () => {
    if (roomId)
      return `/beheer/organisatiestructuur/${organisationId}/afdelingen/${departmentId}/kamers/${roomId}/toevoegen`;
    if (departmentId) return `/beheer/organisatiestructuur/${organisationId}/afdelingen/${departmentId}/toevoegen`;
    if (organisationId) return `/beheer/organisatiestructuur/${organisationId}/toevoegen`;
    return '/';
  };

  return (
    <div className="filter-bar">
      <div className={bemClassName('filters')} />

      <div className={bemClassName('actions')}>
        <Button
          title={buttonTitle}
          onHandleClick={() => navigate(addUrl())}
          theme={ButtonTypes.Solid}
        />
      </div>
    </div>
  );
};

export default SubstancesRegisterList;
