import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { DataResult, State } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords,
} from '@progress/kendo-react-grid';
import ItemsLoader from './ItemsLoader';

import './OrganisationsList.scss';

type PropType = {
  handleOpenModal: (id: string) => void;
};
const ItemsList = (props: PropType) => {
  const { handleOpenModal } = props;
  const { organisationId, departmentId, roomId } = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState<DataResult>({
    data: [],
    total: 0,
  });
  const [errorMessage, setErrorMessage] = useState<string[]>(['Laden...']);
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0,
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState({ ...e.dataState });
  };

  const dataReceived = (productList: DataResult) => {
    setProducts(productList);
  };

  const itemNameCell = (subCategoryProps: GridCellProps) => {
    const {
      dataItem: { id, name },
    } = subCategoryProps;

    let navLink = `/beheer/organisatiestructuur/${organisationId}/afdelingen/${id}`;

    if (departmentId) {
      navLink = `/beheer/organisatiestructuur/${organisationId}/afdelingen/${departmentId}/kamers/${id}`;
    }

    if (roomId) {
      navLink = `/beheer/organisatiestructuur/${organisationId}/afdelingen/${departmentId}/kamers/${roomId}`;
    }

    return (
      <td className="itemName">
        {roomId ? (
          <span>{name}</span>
        ) : (
          <button
            type="button"
            className="stripped-button"
            onClick={() => navigate(navLink)}
          >
            {name}
          </button>
        )}
      </td>
    );
  };

  const actionsCell = (subCategoryProps: GridCellProps) => {
    const {
      dataItem: { id },
    } = subCategoryProps;

    let editLink = `/beheer/organisatiestructuur/${organisationId}/afdelingen/${id}/wijzigen`;

    if (departmentId) {
      editLink = `/beheer/organisatiestructuur/${organisationId}/afdelingen/${organisationId}/kamers/${id}/wijzigen`;
    }

    if (roomId) {
      editLink = `/beheer/organisatiestructuur/${organisationId}/afdelingen/${organisationId}/kamers/${roomId}/kasten/${id}/wijzigen`;
    }

    return (
      <td className="organisationActions">
        <button
          type="button"
          className="stripped-button"
          onClick={() => navigate(editLink)}
        >
          Wijzigen
        </button>
        -
        <button
          type="button"
          className="stripped-button"
          onClick={() => handleOpenModal(id)}
        >
          Verwijderen
        </button>
      </td>
    );
  };

  function handleError(error: string[]) {
    setErrorMessage(error);
  }

  return (
    <>
      <Grid
        sortable
        pageable
        {...dataState}
        data={products}
        onDataStateChange={dataStateChange}
      >
        <GridNoRecords>
          <div className="error-message">
            {errorMessage.length > 0 ? (
              errorMessage.map((message) => (
                <span
                  key={message}
                  className="error-message__content"
                >
                  {message}
                </span>
              ))
            ) : (
              <span className="error-message__content">Geen data gevonden...</span>
            )}
          </div>
        </GridNoRecords>
        <Column
          field="status"
          title="Status"
          cell={(statusCellProps: GridCellProps) =>
            <td className="itemStatus">{statusCellProps.dataItem.status ? 'Actief' : 'Inactief'}</td>}
          className="itemStatus"
          headerClassName="itemStatus"
        />
        <Column
          field="name"
          cell={itemNameCell}
          title="Naam"
          className="itemName"
          headerClassName="itemName"
        />

        <Column
          cell={actionsCell}
          sortable={false}
          title="Acties"
          className="organisationActions"
          headerClassName="organisationActions"
        />
      </Grid>
      <ItemsLoader
        dataState={dataState}
        onDataReceived={dataReceived}
        onError={(error) => handleError(error)}
      />
    </>
  );
};

export default ItemsList;