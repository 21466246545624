import http from '../../Utils/HttpClient';

const getAllClosets = async (url?: URL) => {
  url?.searchParams.append('sort', 'name');
  const { data } = url ? await http.get(url.toString()) : ((await http.get(`closet?sort=name`)) as any);
  
  return data;
};

const getCloset = async (id: string) => {
  const { data } = (await http.get(`closet/${id}`)) as any;

  return data;
};

const patchCloset = async (closet: { name: string }, id: string) => {
  const data = await http.patch(`closet/${id}`, closet);

  if (!data) return null;
  return data;
};

const unpublishCloset = async (id: string) => {
  const data = await http.patch(`closet/${id}`, { status: false });

  if (!data) return null;
  return data;
};

const postCloset = async (closet: { name: string; room: string }) => {
  const data = await http.post(`closet`, closet);

  if (!data) return null;
  return data;
};

export { postCloset, patchCloset, getCloset, getAllClosets, unpublishCloset};
