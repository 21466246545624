import http from '../../Utils/HttpClient';

const postContactForm = async (contactform: {
  name: string;
  organisation: string;
  email: string;
  telephone: string;
  message: string;
}) => {
  const data = await http.post(`contact`, contactform);

  if (!data) return null;
  return data;
};

export default postContactForm;
