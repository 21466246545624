import React from 'react';
import ExtinguishingAgentsFilterBar from './ExtinguishingAgentsFilterBar';
import ExtinguishingAgentsList from './ExtinguishingAgentsList';

import './ExtinguishingAgentsList.scss';

type PropType = {
  handleOpenModal: (id: string) => void;
};

const ExtinguishingAgentsListContent = (props: PropType) => {
  const { handleOpenModal } = props;

  return (
    <>
      <ExtinguishingAgentsFilterBar />
      <div className="list-container">
        <ExtinguishingAgentsList handleOpenModal={(id) => handleOpenModal(id)} />
      </div>
    </>
  );
};

export default ExtinguishingAgentsListContent;
