import React, { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import useBem from '../../../Hooks/useBem';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import InputField from '../../../Shared/Components/Form/InputField';
import Button from '../../../Shared/Components/Button/Button';
import { ButtonTypes } from '../../../Shared/Components/Button/enums';
import SelectField from '../../../Shared/Components/Form/SelectField';

import '../../../Shared/Styling/EditAddGrid.scss';
import handleError from '../../../Shared/Functions/handleError';
import useSymbol from '../../../Shared/Hooks/useSymbol';
import { patchSymbol } from '../../../Shared/Data/SymbolService';
import UploadField from '../../../Shared/Components/Form/UploadField';
import { FileTypes, FillUpSize } from '../../../Shared/Components/Form/enums';
import FillUpField from '../../../Shared/Components/Form/FillUpField';
import Loading from '../../../Shared/Components/Loading/Loading';
import { SymbolType } from '../../../Models/Types/SymbolType';
import { SelectItem } from '../../../Models/Types/SelectItem';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';

const EditSymbol = () => {
  if (!checkUserRight('entity.symbol.PATCH')) {
    return <NoPermission />;
  }

  const [bemClassName] = useBem('edit-add-grid');
  const { id } = useParams();
  const { data, isLoading } = useSymbol(id);
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const symbolTypeList: SelectItem[] = [
    { value: 'ghs', label: 'GHS' },
    { value: 'adr', label: 'ADR' },
    { value: 'pbm', label: 'PBM' },
  ];

  const submit = useCallback(async (e: any) => {
    const { title, type, image } = e.target;
    const symbol = {
      title: title.value,
      type: type.value,
      image: image.value,
    };
    patchSymbol(symbol as { title: string; type: string; image: string }, id!)
      .catch((err) => {
        setSuccessMessage(undefined);
        setErrorMessage(handleError(err));
      })
      .then((result: any) => {
        if (result) {
          setErrorMessage(undefined);
          setSuccessMessage('Symbool succesvol aangepast');

          queryClient.invalidateQueries({ queryKey: [`symbol-${id!}`] });
        }
      });
  }, []);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await submit(e);
  };

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {}}
            title="Symbool aanpassen"
            showBackButton
          />
          <div className={bemClassName()}>
            <div className={bemClassName('content')}>
              <h1 className={bemClassName('header')}>Gegevens</h1>

              {isLoading ? (
                <Loading />
              ) : (
                <form
                  method="post"
                  onSubmit={(event) => submitHandler(event)}
                >
                  <ul className={bemClassName('input-list')}>
                    <InputField
                      name="title"
                      label="Naam symbool"
                      placeholder="Naam symbool"
                      value={(data as SymbolType)?.title}
                    />
                    <SelectField
                      name="type"
                      label="Symbool type"
                      value={symbolTypeList.find((type) => type.value === (data as SymbolType)?.type)}
                      options={symbolTypeList}
                    />
                    <FillUpField size={FillUpSize.Small} />
                    <UploadField
                      name="image"
                      label="Symbool"
                      type={FileTypes.Image}
                      document={(data as SymbolType)?.image}
                      imgUrl={(data as SymbolType)?.image?.image?.url}
                      showPreview
                      url="symbol/image"
                    />
                    <FillUpField size={FillUpSize.Large} />

                    {errorMessage && <p className={bemClassName('error-message')}>{errorMessage}</p>}
                    {successMessage && <p className={bemClassName('success-message')}>{successMessage}</p>}

                    <Button
                      submit
                      theme={ButtonTypes.Solid}
                      title="Symbool aanpassen"
                    />
                  </ul>
                </form>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default EditSymbol;
