import React from 'react';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import AddProductForm from './Components/AddProductForm';

import '../../SubstancesRegister/Components/EditForm/SubstancesRegisterEditFormList.scss';

const SubstancesRegisterEditForm = () => {
  if (!checkUserRight('entity.product.POST')) {
    return <NoPermission />;
  }

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {}}
            title="Product toevoegen"
            showBackButton
          />
          <div className="substances-register-edit-form">
            <AddProductForm />
          </div>
        </section>
      </div>
    </>
  );
};

export default SubstancesRegisterEditForm;
