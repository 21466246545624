import React, { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import useBem from '../../../Hooks/useBem';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import InputField from '../../../Shared/Components/Form/InputField';
import Button from '../../../Shared/Components/Button/Button';
import { ButtonTypes } from '../../../Shared/Components/Button/enums';

import '../../../Shared/Styling/EditAddGrid.scss';
import UploadField from '../../../Shared/Components/Form/UploadField';
import { FileTypes } from '../../../Shared/Components/Form/enums';
import useOrganisation from '../../../Shared/Hooks/useOrganisation';
import { Hospital } from '../../../Models/Types/Hospital';
import { patchOrganisation } from '../../../Shared/Data/OrganisationService';
import handleError from '../../../Shared/Functions/handleError';
import Loading from '../../../Shared/Components/Loading/Loading';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';

type PropType = {
  organisationId?: string;
};

const EditOrganisation = (props: PropType) => {
  if (!checkUserRight('entity.hospital.PATCH')) {
    return <NoPermission />;
  }

  const { organisationId } = props;
  const [bemClassName] = useBem('edit-add-grid');
  const { id } = useParams();
  const { data: organisation, isLoading } = useOrganisation(organisationId || id);
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const hospital = organisation as Hospital;

  const submit = useCallback(async (e: any) => {
    const {
      target: { logo, name },
    } = e;
    const editedOrganisation = {
      name: name.value,
      logo: logo.value,
    };
    patchOrganisation(editedOrganisation as { name: string; logo?: string }, organisationId || id!)
      .catch((err) => {
        setSuccessMessage(undefined);
        setErrorMessage(handleError(err));
      })
      .then((result: any) => {
        if (result) {
          setErrorMessage(undefined);
          setSuccessMessage('Organisatie succesvol aangepast');

          queryClient.invalidateQueries({ queryKey: [`organisation-${organisationId || id!}`] });
        }
      });
  }, []);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await submit(e);
  };

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {}}
            title="Organisatie aanpassen"
            showBackButton
          />
          <div className={bemClassName()}>
            <div className={bemClassName('content')}>
              <h1 className={bemClassName('header')}>Gegevens</h1>

              {organisationId === undefined && id === undefined && (
                <p className={bemClassName('error-message')}>We kunnen geen organisatie-id vinden.</p>
              )}

              {isLoading ? (
                <Loading />
              ) : (
                <form
                  method="post"
                  onSubmit={(event) => submitHandler(event)}
                >
                  {(organisationId !== undefined || id !== undefined) && (
                    <ul className={bemClassName('input-list')}>
                      <InputField
                        name="name"
                        value={hospital?.name}
                        label="Naam organisatie"
                        placeholder="Naam organisatie"
                      />
                      <UploadField
                        name="logo"
                        label="Bedrijfslogo"
                        document={hospital?.logo}
                        type={FileTypes.Image}
                        showPreview
                        imgUrl={hospital?.logo?.url}
                        url="hospital/logo"
                      />

                      {errorMessage && <p className={bemClassName('error-message')}>{errorMessage}</p>}
                      {successMessage && <p className={bemClassName('success-message')}>{successMessage}</p>}

                      <Button
                        submit
                        theme={ButtonTypes.Solid}
                        title="Organisatie aanpassen"
                      />
                    </ul>
                  )}
                </form>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

EditOrganisation.defaultProps = {
  organisationId: undefined,
};

export default EditOrganisation;
