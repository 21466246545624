import React from 'react';

import './HeaderSeperationBar.scss';

const HeaderSeperationBar = () => (
  <div className="header-seperation-bar">
    <div className="header-seperation-bar__line" />
  </div>
);

export default HeaderSeperationBar;
