import React, {SyntheticEvent, useCallback, useState} from 'react';
import {SingleValue} from 'react-select';
import {useNavigate, useParams} from 'react-router-dom';
import CollapsableFormGroup from '../../../../Shared/Components/CollapsableContent/CollapsableComponentGroup';
import InputField from '../../../../Shared/Components/Form/InputField';
import SelectField from '../../../../Shared/Components/Form/SelectField';
import TextAreaInputField from '../../../../Shared/Components/Form/TextAreaInputField';
import CheckboxList from '../../../../Shared/Components/Form/CheckboxList';
import Button from '../../../../Shared/Components/Button/Button';
import {ButtonTypes} from '../../../../Shared/Components/Button/enums';
import {patchSubstance} from '../../../../Shared/Data/SubstanceService';
import {Substance} from '../../../../Models/Types/Substance';
import UploadField from '../../../../Shared/Components/Form/UploadField';
import SubstancesTable from './FormComponents/SubstancesTable';
import {SelectItem} from '../../../../Models/Types/SelectItem';
import {Company} from '../../../../Models/Types/Company';
import {FileTypes} from '../../../../Shared/Components/Form/enums';
import {
  generateStatementResponseSelectFieldList,
  generateStatementSelectFieldList
} from '../../../../Shared/Functions/generateStatements';
import {Statement, StatementResponse} from '../../../../Models/Types/Statement';
import useHazardStatement from '../../../../Shared/Hooks/useHazardStatement';
import Loading from '../../../../Shared/Components/Loading/Loading';
import {getAllSuppliers} from '../../../../Shared/Data/SupplierService';
import returnSearchUrl from '../../../../Shared/Functions/returnSearchUrl';
import {getAllHazardClasses, getHazardClass} from '../../../../Shared/Data/HazardClassService';
import {HazardClass} from '../../../../Models/Types/HazardClass';
import usePrecautionary from '../../../../Shared/Hooks/usePrecautionary';
import {getAllExtinguishingMedia} from '../../../../Shared/Data/ExtinguishingMediaService';
import {ExtinguishingMedia} from '../../../../Models/Types/ExtinguishingMedia';
import generateIdTitleSelectItemList from '../../../../Shared/Functions/generateIdTitleSelectItemList';
import {getAllPackagingGroups} from '../../../../Shared/Data/PackagingGroupService';
import {PackagingGroup} from '../../../../Models/Types/PackagingGroup';
import {getAllPhases} from '../../../../Shared/Data/PhaseService';
import {checkUserRole} from '../../../../Shared/Data/UserService';
import {ProductTypes, StatusTypes} from '../../enums';
import {getAllSymbols} from '../../../../Shared/Data/SymbolService';
import {SymbolType} from '../../../../Models/Types/SymbolType';
import {ProductComponentType} from '../../../../Models/Types/ProductComponentType';
import {generateFlatIdList} from '../../../../Shared/Functions/generateFlatList';
import handleError from '../../../../Shared/Functions/handleError';
import useBem from '../../../../Hooks/useBem';

import './GeneralInformationForm.scss';

type PropType = {
  substanceData: Substance;
};
const GeneralInformation = (props: PropType) => {
  const {id: productId, rev_id} = useParams();
  const [bemClassName] = useBem('general-information-form');
  const {substanceData} = props;
  const {
    product_type,
    name,
    status,
    article_number,
    mpz_number,
    document,
    synonyms,
    purpose,
    company,
    vib_version,
    supplier_vib_date,
    vib_revision_date,
    cas_number,
    eec_number,
    eg_number,
    iupac_name,
    molecule_formula,
    hazard_statements,
    precautionary_statements,
    first_aid_inhalation,
    first_aid_ingestion,
    first_aid_skin_contact,
    first_aid_eye_contact,
    special_workplace_provisions,
    suitable_extinguishing_media,
    unsuitable_extinguishing_media,
    exposure_hazards_and_protection,
    personal_precautions,
    environmental_precautions,
    cleaning_methods,
    handling,
    storage,
    specific_forms_of_use,
    mac_value,
    personal_protective_equipment,
    respiratory_protection,
    hand_protection,
    eye_protection,
    skin_protection,
    control_occupational_exposure,
    other_control_measures,
    general_information,
    physical_state,
    color,
    odor,
    ph,
    boiling_point,
    flash_point,
    flammability,
    oxidizing_properties,
    explosion_properties,
    vapor_pressure,
    relative_vapor_density,
    solubility,
    partition_coefficient,
    viscosity,
    density,
    evaporation_rate,
    water_miscibility,
    conductivity,
    melting_point,
    gas_group,
    self_ignition_temperature,
    stability_and_reactivity,
    avoid_conditions,
    avoid_substances,
    hazardous_decomposition_products,
    other_information,
    lc50_inhalation_rat,
    ld50_dermal_rabbit,
    ld50_oral_rat,
    inhalation,
    skin,
    eyes,
    ingestion,
    other_toxicological_information,
    environmental_risks,
    water_hazard,
    other_harmful_effects,
    disposal_instructions,
    product_disposal,
    packaging_disposal,
    transport_information,
    un_number,
    proper_shipping_name,
    packaging_group,
    other_relevant_information,
    signal_words,
    info_1,
    info_2,
    modified_information,
    alternative_sources_msd,
    history,
    symbol_pbm,
    symbol_ghs,
    symbol_adr,
    hazard_class,
    firma,
    ingestion_protection,
    hands,
    first_aid_hand_contact,
    components: productComponents,
  } = substanceData;

  const navigate = useNavigate();
  const originalStatus = status;

  const [submitType, setSubmitType] = useState<StatusTypes | undefined>(status);
  // Suppliers
  const [supplierOptions, setSupplierOptions] = useState<SelectItem[] | undefined>(undefined);
  // Hazard classes
  const [hazardClassOptions, setHazardClassOptions] = useState<SelectItem[] | undefined>(undefined);
  const [selectedHazardClasses, setSelectedHazardClasses] = useState<SelectItem[] | undefined>(
    generateIdTitleSelectItemList(hazard_class),
  );
  // Extinguishing media
  const [extinguishingMediaOptions, setExtinguishingMediaOptions] = useState<SelectItem[] | undefined>(undefined);
  const [selectedSuitableExtinguishingMedia, setSelectedSuitableExtinguishingMedia] = useState<
    SelectItem[] | undefined
  >(generateIdTitleSelectItemList(suitable_extinguishing_media));
  const [selectedUnsuitableExtinguishingMedia, setSelectedUnsuitableExtinguishingMedia] = useState<
    SelectItem[] | undefined
  >(generateIdTitleSelectItemList(unsuitable_extinguishing_media));
  // Packaging groups
  const [packagingGroupOptions, setPackagingGroupOptions] = useState<SelectItem[] | undefined>(undefined);
  const [selectedPackagingGroup, setSelectedPackagingGroup] = useState<SelectItem | undefined>(
    packaging_group?.id !== undefined
      ? {
        value: packaging_group?.id!,
        label: packaging_group.title,
      }
      : undefined,
  );
  // Phases
  const [phaseOptions, setPhaseOptions] = useState<SelectItem[] | undefined>();
  const [selectedPhase, setSelectedPhase] = useState<SelectItem | undefined>(
    physical_state
      ? ({
        value: physical_state!.id,
        label: physical_state!.title,
      } as SelectItem)
      : undefined,
  );
  // Hazard statements
  const [selectedHazardStatements, setSelectedHazardStatements] = useState<string[] | undefined>(
    generateFlatIdList(hazard_statements as Statement[]),
  );
  // Symbols
  const [ghsSymbolOptions, setGhsSymbolOptions] = useState<SelectItem[] | undefined>(undefined);
  const [selectedGhsSymbols, setSelectedGhsSymbols] = useState<SelectItem[] | undefined>(
    generateIdTitleSelectItemList(symbol_ghs),
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [selectedPrecautionaryStatements, setSelectedPrecautionaryStatements] = useState<SelectItem[] | undefined>(
    generateStatementSelectFieldList(precautionary_statements as Statement[]),
  );
  const [pbmSymbolOptions, setPbmSymbolOptions] = useState<SelectItem[] | undefined>(undefined);
  const [selectedPbmSymbols, setSelectedPbmSymbols] = useState<SelectItem[] | undefined>(
    generateIdTitleSelectItemList(symbol_pbm),
  );
  const [adrSymbolOptions, setAdrSymbolOptions] = useState<SelectItem[] | undefined>(undefined);
  const [selectedAdrSymbols, setSelectedAdrSymbols] = useState<SelectItem[] | undefined>(
    generateIdTitleSelectItemList(symbol_adr),
  );

  const [mixtures, setMixtures] = useState<ProductComponentType[]>(productComponents);
  const [mixtureMessage, setMixtureMessage] = useState<string | undefined>();
  const [productType, setProductType] = useState<string | undefined>(product_type);
  const [listBuildInProgress, setListBuildInProgress] = useState<boolean | undefined>(undefined);

  const {data: hazardStatements, isLoading: hStatementsLoading} = useHazardStatement();
  // Precautionary statements
  const {data: precautionaryStatements, isLoading: pStatementsLoading} = usePrecautionary();

  // Building options for dropdowns
  const buildSupplierList = async (value?: string) => {
    const suppliers = await getAllSuppliers(returnSearchUrl('company', value));
    if (company?.name) {
      suppliers.items.push(company);
    }
    const optionsList = suppliers?.items.map(({id, name: supplierName}: Company) => ({
      label: supplierName,
      value: id,
    }));


    setSupplierOptions(optionsList);
  };

  const generateSymbolList = async (symbolType: string) => {
    if (!listBuildInProgress) {
      setListBuildInProgress(true);
      const base = window.__RUNTIME_CONFIG__.BACKEND_BASEURL;
      const url = new URL(`${base}/symbol`);
      url.searchParams.append('page_limit', '20');
      url.searchParams.append('type', symbolType);

      const symbols = await getAllSymbols(url);
      const optionsList = symbols?.items.map(({id, title}: SymbolType) => ({
        label: title,
        value: id,
      }));

      switch (symbolType) {
        case 'ghs':
          setGhsSymbolOptions(optionsList);
          break;
        case 'pbm':
          setPbmSymbolOptions(optionsList);
          break;
        case 'adr':
          setAdrSymbolOptions(optionsList);
          break;
        default:
      }
    }
  };

  const buildHazardClassList = async (value?: string) => {
    if (!listBuildInProgress || value) {
      setListBuildInProgress(true);
      const hazardClasses = await getAllHazardClasses(returnSearchUrl('hazard-class', value));
      const optionsList = hazardClasses?.items.map(({id, title}: HazardClass) => ({
        label: title,
        value: id,
      }));
      setHazardClassOptions(optionsList);
    }
  };

  const buildExtinguishMediaList = async (value?: string) => {
    if (!listBuildInProgress || value) {
      setListBuildInProgress(true);
      const extinguishingMedia = await getAllExtinguishingMedia(returnSearchUrl('extinguishing-media', value));
      const optionsList = extinguishingMedia?.items.map(({id, title}: ExtinguishingMedia) => ({
        label: title,
        value: id,
      }));
      setExtinguishingMediaOptions(optionsList);
    }
  };

  const buildPackagingGroupList = async (value?: string) => {
    if (!listBuildInProgress || value) {
      setListBuildInProgress(true);
      const packagingGroupList = await getAllPackagingGroups(returnSearchUrl('packaging-group', value));
      const optionsList = packagingGroupList?.items.map(({id, title}: PackagingGroup) => ({
        label: title,
        value: id,
      }));
      setPackagingGroupOptions(optionsList);
    }
  };

  const buildPhaseList = async (value?: string) => {
    if (!listBuildInProgress || value) {
      setListBuildInProgress(true);
      const phasesList = await getAllPhases(returnSearchUrl('phase', value));
      const optionsList = phasesList?.items.map(({id, title}: PackagingGroup) => ({
        label: title,
        value: id,
      }));
      setPhaseOptions(optionsList);
    }
  };

  // Updating of options for dropdowns based on searches

  const updateHazardStatementList = async (hazardClasses: SelectItem[] | undefined) => {
    const updatedHazardstatements = selectedHazardStatements || [];

    await hazardClasses?.forEach(({value: hId}) => {
      getHazardClass(hId).then((response) => {
        if (
          !selectedHazardStatements?.find(
            (selectedHazardStatement) => response.hazard_statements[0].id === selectedHazardStatement,
          )
        ) {
          updatedHazardstatements.push(`${response.hazard_statements[0].id}`);
        }
        setSelectedHazardStatements([...updatedHazardstatements]);
      });
    });

    setSelectedHazardClasses(hazardClasses);
  };

  // Adding and removing of substances to mixture table

  function addMixtureItem(mixtureItem: ProductComponentType) {

    if (mixtures.some((m) => m.component === mixtureItem.component)) {
      setMixtureMessage('Deze stof is al toegevoegd.');
      return;
    }

    setMixtureMessage(undefined);
    setMixtures([...mixtures, mixtureItem]);
  }

  const submit = useCallback(
    async (e: SyntheticEvent) => {
      const ghsValues = selectedGhsSymbols?.map((s) => s.value);
      const pbmValues = selectedPbmSymbols?.map((s) => s.value);
      const adrValues = selectedAdrSymbols?.map((s) => s.value);
      const precautionaryStatementsValues = selectedPrecautionaryStatements?.map((s) => s.value);
      const suitableExtinguishingMediaValues = selectedSuitableExtinguishingMedia?.map((s) => s.value);
      const unsuitableExtinguishingMediaValues = selectedUnsuitableExtinguishingMedia?.map((s) => s.value);
      const hazardClassValues = selectedHazardClasses?.map((s) => s.value);
      const mixtureValues = mixtures?.map((m) => ({
        component: m.component.id,
        percentage: m.percentage,
      }));

      const revision = rev_id !== undefined ? parseInt(rev_id!, 10) : undefined;

      await patchSubstance(
        e,
        productId!,
        ghsValues,
        pbmValues,
        adrValues,
        suitableExtinguishingMediaValues,
        unsuitableExtinguishingMediaValues,
        precautionaryStatementsValues,
        hazardClassValues,
        mixtureValues,
        revision,
        originalStatus === 'published'
      )
        .catch((err) => {
          setErrorMessage(handleError(err));
        })
        .then((result: any) => {
          if (result) {
            navigate(`/productenregister/detail/${result.data?.id}/r/${result.data?.revision_id}`);
          }
        });
    },
    [
      selectedGhsSymbols,
      selectedPbmSymbols,
      selectedAdrSymbols,
      selectedSuitableExtinguishingMedia,
      selectedUnsuitableExtinguishingMedia,
      selectedPrecautionaryStatements,
      selectedHazardClasses,
      mixtures,
      status,
    ],
  );

  const submitHandler = async (e: any) => {
    e.preventDefault();
    await submit(e);
  };

  // Loading dropdown options on initial load

  if (supplierOptions === undefined) buildSupplierList();
  if (hazardClassOptions === undefined) buildHazardClassList();
  if (extinguishingMediaOptions === undefined) buildExtinguishMediaList();
  if (packagingGroupOptions === undefined) buildPackagingGroupList();
  if (phaseOptions === undefined) buildPhaseList();

  if (ghsSymbolOptions === undefined) generateSymbolList('ghs');
  if (pbmSymbolOptions === undefined) generateSymbolList('pbm');
  if (adrSymbolOptions === undefined) generateSymbolList('adr');

  // Show Loading icon when loading data

  if (
    hStatementsLoading ||
    pStatementsLoading ||
    supplierOptions === undefined ||
    hazardClassOptions === undefined ||
    extinguishingMediaOptions === undefined ||
    packagingGroupOptions === undefined ||
    phaseOptions === undefined ||
    ghsSymbolOptions === undefined ||
    pbmSymbolOptions === undefined ||
    adrSymbolOptions === undefined
  ) {
    return <Loading/>;
  }

  // Check if product is a substance or a mixture

  const renderSubstancesTable = (selectedProductType?: string) => {
    if (selectedProductType === ProductTypes.Preparation) {
      return (
        <SubstancesTable
          values={mixtures}
          onAdd={(mixtureItem) => {
            addMixtureItem(mixtureItem);
          }}
          onDelete={(mixtureItem) => {
            setMixtures(mixtures.filter((m) => m.component.id !== mixtureItem));
          }}
          message={mixtureMessage}
        />
      );
    }

    return <p>Selecteer prodcuttype Mengsel voor het toevoegen van extra stoffen</p>;
  };

  return (
    <form
      method="post"
      onSubmit={(event) => submitHandler(event)}
    >
      <ul className="information-list">
        <CollapsableFormGroup title="1. Identificatie van de stof">
          <InputField
            name="name"
            label="Productnaam"
            value={name}
          />
          <InputField
            name="purpose"
            label="Gebruikersdoeleinde"
            value={purpose}
          />
          <SelectField
            name="company"
            label="Leverancier"
            placeholder="Begin met typen..."
            options={supplierOptions}
            value={supplierOptions.find((c: SelectItem) => c.value === company?.id)}
            onInputChange={(value) => buildSupplierList(value)}
          />
          <TextAreaInputField
            name="firma"
            label="NAW gegevens leverancier"
            value={firma}
          />
          <InputField
            name="synonyms"
            label="Synoniemen"
            value={synonyms}
          />
          <SelectField
            name="product_type"
            label="Type product"
            value={{value: product_type, label: product_type === ProductTypes.Product ? 'Stof' : 'Mengsel'}}
            options={[
              {value: ProductTypes.Product, label: 'Stof'},
              {value: ProductTypes.Preparation, label: 'Mengsel'},
            ]}
            onChangedValue={(selectedValue) => setProductType((selectedValue as SingleValue<SelectItem>)?.value)}
          />
          <InputField
            name="mpz_number"
            label="MPZ nummer"
            value={mpz_number}
            disabled
          />
          <InputField
            name="article_number"
            label="Artikelnummer"
            value={article_number}

          />
          <InputField
            name="vib_version"
            label="Versie"
            value={vib_version}
          />
          <InputField
            name="supplier_vib_date"
            label="VIB datum leverancier"
            value={supplier_vib_date}
            type="date"
          />
          <InputField
            name="vib_revision_date"
            label="VIB herzieningsdatum"
            value={vib_revision_date}
            type="date"
          />
          <UploadField
            name="document"
            label="Extern document"
            type={FileTypes.Pdf}
            document={document}
            url="product/document"
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="2. Gevaren">
          <SelectField
            name="hazard_class"
            label="Gevarenklasse"
            placeholder="Begin met typen..."
            allowMultiSelect
            options={hazardClassOptions}
            onInputChange={(value) => buildHazardClassList(value)}
            value={selectedHazardClasses}
            onChangedValue={(selectedItems) => updateHazardStatementList(selectedItems as SelectItem[] | undefined)}
          />
          <CheckboxList
            name="hazard_statements"
            label="H-zinnen"
            selectedOptions={[...selectedHazardStatements!]}
            options={generateStatementResponseSelectFieldList(hazardStatements as StatementResponse)}
            onChangedSelection={(selectedOptions) => setSelectedHazardStatements(selectedOptions)}
          />
          <SelectField
            name="symbol_ghs"
            label="GHS symbolen"
            placeholder="Selecteer symbolen..."
            allowMultiSelect
            options={ghsSymbolOptions}
            value={selectedGhsSymbols}
            onChangedValue={(selectedOptions) => {
              setSelectedGhsSymbols(selectedOptions as SelectItem[] | undefined);
            }}
          />
          <InputField
            name="signal_words"
            label="Signaalwoord"
            value={signal_words}
          />
          <SelectField
            name="precautionary_statements"
            label="P-zinnen"
            allowMultiSelect
            options={generateStatementResponseSelectFieldList(precautionaryStatements as StatementResponse)}
            value={selectedPrecautionaryStatements}
            wide
            onChangedValue={(selectedOptions) => {
              setSelectedPrecautionaryStatements(selectedOptions as SelectItem[] | undefined);
            }}
          />
          {productType === ProductTypes.Product ? (
            <>
              <InputField
                name="cas_number"
                label="CAS-nummer"
                value={cas_number}
              />
              <InputField
                name="eec_number"
                label="EEC-catalogusnummer"
                value={eec_number}
              />
              <InputField
                name="eg_number"
                label="EINECS / ELINCS nummer"
                value={eg_number}
              />
              <InputField
                name="iupac_name"
                label="IUPAC-naam (International Union of Pure and Applied Chemistry)"
                value={iupac_name}
              />
              <InputField
                name="molecule_formula"
                label="Molecuul formule"
                value={molecule_formula}
              />
            </>
          ) : (
            <div/>
          )}
        </CollapsableFormGroup>
        <CollapsableFormGroup title="3. Samenstelling">
          {renderSubstancesTable(productType)}
          {productType === ProductTypes.Product ? (
            <InputField
              name="mac_value"
              label="Mac waarde"
              value={mac_value}
            />
          ) : (
            <div/>
          )}
        </CollapsableFormGroup>
        <CollapsableFormGroup title="4. Eerste hulp maatregelen">
          <TextAreaInputField
            name="first_aid_inhalation"
            label="Inademing"
            value={first_aid_inhalation}
          />
          <TextAreaInputField
            name="first_aid_ingestion"
            label="Inslikken"
            value={first_aid_ingestion}
          />
          <TextAreaInputField
            name="first_aid_skin_contact"
            label="Huidcontact"
            value={first_aid_skin_contact}
          />
          <TextAreaInputField
            name="first_aid_hand_contact"
            label="Contact met de handen"
            value={first_aid_hand_contact}
          />
          <TextAreaInputField
            name="first_aid_eye_contact"
            label="Oogcontact"
            value={first_aid_eye_contact}
          />
          <TextAreaInputField
            name="special_workplace_provisions"
            label="Noodzakelijke speciale voorzieningen op werkplek"
            value={special_workplace_provisions}
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="5. Brandbestrijdingsmaatregelen">
          <SelectField
            name="suitable_extinguishing_media"
            label="Geschikte blusmiddelen"
            allowMultiSelect
            wide
            options={extinguishingMediaOptions}
            value={selectedSuitableExtinguishingMedia}
            onChangedValue={(value) => setSelectedSuitableExtinguishingMedia(value as SelectItem[])}
          />
          <SelectField
            name="unsuitable_extinguishing_media"
            label="Ongeschikte blusmiddelen"
            allowMultiSelect
            wide
            options={extinguishingMediaOptions}
            value={selectedUnsuitableExtinguishingMedia}
            onChangedValue={(value) => setSelectedUnsuitableExtinguishingMedia(value as SelectItem[])}
          />
          <TextAreaInputField
            name="exposure_hazards_and_protection"
            label="Blootstellingsgevaren en bescherming"
            value={exposure_hazards_and_protection}
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="6. Voorzorg">
          <TextAreaInputField
            name="personal_precautions"
            label="Persoonlijke voorzorgsmaatregelen"
            value={personal_precautions}
          />
          <TextAreaInputField
            name="environmental_precautions"
            label="Milieuvoorzorgsmaatregelen"
            value={environmental_precautions}
          />
          <TextAreaInputField
            name="cleaning_methods"
            label="Reinigingsmethoden"
            value={cleaning_methods}
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="7. Hantering en opslag">
          <TextAreaInputField
            name="handling"
            label="Hantering"
            value={handling}
          />
          <TextAreaInputField
            name="storage"
            label="Opslag"
            value={storage}
          />
          <TextAreaInputField
            name="specific_forms_of_use"
            label="Specifieke vorm(en) van gebruik"
            value={specific_forms_of_use}
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="8. Beheer blootstelling">
          <TextAreaInputField
            name="respiratory_protection"
            label="Bescherming ademhalingsorganen"
            value={respiratory_protection}
          />
          <TextAreaInputField
            name="hand_protection"
            label="Bescherming handen"
            value={hand_protection}
          />
          <TextAreaInputField
            name="eye_protection"
            label="Bescherming ogen"
            value={eye_protection}
          />
          <TextAreaInputField
            name="skin_protection"
            label="Bescherming huid"
            value={skin_protection}
          />
          <TextAreaInputField
            name="ingestion_protection"
            label="Bescherming tegen inslikken"
            value={ingestion_protection}
          />
          <TextAreaInputField
            name="control_occupational_exposure"
            label="Beheersing beroepsmatige blootstelling"
            value={control_occupational_exposure}
          />
          <TextAreaInputField
            name="other_control_measures"
            label="Overige beheersmaatregelen"
            value={other_control_measures}
          />
          <InputField
            name="personal_protective_equipment"
            label="Persoonlijke beschermingsmiddelen"
            value={personal_protective_equipment}
          />
          <SelectField
            name="symbol_pbm"
            label="PBM symbolen"
            placeholder="Selecteer symbolen..."
            allowMultiSelect
            options={pbmSymbolOptions}
            value={selectedPbmSymbols}
            onChangedValue={(selectedOptions) => setSelectedPbmSymbols(selectedOptions as SelectItem[] | undefined)}
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="9. Fysische en chemische eigenschappen">
          <TextAreaInputField
            name="general_information"
            label="Algemene informatie"
            value={general_information}
          />
          <SelectField
            name="physical_state"
            label="Verschijningsvorm"
            wide
            options={phaseOptions}
            value={selectedPhase}
            onChangedValue={(value) => setSelectedPhase(value as SelectItem)}
          />
          <InputField
            name="color"
            label="Kleur"
            value={color}
          />
          <InputField
            name="odor"
            label="Geur"
            value={odor}
          />
          <InputField
            name="ph"
            label="pH waarde"
            value={ph}

          />
          <InputField
            name="boiling_point"
            label="Kookpunt/kooktraject"

            value={boiling_point}
          />
          <InputField
            name="flash_point"
            label="Vlampunt"

            value={flash_point}
          />
          <InputField
            name="flammability"
            label="Ontvlambaarheid"
            value={flammability}
          />
          <TextAreaInputField
            name="oxidizing_properties"
            label="Oxiderende eigenschappen"
            value={oxidizing_properties}
          />
          <InputField
            name="explosion_properties"
            label="Ontploffingseigenschappen (ondergrens / bovengrens)"
            value={explosion_properties}
          />
          <InputField
            name="vapor_pressure"
            label="Dampspanning"
            value={vapor_pressure}
          />
          <InputField
            name="relative_vapor_density"
            label="Relatieve dampdichtheid"

            value={relative_vapor_density}
          />
          <InputField
            name="solubility"
            label="Oplosbaarheid in water of ander oplosmiddel (specificeer oplosmiddel)"
            value={solubility}
          />
          <InputField
            name="partition_coefficient"
            label="Verdelingscoëfficiënt: n-octanol/water"
            value={partition_coefficient}
          />
          <InputField
            name="viscosity"
            label="Viscositeit"
            value={viscosity}
          />
          <InputField
            name="density"
            label="Dichtheid"

            value={density}
          />
          <InputField
            name="evaporation_rate"
            label="Verdampingssnelheid"
            value={evaporation_rate}

          />
          <InputField
            name="water_miscibility"
            label="Mengbaarheid met water"
            value={water_miscibility}
          />
          <InputField
            name="conductivity"
            label="Geleidingsvermogen"
            value={conductivity}
          />
          <InputField
            name="melting_point"
            label="Smeltpunt/smelttrajekt"
            value={melting_point}
          />
          <InputField
            name="gas_group"
            label="Gasgroep"
            value={gas_group}
          />
          <InputField
            name="self_ignition_temperature"
            label="Zelfontbrandingstemperatuur"

            value={self_ignition_temperature}
          />
          <TextAreaInputField
            name="other_information"
            label="Overige informatie"
            value={other_information}
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="10. Reactiviteit">
          <TextAreaInputField
            name="stability_and_reactivity"
            label="Chemische stabiliteit"
            value={stability_and_reactivity}
          />
          <TextAreaInputField
            name="avoid_conditions"
            label="Te vermijden omstandigheden"
            value={avoid_conditions}
          />
          <TextAreaInputField
            name="avoid_substances"
            label="Te vermijden stoffen"
            value={avoid_substances}
          />
          <TextAreaInputField
            name="hazardous_decomposition_products"
            label="Gevaarlijke ontledingsproducten"
            value={hazardous_decomposition_products}
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="11. Toxicologische informatie">
          <TextAreaInputField
            name="lc50_inhalation_rat"
            label="LC 50 (Inhalatie, rat)"
            value={lc50_inhalation_rat}
          />
          <TextAreaInputField
            name="ld50_dermal_rabbit"
            label="LD 50 (dermaal, konijn)"
            value={ld50_dermal_rabbit}
          />
          <TextAreaInputField
            name="ld50_oral_rat"
            label="LD 50 (oraal, rat)"
            value={ld50_oral_rat}
          />
          <TextAreaInputField
            name="inhalation"
            label="Inademen"
            value={inhalation}
          />
          <TextAreaInputField
            name="skin"
            label="Huid"
            value={skin}
          />
          <TextAreaInputField
            name="hands"
            label="Handen"
            value={hands}
          />
          <TextAreaInputField
            name="eyes"
            label="Ogen"
            value={eyes}
          />
          <TextAreaInputField
            name="ingestion"
            label="Inslikken"
            value={ingestion}
          />
          <TextAreaInputField
            name="other_toxicological_information"
            label="Overige toxicologische informatie"
            value={other_toxicological_information}
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="12. Milieu">
          <TextAreaInputField
            name="environmental_risks"
            label="Milieurisico"
            value={environmental_risks}
          />
          <TextAreaInputField
            name="water_hazard"
            label="Waterbezwaarlijkheid"
            value={water_hazard}
          />
          <TextAreaInputField
            name="other_harmful_effects"
            label="Overige toxicologische informatie"
            value={other_harmful_effects}
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="13. Verpakking">
          <TextAreaInputField
            name="product_disposal"
            label="Product"
            value={product_disposal}
          />
          <TextAreaInputField
            name="packaging_disposal"
            label="Afval verpakking"
            value={packaging_disposal}
          />
          <InputField
            name="disposal_instructions"
            label="Afvalinstructies"
            value={disposal_instructions}
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="14. Transport">
          <InputField
            name="un_number"
            label="UN-nummer"
            value={un_number}
          />
          <InputField
            name="proper_shipping_name"
            label="Ladingsnaam"
            value={proper_shipping_name}
          />
          <SelectField
            name="symbol_adr"
            label="ADR gevarenklassen"
            placeholder="Selecteer symbolen..."
            allowMultiSelect
            options={adrSymbolOptions}
            value={selectedAdrSymbols}
            onChangedValue={(selectedOptions) => setSelectedAdrSymbols(selectedOptions as SelectItem[] | undefined)}
          />
          <SelectField
            name="packaging_group"
            label="Verpakking"
            wide
            options={packagingGroupOptions}
            value={selectedPackagingGroup}
            onChangedValue={(value) => setSelectedPackagingGroup(value as SelectItem)}
          />
          <InputField
            name="transport_information"
            label="Andere relevante transport informatie"
            value={transport_information}
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="15. Regelgeving">
          <TextAreaInputField
            name="info_1"
            label="Informatie 1"
            value={info_1}
          />
          <TextAreaInputField
            name="info_2"
            label="Informatie 2"
            value={info_2}
          />
          <InputField
            name="other_relevant_information"
            label="Andere relevante informatie"
            value={other_relevant_information}
          />
          <InputField
            name="modified_information"
            label="Aangepaste informatie"
            value={modified_information}
          />
          <InputField
            name="history"
            label="Geschiedenis"
            value={history}
          />
          <InputField
            name="alternative_sources_msd"
            label="Alternatieve bronnen MSD"
            value={alternative_sources_msd}
          />
        </CollapsableFormGroup>
      </ul>
      <input
        value={submitType}
        type="hidden"
        name="status"
      />
      <div className="form-buttons">
        {errorMessage && <p className={bemClassName('error-message')}>{errorMessage}</p>}
        <Button
          onHandleClick={() => setSubmitType(StatusTypes.SaveAsDraft)}
          submit
          theme={ButtonTypes.Solid}
          title="Opslaan"
        />
        <Button
          onHandleClick={() => setSubmitType(StatusTypes.SubmitForApproval)}
          submit
          theme={ButtonTypes.Solid}
          title="Indienen voor goedkeuring"
        />

        {checkUserRole('administrator') && (
          <Button
            onHandleClick={() => setSubmitType(StatusTypes.SaveAndPublish)}
            submit
            theme={ButtonTypes.Solid}
            title="Direct publiceren"
          />
        )}
      </div>
    </form>
  );
};

export default GeneralInformation;
