import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getCloset, getAllClosets } from '../Data/ClosetService';

import { Closet, ClosetResponse } from '../../Models/Types/Closet';

const useCloset = (id?: string, hospitalId?: string): UseQueryResult<Closet | ClosetResponse, Error> => {
  if (id) {
    return useQuery({
      queryKey: [`closet-${id}`, 'closets'],
      queryFn: () => getCloset(id),
    });
  }

  return useQuery({
    queryKey: [`closet-${hospitalId}`, 'closets'],
    queryFn: () => getAllClosets(),
  });
};

export default useCloset;
