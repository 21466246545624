import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getUser, getAllUsers } from '../Data/UserService';
import { User, UserResponse } from '../../Models/Types/User';

const useUser = (id?: string): UseQueryResult<UserResponse | User, Error> =>
  useQuery({
    queryKey: [id ? `user-${id}` : `users`], 
    queryFn:() => (id ? getUser(id) : getAllUsers()), 
  });
export default useUser;
