import React from 'react';
import useBem from '../../../Hooks/useBem';
import { FillUpSize } from './enums';

import './InputField.scss';

type PropType = {
  name: string;
  label: string;
  placeholder?: string;
  type?: string;
  value?: string | number;
  min?: number;
  max?: number;
  size?: FillUpSize;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  preventDefault?: boolean;
};
const InputField = ({ disabled, label, max, min, name, onChange, placeholder, size, type, value, preventDefault }: PropType) => {
  const [bemClassName, bemClassNames] = useBem('form-field');

  return (
    <div
      className={bemClassNames([
        bemClassName(),
        bemClassName('small', null, size === FillUpSize.Small),
        bemClassName('x-small', null, size === FillUpSize.ExtraSmall),
      ])}
    >
      {label !== '' && (
        <label
          className={bemClassName('label')}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <input
        onKeyDown={(e) => {
          if (preventDefault && e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        id={name}
        name={name}
        type={type}
        min={min}
        max={max}
        defaultValue={value}
        placeholder={placeholder}
        onChange={(event) => onChange!(event)}
        className={bemClassName('input')}
        disabled={disabled}
      />
    </div>
  );
};

InputField.defaultProps = {
  type: 'text',
  value: undefined,
  min: undefined,
  max: undefined,
  placeholder: undefined,
  size: FillUpSize.Default,
  disabled: false,
  onChange: () => {},
  preventDefault: true,
};

export default InputField;
