import React, { useState } from 'react';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import HeaderSeperationBar from '../../../Shared/Components/HeaderSeperationBar/HeaderSeperationBar';
import ManualListContent from './Components/ManualList/ManualListContent';
import Modal from '../../../Shared/Components/Modal/Modal';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';

const ManualManagement = () => {
  if (!checkUserRight('entity.manual.GET')) {
    return <NoPermission />;
  }

  const [showModal, setShowDModal] = useState(false);
  const [selectedSupplierId, setSelectedSupplierId] = useState<string | undefined>(undefined);

  function openModal(id: string) {
    setShowDModal(true);
    setSelectedSupplierId(id);
  }

  function removeSupplier(id: string | undefined) {
    // TODO: Implement remove manual functionality
    setShowDModal(false);

    // eslint-disable-next-line no-console
    console.log(`Remove manual with id: ${id}`);
  }

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            title="Handleidingen beheren"
            onHandleEnterKeyDown={() => {}}
          />
          <HeaderSeperationBar />
          <ManualListContent handleOpenModal={(id) => openModal(id)} />
        </section>
      </div>
      <Modal
        showModal={showModal}
        modalTitle="Handleiding verwijderen"
        modalDescription="Wilt u deze handleiding definitief verwijderen?"
        modalButtonLeftText="Annuleren"
        modalButtonRightText="Verwijderen"
        rightButtonEnabled
        onLeftAction={() => setShowDModal(false)}
        onRightAction={() => removeSupplier(selectedSupplierId)}
        onHandleClose={() => setShowDModal(false)}
      />
    </>
  );
};

export default ManualManagement;
