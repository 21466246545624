import React, { useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner';
import { LoginWithUsername } from '../../Data/LoginService';
import { getUser } from '../../Data/UserService';
import Loading from '../Loading/Loading';
import Button from '../Button/Button';
import { ButtonSizes, ButtonTypes } from '../Button/enums';
import useBem from '../../../Hooks/useBem';

import './LoginWithUrl.scss';

const LoginWithUrl = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState<string | undefined | null>(null);
  const [bemClassName] = useBem('login');

  const handleLogin = async () => {
    const username = search?.replace('?userlogin=', '');

    const loginData = await LoginWithUsername({
      name: username as string,
    }).catch((err) => {
      const { data } = err;
      if (data !== undefined) {
        const { message } = data;

        if (message === 'Sorry, unrecognized username.') {
          setError('Deze gebruiker is niet bekend in ons systeem.');
        } else {
          setError(message);
        }
      } else {
        setError('Er ging iets fout');
      }
    });

    if (loginData !== undefined) {
      const { token } = loginData;
      localStorage.setItem('userToken', token);
      const { access, roles } = jwtDecode(token) as { access: string[]; roles: string[] };

      getUser().then((userData) => {
        // eslint-disable-next-line no-param-reassign
        userData.rights = access;
        // eslint-disable-next-line no-param-reassign
        userData.roles = roles;

        localStorage.setItem('user', JSON.stringify(userData));
        navigate('/productenregister');
      });
    }
  };

  handleLogin();

  if (error) {
    return (
      <section className={bemClassName()}>
        <div className={bemClassName('login-screen')}>
          <div className={bemClassName('logo')}>
            <img
              src="/Images/mpzLogo.svg"
              alt="Arrow left"
            />
          </div>
          <div>
            <b>Inloggen met gebruikersnaam</b>
            <span className={bemClassName('error')}>{error}</span>
          </div>
          {error === undefined ? (
            <div className={bemClassName('loading')}>
              <InfinitySpin
                width="100"
                color="#5776B0"
              />
            </div>
          ) : (
            <Button
              title="Probeer opnieuw"
              theme={ButtonTypes.SolidBlue}
              width={ButtonSizes.Full}
              onHandleClick={() => window.location.reload()}
            />
          )}
        </div>
      </section>
    );
  }

  return <Loading />;
};

export default LoginWithUrl;
