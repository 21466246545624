import React, { SyntheticEvent, useCallback, useState } from 'react';
import { SingleValue } from 'react-select';
import { useNavigate } from 'react-router-dom';
import CollapsableFormGroup from '../../../../Shared/Components/CollapsableContent/CollapsableComponentGroup';
import InputField from '../../../../Shared/Components/Form/InputField';
import SelectField from '../../../../Shared/Components/Form/SelectField';
import TextAreaInputField from '../../../../Shared/Components/Form/TextAreaInputField';
import CheckboxList from '../../../../Shared/Components/Form/CheckboxList';
import Button from '../../../../Shared/Components/Button/Button';
import { ButtonTypes } from '../../../../Shared/Components/Button/enums';
import UploadField from '../../../../Shared/Components/Form/UploadField';
import { SelectItem } from '../../../../Models/Types/SelectItem';
import { Company } from '../../../../Models/Types/Company';
import { FileTypes } from '../../../../Shared/Components/Form/enums';
import { generateStatementResponseSelectFieldList } from '../../../../Shared/Functions/generateStatements';
import { StatementResponse } from '../../../../Models/Types/Statement';
import useHazardStatement from '../../../../Shared/Hooks/useHazardStatement';
import Loading from '../../../../Shared/Components/Loading/Loading';
import { getAllSuppliers } from '../../../../Shared/Data/SupplierService';
import returnSearchUrl from '../../../../Shared/Functions/returnSearchUrl';
import { getAllHazardClasses, getHazardClass } from '../../../../Shared/Data/HazardClassService';
import { HazardClass } from '../../../../Models/Types/HazardClass';
import usePrecautionary from '../../../../Shared/Hooks/usePrecautionary';
import { getAllExtinguishingMedia } from '../../../../Shared/Data/ExtinguishingMediaService';
import { ExtinguishingMedia } from '../../../../Models/Types/ExtinguishingMedia';
import { getAllPackagingGroups } from '../../../../Shared/Data/PackagingGroupService';
import { PackagingGroup } from '../../../../Models/Types/PackagingGroup';
import { getAllPhases } from '../../../../Shared/Data/PhaseService';
import { checkUserRole } from '../../../../Shared/Data/UserService';
import { getAllSymbols } from '../../../../Shared/Data/SymbolService';
import { SymbolType } from '../../../../Models/Types/SymbolType';
import { ProductComponentType } from '../../../../Models/Types/ProductComponentType';
import SubstancesTable from '../../../SubstancesRegister/Components/EditForm/FormComponents/SubstancesTable';
import { ProductTypes, StatusTypes } from '../../../SubstancesRegister/enums';
import { postSubstance } from '../../../../Shared/Data/SubstanceService';
import handleError from '../../../../Shared/Functions/handleError';
import useBem from '../../../../Hooks/useBem';

const AddProductForm = () => {
  const navigate = useNavigate();
  const [bemClassName] = useBem('general-information-form');

  const [submitType, setSubmitType] = useState<StatusTypes | undefined>(undefined);
  // Suppliers
  const [supplierOptions, setSupplierOptions] = useState<SelectItem[] | undefined>(undefined);
  // Hazard classes
  const [hazardClassOptions, setHazardClassOptions] = useState<SelectItem[] | undefined>(undefined);
  const [selectedHazardClasses, setSelectedHazardClasses] = useState<SelectItem[] | undefined>(undefined);
  // Extinguishing media
  const [extinguishingMediaOptions, setExtinguishingMediaOptions] = useState<SelectItem[] | undefined>(undefined);
  const [selectedSuitableExtinguishingMedia, setSelectedSuitableExtinguishingMedia] = useState<
    SelectItem[] | undefined
  >(undefined);
  const [selectedUnsuitableExtinguishingMedia, setSelectedUnsuitableExtinguishingMedia] = useState<
    SelectItem[] | undefined
  >(undefined);
  // Packaging groups
  const [packagingGroupOptions, setPackagingGroupOptions] = useState<SelectItem[] | undefined>(undefined);
  const [selectedPackagingGroup, setSelectedPackagingGroup] = useState<SelectItem | undefined>(undefined);
  // Phases
  const [phaseOptions, setPhaseOptions] = useState<SelectItem[] | undefined>();
  const [selectedPhase, setSelectedPhase] = useState<SelectItem | undefined>(undefined);
  // Hazard statements
  const [selectedHazardStatements, setSelectedHazardStatements] = useState<string[] | undefined>([]);
  // Symbols
  const [ghsSymbolOptions, setGhsSymbolOptions] = useState<SelectItem[] | undefined>(undefined);
  const [selectedGhsSymbols, setSelectedGhsSymbols] = useState<SelectItem[] | undefined>(undefined);
  const [selectedPrecautionaryStatements, setSelectedPrecautionaryStatements] = useState<SelectItem[] | undefined>(
    undefined
  );
  const [pbmSymbolOptions, setPbmSymbolOptions] = useState<SelectItem[] | undefined>(undefined);
  const [selectedPbmSymbols, setSelectedPbmSymbols] = useState<SelectItem[] | undefined>(undefined);
  const [adrSymbolOptions, setAdrSymbolOptions] = useState<SelectItem[] | undefined>(undefined);
  const [selectedAdrSymbols, setSelectedAdrSymbols] = useState<SelectItem[] | undefined>(undefined);
  const [mixtures, setMixtures] = useState<ProductComponentType[]>([]);
  const [mixtureMessage, setMixtureMessage] = useState<string | undefined>();
  const [productType, setProductType] = useState<string | undefined>(undefined);
  const [listBuildInProgress, setListBuildInProgress] = useState<boolean | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { data: hazardStatements, isLoading: hStatementsLoading } = useHazardStatement();
  // Precautionary statements
  const { data: precautionaryStatements, isLoading: pStatementsLoading } = usePrecautionary();

  // Building options for dropdowns
  const buildSupplierList = async (value?: string) => {
    if (!listBuildInProgress) {
      setListBuildInProgress(true);
      const suppliers = await getAllSuppliers(returnSearchUrl('company', value));
      const optionsList = suppliers?.items.map(({ id, name: supplierName }: Company) => ({
        label: supplierName,
        value: id,
      }));
      setSupplierOptions(optionsList);
    }
  };

  const generateSymbolList = async (symbolType: string) => {
    if (!listBuildInProgress) {
      setListBuildInProgress(true);
      const base = window.__RUNTIME_CONFIG__.BACKEND_BASEURL;
      const url = new URL(`${base}/symbol`);
      url.searchParams.append('page_limit', '100');
      url.searchParams.append('type', symbolType);

      const symbols = await getAllSymbols(url);
      const optionsList = symbols?.items.map(({ id, title }: SymbolType) => ({
        label: title,
        value: id,
      }));

      switch (symbolType) {
        case 'ghs':
          setGhsSymbolOptions(optionsList);
          break;
        case 'pbm':
          setPbmSymbolOptions(optionsList);
          break;
        case 'adr':
          setAdrSymbolOptions(optionsList);
          break;
        default:
      }
    }
  };

  const buildHazardClassList = async (value?: string) => {
    if (!listBuildInProgress || value) {
      setListBuildInProgress(true);
      const hazardClasses = await getAllHazardClasses(returnSearchUrl('hazard-class', value));
      const optionsList = hazardClasses?.items.map(({ id, title }: HazardClass) => ({
        label: title,
        value: id,
      }));
      setHazardClassOptions(optionsList);
    }
  };

  const buildExtinguishMediaList = async (value?: string) => {
    if (!listBuildInProgress || value) {
      setListBuildInProgress(true);
      const extinguishingMedia = await getAllExtinguishingMedia(returnSearchUrl('extinguishing-media', value));
      const optionsList = extinguishingMedia?.items.map(({ id, title }: ExtinguishingMedia) => ({
        label: title,
        value: id,
      }));
      setExtinguishingMediaOptions(optionsList);
    }
  };

  const buildPackagingGroupList = async (value?: string) => {
    if (!listBuildInProgress || value) {
      setListBuildInProgress(true);
      const packagingGroupList = await getAllPackagingGroups(returnSearchUrl('packaging-group', value));
      const optionsList = packagingGroupList?.items.map(({ id, title }: PackagingGroup) => ({
        label: title,
        value: id,
      }));
      setPackagingGroupOptions(optionsList);
    }
  };

  const buildPhaseList = async (value?: string) => {
    if (!listBuildInProgress || value) {
      setListBuildInProgress(true);
      const phasesList = await getAllPhases(returnSearchUrl('phase', value));
      const optionsList = phasesList?.items.map(({ id, title }: PackagingGroup) => ({
        label: title,
        value: id,
      }));
      setPhaseOptions(optionsList);
    }
  };

  // Updating of options for dropdowns based on searches

  const updateHazardStatementList = async (hazardClasses: SelectItem[] | undefined) => {
    const updatedHazardstatements = selectedHazardStatements ?? [];

    await hazardClasses?.forEach(({ value: hId }) => {
      getHazardClass(hId).then((response) => {
        if (
          !selectedHazardStatements?.find(
            (selectedHazardStatement) => response.hazard_statements[0].id === selectedHazardStatement
          )
        ) {
          updatedHazardstatements.push(`${response.hazard_statements[0].id}`);
        }
        setSelectedHazardStatements([...updatedHazardstatements]);
      });
    });

    setSelectedHazardClasses(hazardClasses);
  };

  // Adding and removing of substances to mixture table

  function addMixtureItem(mixtureItem: ProductComponentType) {
    if (mixtures?.some((m) => m.component === mixtureItem.component)) {
      setMixtureMessage('Deze stof is al toegevoegd.');
      return;
    }

    setMixtureMessage(undefined);
    if (mixtures) setMixtures([...mixtures, mixtureItem]);
  }

  const submit = useCallback(
    async (e: SyntheticEvent) => {
      const ghsValues = selectedGhsSymbols?.map((s) => s.value);
      const pbmValues = selectedPbmSymbols?.map((s) => s.value);
      const adrValues = selectedAdrSymbols?.map((s) => s.value);
      const precautionaryStatementsValues = selectedPrecautionaryStatements?.map((s) => s.value);
      const suitableExtinguishingMediaValues = selectedSuitableExtinguishingMedia?.map((s) => s.value);
      const unsuitableExtinguishingMediaValues = selectedUnsuitableExtinguishingMedia?.map((s) => s.value);
      const hazardClassValues = selectedHazardClasses?.map((s) => s.value);
      const mixtureValues = mixtures?.map((m) => ({
        component: m.component.id,
        percentage: m.percentage,
      }));

      await postSubstance(
        e,
        ghsValues,
        pbmValues,
        adrValues,
        suitableExtinguishingMediaValues,
        unsuitableExtinguishingMediaValues,
        precautionaryStatementsValues,
        hazardClassValues,
        mixtureValues
      )
        .catch((err) => {
          setErrorMessage(handleError(err));
        })
        .then((result: any) => {
          if (result) {
            navigate(`/productenregister/detail/${result.data?.id}`);
          }
        });
    },
    [
      selectedGhsSymbols,
      selectedPbmSymbols,
      selectedAdrSymbols,
      selectedSuitableExtinguishingMedia,
      selectedUnsuitableExtinguishingMedia,
      selectedPrecautionaryStatements,
      selectedHazardClasses,
      mixtures,
    ]
  );

  const submitHandler = async (e: SyntheticEvent) => {
    e.preventDefault();
    await submit(e);
  };

  // Loading dropdown options on initial load

  if (supplierOptions === undefined) buildSupplierList();
  if (hazardClassOptions === undefined) buildHazardClassList();
  if (extinguishingMediaOptions === undefined) buildExtinguishMediaList();
  if (packagingGroupOptions === undefined) buildPackagingGroupList();
  if (phaseOptions === undefined) buildPhaseList();

  if (ghsSymbolOptions === undefined) generateSymbolList('ghs');
  if (pbmSymbolOptions === undefined) generateSymbolList('pbm');
  if (adrSymbolOptions === undefined) generateSymbolList('adr');

  // Show Loading icon when loading data

  if (
    hStatementsLoading ||
    pStatementsLoading ||
    supplierOptions === undefined ||
    hazardClassOptions === undefined ||
    extinguishingMediaOptions === undefined ||
    packagingGroupOptions === undefined ||
    phaseOptions === undefined ||
    ghsSymbolOptions === undefined ||
    pbmSymbolOptions === undefined ||
    adrSymbolOptions === undefined
  ) {
    return <Loading />;
  }

  // Check if product is a substance or a mixture

  const renderSubstancesTable = (selectedProductType?: string) => {
    if (selectedProductType === 'product') {
      return (
        <SubstancesTable
          values={mixtures}
          onAdd={(mixtureItem) => {
            addMixtureItem(mixtureItem);
          }}
          onDelete={(mixtureItem) => {
            setMixtures(mixtures.filter((m) => m.component.id !== mixtureItem));
          }}
          message={mixtureMessage}
        />
      );
    }

    return <p>Selecteer producttype Mengsel voor het toevoegen van extra stoffen</p>;
  };

  return (
    <form
      method="post"
      onSubmit={(event) => submitHandler(event)}
    >
      <ul className="information-list">
        <CollapsableFormGroup
          title="1. Identificatie van de stof"
          open
        >
          <InputField
            name="name"
            label="Productnaam"
          />
          <InputField
            name="purpose"
            label="Gebruikersdoeleinde"
          />
          <SelectField
            name="company"
            label="Leverancier"
            placeholder="Begin met typen..."
            options={supplierOptions}
            onInputChange={(value) => buildSupplierList(value)}
          />
          <TextAreaInputField
            name="firma"
            label="Gegevens leverancier"
          />
          <InputField
            name="synonyms"
            label="Synoniemen"
          />
          <SelectField
            name="type"
            label="Type product"
            options={[
              { value: 'preparation', label: 'Stof' },
              { value: 'product', label: 'Mengsel' },
            ]}
            onChangedValue={(selectedValue) => setProductType((selectedValue as SingleValue<SelectItem>)?.value)}
          />
          <InputField
            name="mpz_number"
            label="MPZ nummer"
            disabled
          />
          <InputField
            name="article_number"
            label="Artikelnummer"
          />
          <InputField
            name="vib_version"
            label="Versie"
          />
          <InputField
            name="supplier_vib_date"
            label="VIB datum leverancier"
            type="date"
          />
          <InputField
            name="vib_revision_date"
            label="VIB herzieningsdatum"
            type="date"
          />
          <UploadField
            name="document"
            label="Extern document"
            type={FileTypes.Pdf}
            url="product/document"
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="2. Gevaren">
          <SelectField
            name="hazard_class"
            label="Gevarenklasse"
            placeholder="Begin met typen..."
            allowMultiSelect
            options={hazardClassOptions}
            onInputChange={(value) => buildHazardClassList(value)}
            value={selectedHazardClasses}
            onChangedValue={(selectedItems) => updateHazardStatementList(selectedItems as SelectItem[] | undefined)}
          />
          <CheckboxList
            name="hazard_statements"
            label="H-zinnen"
            selectedOptions={[...selectedHazardStatements!]}
            options={generateStatementResponseSelectFieldList(hazardStatements as StatementResponse)}
            onChangedSelection={(selectedOptions) => setSelectedHazardStatements(selectedOptions)}
          />
          <SelectField
            name="symbol_ghs"
            label="GHS symbolen"
            placeholder="Selecteer symbolen..."
            allowMultiSelect
            options={ghsSymbolOptions}
            value={selectedGhsSymbols}
            onChangedValue={(selectedOptions) => setSelectedGhsSymbols(selectedOptions as SelectItem[] | undefined)}
          />
          <InputField
            name="signal_words"
            label="Signaalwoord"
          />
          <SelectField
            name="precautionary_statements"
            label="P-zinnen"
            allowMultiSelect
            options={generateStatementResponseSelectFieldList(precautionaryStatements as StatementResponse)}
            wide
            onChangedValue={(selectedOptions) => {
              setSelectedPrecautionaryStatements(selectedOptions as SelectItem[] | undefined);
            }}
          />
          {productType === ProductTypes.Product ? (
            <>
              <InputField
                name="cas_number"
                label="CAS-nummer"
              />
              <InputField
                name="eec_number"
                label="EEC-catalogusnummer"
              />
              <InputField
                name="eg_number"
                label="EINECS / ELINCS nummer"
              />
              <InputField
                name="iupac_name"
                label="IUPAC-naam (International Union of Pure and Applied Chemistry)"
              />
              <InputField
                name="molecule_formula"
                label="Molecuul formule"
              />
            </>
          ) : (
            <div />
          )}
        </CollapsableFormGroup>
        <CollapsableFormGroup title="3. Samenstelling">{renderSubstancesTable(productType)}</CollapsableFormGroup>
        <CollapsableFormGroup title="4. Eerste hulp maatregelen">
          <TextAreaInputField
            name="first_aid_inhalation"
            label="Inademing"
          />
          <TextAreaInputField
            name="first_aid_ingestion"
            label="Inslikken"
          />
          <TextAreaInputField
            name="first_aid_skin_contact"
            label="Huidcontact"
          />
          <TextAreaInputField
            name="first_aid_hand_contact"
            label="Contact met de handen"
          />
          <TextAreaInputField
            name="first_aid_eye_contact"
            label="Oogcontact"
          />
          <TextAreaInputField
            name="special_workplace_provisions"
            label="Noodzakelijke speciale voorzieningen op werkplek"
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="5. Brandbestrijdingsmaatregelen">
          <SelectField
            name="suitable_extinguishing_media"
            label="Geschikte blusmiddelen"
            allowMultiSelect
            wide
            options={extinguishingMediaOptions}
            value={selectedSuitableExtinguishingMedia}
            onChangedValue={(value) => setSelectedSuitableExtinguishingMedia(value as SelectItem[])}
          />
          <SelectField
            name="unsuitable_extinguishing_media"
            label="Ongeschikte blusmiddelen"
            allowMultiSelect
            wide
            options={extinguishingMediaOptions}
            value={selectedUnsuitableExtinguishingMedia}
            onChangedValue={(value) => setSelectedUnsuitableExtinguishingMedia(value as SelectItem[])}
          />
          <TextAreaInputField
            name="exposure_hazards_and_protection"
            label="Blootstellingsgevaren en bescherming"
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="6. Voorzorg">
          <TextAreaInputField
            name="personal_precautions"
            label="Persoonlijke voorzorgsmaatregelen"
          />
          <TextAreaInputField
            name="environmental_precautions"
            label="Milieuvoorzorgsmaatregelen"
          />
          <TextAreaInputField
            name="cleaning_methods"
            label="Reinigingsmethoden"
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="7. Hantering en opslag">
          <TextAreaInputField
            name="handling"
            label="Hantering"
          />
          <TextAreaInputField
            name="storage"
            label="Opslag"
          />
          <TextAreaInputField
            name="specific_forms_of_use"
            label="Specifieke vorm(en) van gebruik"
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="8. Beheer blootstelling">
          <TextAreaInputField
            name="respiratory_protection"
            label="Bescherming ademhalingsorganen"
          />
          <TextAreaInputField
            name="hand_protection"
            label="Bescherming handen"
          />
          <TextAreaInputField
            name="eye_protection"
            label="Bescherming ogen"
          />
          <TextAreaInputField
            name="skin_protection"
            label="Bescherming huid"
          />
          <TextAreaInputField
            name="ingestion_protection"
            label="Bescherming tegen inslikken"
          />
          <TextAreaInputField
            name="control_occupational_exposure"
            label="Beheersing beroepsmatige blootstelling"
          />
          <TextAreaInputField
            name="other_control_measures"
            label="Overige beheersmaatregelen"
          />
          <InputField
            name="personal_protective_equipment"
            label="Persoonlijke beschermingsmiddelen"
          />
          <SelectField
            name="symbol_pbm"
            label="PBM symbolen"
            placeholder="Selecteer symbolen..."
            allowMultiSelect
            options={pbmSymbolOptions}
            value={selectedPbmSymbols}
            onChangedValue={(selectedOptions) => setSelectedPbmSymbols(selectedOptions as SelectItem[] | undefined)}
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="9. Fysische en chemische eigenschappen">
          <TextAreaInputField
            name="general_information"
            label="Algemene informatie"
          />
          <SelectField
            name="physical_state"
            label="Verschijningsvorm"
            wide
            options={phaseOptions}
            value={selectedPhase}
            onChangedValue={(value) => setSelectedPhase(value as SelectItem)}
          />
          <InputField
            name="color"
            label="Kleur"
          />
          <InputField
            name="odor"
            label="Geur"
          />
          <InputField
            name="ph"
            label="pH waarde"
          />
          <InputField
            name="boiling_point"
            label="Kookpunt/kooktraject"
          />
          <InputField
            name="flash_point"
            label="Vlampunt"
          />
          <InputField
            name="flammability"
            label="Ontvlambaarheid"
          />
          <TextAreaInputField
            name="oxidizing_properties"
            label="Oxiderende eigenschappen"
          />
          <InputField
            name="explosion_properties"
            label="Ontploffingseigenschappen (ondergrens / bovengrens)"
          />
          <InputField
            name="vapor_pressure"
            label="Dampspanning"
          />
          <InputField
            name="relative_vapor_density"
            label="Relatieve dampdichtheid"
          />
          <InputField
            name="solubility"
            label="Oplosbaarheid in water of ander oplosmiddel (specificeer oplosmiddel)"
          />
          <InputField
            name="partition_coefficient"
            label="Verdelingscoëfficiënt: n-octanol/water"
          />
          <InputField
            name="viscosity"
            label="Viscositeit"
          />
          <InputField
            name="density"
            label="Dichtheid"
          />
          <InputField
            name="evaporation_rate"
            label="Verdampingssnelheid"
          />
          <InputField
            name="water_miscibility"
            label="Mengbaarheid met water"
          />
          <InputField
            name="conductivity"
            label="Geleidingsvermogen"
          />
          <InputField
            name="melting_point"
            label="Smeltpunt/smelttrajekt"
          />
          <InputField
            name="gas_group"
            label="Gasgroep"
          />
          <InputField
            name="self_ignition_temperature"
            label="Zelfontbrandingstemperatuur"
          />
          <TextAreaInputField
            name="other_information"
            label="Overige informatie"
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="10. Reactiviteit">
          <TextAreaInputField
            name="stability_and_reactivity"
            label="Chemische stabiliteit"
          />
          <TextAreaInputField
            name="dangerous_reactions"
            label="Gevaarlijke reacties"
          />
          <TextAreaInputField
            name="avoid_conditions"
            label="Te vermijden omstandigheden"
          />
          <TextAreaInputField
            name="avoid_substances"
            label="Te vermijden stoffen"
          />
          <TextAreaInputField
            name="hazardous_decomposition_products"
            label="Gevaarlijke ontledingsproducten"
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="11. Toxicologische informatie">
          <TextAreaInputField
            name="lc50_inhalation_rat"
            label="LC 50 (Inhalatie, rat)"
          />
          <TextAreaInputField
            name="ld50_dermal_rabbit"
            label="LD 50 (dermaal, konijn)"
          />
          <TextAreaInputField
            name="ld50_oral_rat"
            label="LD 50 (oraal, rat)"
          />
          <TextAreaInputField
            name="inhalation"
            label="Inademen"
          />
          <TextAreaInputField
            name="skin"
            label="Huid"
          />
          <TextAreaInputField
            name="hands"
            label="Handen"
          />
          <TextAreaInputField
            name="eyes"
            label="Ogen"
          />
          <TextAreaInputField
            name="ingestion"
            label="Inslikken"
          />
          <TextAreaInputField
            name="other_toxicological_information"
            label="Overige toxicologische informatie"
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="12. Milieu">
          <TextAreaInputField
            name="environmental_risks"
            label="Milieurisico"
          />
          <TextAreaInputField
            name="water_hazard"
            label="Waterbezwaarlijkheid"
          />
          <TextAreaInputField
            name="other_harmful_effects"
            label="Overige toxicologische informatie"
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="13. Verpakking">
          <TextAreaInputField
            name="product_disposal"
            label="Product"
          />
          <TextAreaInputField
            name="packaging_disposal"
            label="Afval verpakking"
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="14. Transport">
          <InputField
            name="un_number"
            label="UN-nummer"
          />
          <InputField
            name="proper_shipping_name"
            label="Juiste ladingsnaam"
          />
          <SelectField
            name="symbol_adr"
            label="ADR gevarenklasse"
            placeholder="Selecteer symbolen..."
            allowMultiSelect
            options={adrSymbolOptions}
            value={selectedAdrSymbols}
            onChangedValue={(selectedOptions) => setSelectedAdrSymbols(selectedOptions as SelectItem[] | undefined)}
          />
          <SelectField
            name="packaging_group"
            label="Verpakking"
            wide
            options={packagingGroupOptions}
            value={selectedPackagingGroup}
            onChangedValue={(value) => setSelectedPackagingGroup(value as SelectItem)}
          />
          <InputField
            name="transport_information"
            label="Andere relevante transport informatie"
          />
        </CollapsableFormGroup>
        <CollapsableFormGroup title="15. Regelgeving">
          <TextAreaInputField
            name="info_1"
            label="Informatie 1"
          />
          <TextAreaInputField
            name="info_2"
            label="Informatie 2"
          />
          <InputField
            name="other_relevant_information"
            label="Andere relevante informatie"
          />
          <InputField
            name="modified_information"
            label="Aangepaste informatie"
          />
          <InputField
            name="history"
            label="Geschiedenis"
          />
          <InputField
            name="alternative_sources_msd"
            label="Alternatieve bronnen MSD"
          />
        </CollapsableFormGroup>
      </ul>
      <input
        value={submitType}
        type="hidden"
        name="status"
      />
      <div className="form-buttons">
        {errorMessage && <p className={bemClassName('error-message')}>{errorMessage}</p>}
        <Button
          onHandleClick={() => setSubmitType(StatusTypes.SaveAsDraft)}
          submit
          theme={ButtonTypes.Solid}
          title="Opslaan"
        />
        <Button
          onHandleClick={() => setSubmitType(StatusTypes.SubmitForApproval)}
          submit
          theme={ButtonTypes.Solid}
          title="Indienen voor goedkeuring"
        />

        {checkUserRole('administrator') && (
          <Button
            onHandleClick={() => setSubmitType(StatusTypes.SaveAndPublish)}
            submit
            theme={ButtonTypes.Solid}
            title="Direct publiceren"
          />
        )}
      </div>
    </form>
  );
};

export default AddProductForm;
