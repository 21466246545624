import { SymbolType } from '../../Models/Types/SymbolType';
import { SymbolListItemType } from '../Components/CollapsableContent/types';

const generateSymbolList = (items: SymbolType[]) => {
  const symbols: SymbolListItemType[] = [];
  if (items !== undefined) {
    items.map((symbol: SymbolType) => {
      symbols.push({
        path: symbol.image?.styles?.png,
      });

      return null;
    });
  }
  return symbols;
};

export default generateSymbolList;
