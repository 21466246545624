import http from '../../Utils/HttpClient';

const getAllOrganisations = async () => {
  const { data } = (await http.get(`hospital`)) as any;

  return data;
};

const getOrganisation = async (id: string) => {
  const { data } = (await http.get(`hospital/${id}`)) as any;

  return data;
};

const postOrganisation = async (organisation: { name: string; logo?: string }) => {
  const data = await http.post(`hospital`, organisation);

  if (!data) return null;
  return data;
};

const patchOrganisation = async (organisation: { name: string; logo?: string }, id: string) => {
  const data = await http.patch(`hospital/${id}`, organisation);

  if (!data) return null;
  return data;
};

export { getAllOrganisations, getOrganisation, postOrganisation, patchOrganisation };
