import React, { FormEvent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useBem from '../../../Hooks/useBem';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import InputField from '../../../Shared/Components/Form/InputField';
import { ButtonTypes } from '../../../Shared/Components/Button/enums';
import Button from '../../../Shared/Components/Button/Button';
import handleError from '../../../Shared/Functions/handleError';

import '../../../Shared/Styling/EditAddGrid.scss';
import SelectField from '../../../Shared/Components/Form/SelectField';
import useHazardStatement from '../../../Shared/Hooks/useHazardStatement';
import { Statement, StatementResponse } from '../../../Models/Types/Statement';
import {
  generateClassSelectFieldList,
  generateStatementResponseSelectFieldList,
  generateStatementSelectFieldList,
} from '../../../Shared/Functions/generateStatements';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';
import { ComponentType } from '../../../Models/Types/Component';
import { postComponent } from '../../../Shared/Data/ComponentService';
import { SelectItem } from '../../../Models/Types/SelectItem';
import FillUpField from '../../../Shared/Components/Form/FillUpField';
import { FillUpSize } from '../../../Shared/Components/Form/enums';
import { HazardClass, HazardClassResponse } from '../../../Models/Types/HazardClass';
import useHazardClass from '../../../Shared/Hooks/useHazardClass';

const AddComponent = () => {
  if (!checkUserRight('entity.product.POST')) {
    return <NoPermission />;
  }

  const [bemClassName] = useBem('edit-add-grid');
  // Hazard statements
  const { data: hazardStatements, isLoading: hazardStatementsLoading } = useHazardStatement();
  const [hazardStatementsOptions, setHazardStatementsOptions] = useState<SelectItem[] | undefined>(undefined);
  const [selectedHazardStatements, setSelectedHazardStatements] = useState<SelectItem[] | undefined>(undefined);

  // Hazard classes
  const { data: hazardClasses, isLoading: hazardClassesLoading } = useHazardClass();
  const [hazardClassOptions, setHazardClassOptions] = useState<SelectItem[] | undefined>(undefined);
  const [selectedHazardClasses, setSelectedHazardClasses] = useState<SelectItem[] | undefined>(undefined);

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const submit = useCallback(
    async (e: any) => {
      const hazardStatementsValues = selectedHazardStatements?.map((s) => s.value);

      const { name, cas_number, einics_number } = e.target;
      const component = {
        name: name.value,
        hazard_statements: hazardStatementsValues,
        cas_number: cas_number.value,
        einics_number: einics_number.value,
        hazard_class: selectedHazardClasses?.map((h) => h.value),
      };

      postComponent(component as ComponentType)
        .catch((err) => setErrorMessage(handleError(err)))
        .then((result: any) => {
          if (result && !errorMessage) {
            navigate(`/productbeheer/componenten/`);
          }
        });
    },
    [selectedHazardStatements],
  );

  const submitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await submit(e);
  };

  const updateHazardStatementList = async (hClasses: SelectItem[] | undefined) => {
    setSelectedHazardClasses(hClasses);
  };

  if (!hazardStatementsLoading && hazardStatementsOptions === undefined) {
    setHazardStatementsOptions(
      generateStatementSelectFieldList((hazardStatements as StatementResponse)?.items as Statement[]),
    );
  }

  if (!hazardClassesLoading && hazardClassOptions === undefined) {
    setHazardClassOptions(generateClassSelectFieldList((hazardClasses as HazardClassResponse).items as HazardClass[]));
  }

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {}}
            title="Component toevoegen"
            showBackButton
          />
          <div className={bemClassName()}>
            <div className={bemClassName('content')}>
              <h1 className={bemClassName('header')}>Gegevens</h1>
              <form
                method="post"
                onSubmit={(e) => submitHandler(e)}
              >
                <ul className={bemClassName('input-list')}>
                  <InputField
                    name="name"
                    label="Naam component"
                    placeholder="Naam component"
                  />
                  <InputField
                    name="cas_number"
                    label="CAS nummer"
                    placeholder="CAS nummer"
                  />
                  <InputField
                    name="einics_number"
                    label="EINICS nummer"
                    placeholder="EINICS nummer"
                  />
                  <SelectField
                    name="hazard_class"
                    label="Gevarenklasse"
                    placeholder="Begin met typen..."
                    allowMultiSelect
                    options={hazardClassOptions}
                    onChangedValue={(selectedItems) =>
                      selectedItems && updateHazardStatementList(selectedItems as SelectItem[])
                    }
                  />

                  <SelectField
                    name="hazard_statements"
                    allowMultiSelect
                    options={generateStatementResponseSelectFieldList(hazardStatements as StatementResponse)}
                    wide
                    onChangedValue={(selectedOptions) => {
                      setSelectedHazardStatements(selectedOptions as SelectItem[] | undefined);
                    }}
                    label="H-Codes"
                  />
                  <FillUpField size={FillUpSize.Small} />

                  {errorMessage && <p className={bemClassName('error-message')}>{errorMessage}</p>}

                  <Button
                    submit
                    theme={ButtonTypes.Solid}
                    title="Gevarenklasse toevoegen"
                  />
                </ul>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AddComponent;
