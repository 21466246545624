import http from '../../Utils/HttpClient';

const getAllDepartments = async (url?: URL) => {
  url?.searchParams.append('sort', 'name');
  const { data } = url ? await http.get(url.toString()) : ((await http.get(`department?sort=name`)) as any);

  return data;
};

const getDepartment = async (id: string) => {
  const { data } = (await http.get(`department/${id}`)) as any;

  return data;
};

const getAllDepartmentsOfOrganisation = async (id: string) => {
  const { data } = (await http.get(`department/${id}`)) as any;

  return data;
};

const patchDepartment = async (department: { name: string }, id: string) => {
  const data = await http.patch(`department/${id}`, department);

  if (!data) return null;
  return data;
};

const unpublishDepartment = async (id: string) => {
  const data = await http.patch(`department/${id}`, { status: false });

  if (!data) return null;
  return data;
}

const postDepartment = async (department: { name: string; hospital: string }) => {
  const data = await http.post(`department`, department);

  if (!data) return null;
  return data;
};

export { getAllDepartments, getDepartment, getAllDepartmentsOfOrganisation, patchDepartment, postDepartment, unpublishDepartment};
