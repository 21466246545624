import React from 'react';

import useBem from '../../../Hooks/useBem';
import { ButtonSizes, ButtonTypes } from './enums';

import './Button.scss';

type PropType = {
  theme?: ButtonTypes;
  width?: ButtonSizes;
  title?: string;
  iconPath?: string;
  submit?: boolean;
  onHandleClick?: () => void;
};

const Button = (props: PropType) => {
  const [bemClassName, bemClassNames] = useBem('button');
  const { theme, width, title, iconPath, submit, onHandleClick } = props;

  return (
    <button
      className={bemClassNames([bemClassName(), bemClassName(null, theme), bemClassName(null, width)])}
      type={submit ? 'submit' : 'button'}
      title={title}
      onClick={() => onHandleClick!()}
    >
      {iconPath && (
        <img
          className={bemClassName('icon')}
          src={iconPath}
          alt="Button icon"
        />
      )}
      {title && <span className={bemClassName('title')}>{title}</span>}
    </button>
  );
};

Button.defaultProps = {
  theme: ButtonTypes.Bordered,
  width: ButtonSizes.Auto,
  iconPath: undefined,
  title: undefined,
  submit: false,
  onHandleClick: () => {},
};

export default Button;
