import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../../Shared/Components/Button/Button';
import { ButtonTypes } from '../../../../../Shared/Components/Button/enums';
import useBem from '../../../../../Hooks/useBem';

const SubstancesRegisterList = () => {
  const [bemClassName] = useBem('filter-bar');
  const navigate = useNavigate();

  return (
    <div className="filter-bar">
      <div className={bemClassName('filters')} />

      <div className={bemClassName('actions')}>
        <Button
          title="Verschijningsvorm toevoegen"
          onHandleClick={() => navigate('/beheer/verschijningsvormen/toevoegen')}
          theme={ButtonTypes.Solid}
        />
      </div>
    </div>
  );
};

export default SubstancesRegisterList;
