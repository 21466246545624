import React, { ChangeEvent } from 'react';
import useBem from '../../../Hooks/useBem';
import TextAreaSizes from './enums';

import './InputField.scss';

type PropType = {
  name: string;
  label: string;
  value?: string;
  width?: TextAreaSizes;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
};
const TextAreaInputField = (props: PropType) => {
  const [bemClassName, bemClassNames] = useBem('form-field');
  const { name, label, value, width, onChange } = props;

  return (
    <div className={bemClassNames([bemClassName(), bemClassName('full', null, width === TextAreaSizes.Full)])}>
      <label
        className={bemClassName('label')}
        htmlFor={name}
      >
        {label}
      </label>
      <textarea
        id={name}
        name={name}
        defaultValue={value}
        onChange={(event) => onChange!(event)}
        className={bemClassNames([bemClassName('input'), bemClassName('input', 'textarea')])}
      />
    </div>
  );
};

TextAreaInputField.defaultProps = {
  value: undefined,
  width: TextAreaSizes.Default,
  onChange: () => {},
};

export default TextAreaInputField;
