import React, { useState } from 'react';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import HeaderSeperationBar from '../../../Shared/Components/HeaderSeperationBar/HeaderSeperationBar';
import AppearancesListContent from './Components/AppearancesList/AppearancesListContent';
import Modal from '../../../Shared/Components/Modal/Modal';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';

const Appearances = () => {
  if (!checkUserRight('entity.phase.GET')) {
    return <NoPermission />;
  }

  const [showModal, setShowDModal] = useState(false);
  const [selectedAppearanceId, setSelectedAppearanceId] = useState<string | undefined>(undefined);

  function openModal(id: string) {
    setShowDModal(true);
    setSelectedAppearanceId(id);
  }

  function removeAppearance(id: string | undefined) {
    // TODO: Implement remove appearance functionality
    setShowDModal(false);

    // eslint-disable-next-line no-console
    console.log(`Remove appearance with id: ${id}`);
  }

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            title="Verschijningsvormen beheren"
            onHandleEnterKeyDown={() => {}}
          />
          <HeaderSeperationBar />
          <AppearancesListContent handleOpenModal={(id) => openModal(id)} />
        </section>
      </div>
      <Modal
        showModal={showModal}
        modalTitle="Verschijningsvorm verwijderen"
        modalDescription="Wilt u deze verschijningsvorm verwijderen?"
        modalButtonLeftText="Annuleren"
        modalButtonRightText="Verwijderen"
        rightButtonEnabled
        onLeftAction={() => setShowDModal(false)}
        onRightAction={() => removeAppearance(selectedAppearanceId)}
        onHandleClose={() => setShowDModal(false)}
      />
    </>
  );
};

export default Appearances;
