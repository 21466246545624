import React, { useState } from 'react';

import { PDFViewer } from '@react-pdf/renderer';
import CollapsableComponentGroup from '../../../../Shared/Components/CollapsableContent/CollapsableComponentGroup';
import { ProductTypes, SettingTypes } from '../../enums';
import { Substance } from '../../../../Models/Types/Substance';
import TextAreaInputField from '../../../../Shared/Components/Form/TextAreaInputField';
import SafetyInformationCardPDF from '../../../../Shared/Components/PdfGenerator/PdfGeneratorSafetyInformationCard';
import { ButtonSizes, ButtonTypes } from '../../../../Shared/Components/Button/enums';
import Button from '../../../../Shared/Components/Button/Button';
import { generateFlatStatements, generateStatements } from '../../../../Shared/Functions/generateStatements';
import generateFlatTitleList from '../../../../Shared/Functions/generateFlatList';
import generateSymbolList from '../../../../Shared/Functions/generateSymbolList';
import { PdfListItem } from '../../../../Shared/Components/PdfGenerator/types';

type PropType = {
  substanceData: Substance;
};

const SafetyInformationCard = (props: PropType) => {
  const { substanceData } = props;
  const {
    product_type,
    name,
    article_number,
    mpz_number,
    synonyms,
    document,
    purpose,
    company,
    vib_version,
    supplier_vib_date,
    vib_revision_date,
    gevaren_mens_en_milieu,
    cas_number,
    eec_number,
    eg_number,
    iupac_name,
    molecule_formula,
    hazard_statements,
    precautionary_statements,
    first_aid_inhalation,
    first_aid_ingestion,
    first_aid_skin_contact,
    first_aid_hand_contact,
    first_aid_eye_contact,
    special_workplace_provisions,
    suitable_extinguishing_media,
    unsuitable_extinguishing_media,
    exposure_hazards_and_protection,
    personal_precautions,
    environmental_precautions,
    cleaning_methods,
    handling,
    storage,
    specific_forms_of_use,
    mac_value,
    personal_protective_equipment,
    respiratory_protection,
    hand_protection,
    ingestion_protection,
    eye_protection,
    skin_protection,
    control_occupational_exposure,
    other_control_measures,
    general_information,
    physical_state,
    color,
    odor,
    ph,
    boiling_point,
    flash_point,
    flammability,
    oxidizing_properties,
    explosion_properties,
    vapor_pressure,
    relative_vapor_density,
    solubility,
    partition_coefficient,
    viscosity,
    density,
    evaporation_rate,
    water_miscibility,
    conductivity,
    melting_point,
    gas_group,
    self_ignition_temperature,
    stability_and_reactivity,
    avoid_conditions,
    avoid_substances,
    hazardous_decomposition_products,
    other_information,
    lc50_inhalation_rat,
    ld50_dermal_rabbit,
    ld50_oral_rat,
    inhalation,
    skin,
    eyes,
    hands,
    ingestion,
    other_toxicological_information,
    environmental_risks,
    water_hazard,
    other_harmful_effects,
    disposal_instructions,
    product_disposal,
    packaging_disposal,
    transport_information,
    un_number,
    proper_shipping_name,
    packaging_group,
    other_relevant_information,
    signal_words,
    info_1,
    info_2,
    modified_information,
    alternative_sources_msd,
    history,
    // label_class,
    hazard_class,
    symbol_ghs,
    symbol_pbm,
    symbol_adr,
    components,
    dangerous_reactions,
  } = substanceData;

  const [settings, setSettings] = useState({
    amount: 1,
    notes: other_information,
  });
  const [updatedSettings, setUpdatedSettings] = useState(settings);

  const chapterOne = [
    { title: 'Productnaam', description: name },
    { title: 'Gebruikers doeleinde', description: purpose },
    { title: 'Leverancier', description: company?.name },
    { title: 'Synoniem', description: synonyms },
    { title: 'Type product', description: product_type },
    { title: 'MPZ nummer', description: mpz_number },
    { title: 'Artikelnummer', description: article_number },
    { title: 'Versie', description: vib_version },
    { title: 'VIB datum leverancier', description: supplier_vib_date },
    { title: 'VIB herzieningsdatum', description: vib_revision_date },
    { title: 'Brondocument', description: document?.filename },
  ];
  const chapterTwo =
    product_type === ProductTypes.Product
      ? [
          { title: 'Gevarenklassen', list: generateFlatTitleList(hazard_class) },
          { title: 'H-zinnen', list: generateFlatStatements(hazard_statements) },
          { title: 'GHS Symbolen', list: generateFlatTitleList(symbol_ghs) },
          { title: 'Signaalwoord', description: signal_words },
          { title: 'P-Zinnen', list: generateFlatStatements(precautionary_statements) },
          { title: 'Gevaren mens en milieu', description: gevaren_mens_en_milieu },
          { title: 'CAS-nummer', description: cas_number },
          { title: 'EEC-catalogusnummer', description: eec_number },
          { title: 'EINECS / ELINCS nummer', description: eg_number },
          { title: 'IUPAC-naam ', description: iupac_name },
          { title: 'Molecuul formule', description: molecule_formula },
        ]
      : [
          { title: 'Gevarenklassen', list: generateFlatTitleList(hazard_class) },
          { title: 'H-zinnen', list: generateFlatStatements(hazard_statements) },
          { title: 'GHS Symbolen', list: generateFlatTitleList(symbol_ghs) },
          { title: 'Signaalwoord', description: signal_words },
          { title: 'P-Zinnen', list: generateFlatStatements(precautionary_statements) },
          { title: 'Gevaren mens en milieu', description: gevaren_mens_en_milieu },
        ];
  const chapterThree =
    product_type === ProductTypes.Product
      ? [
          {
            title: 'Componenten',
            list: components.map((component) => `${component.component.name} (${component.percentage.toString()}%)`),
          },
        ]
      : [
          {
            title: 'Componenten',
            pdfList: components.map(
              (component): PdfListItem => ({
                name: component.component.name,
                value: `${component.percentage.toString()}%`,
              }),
            ),
          },
          { title: 'Mac waarde', description: mac_value },
        ];
  const chapterFour = [
    { title: 'Inademing', description: first_aid_inhalation },
    { title: 'Inslikken', description: first_aid_ingestion },
    { title: 'Huidcontact', description: first_aid_skin_contact },
    { title: 'Handcontact', description: first_aid_hand_contact },
    { title: 'Oogcontact', description: first_aid_eye_contact },
    {
      title: 'Noodzakelijke speciale voorzieningen op werkplek',
      description: special_workplace_provisions,
    },
  ];
  const chapterFive = [
    { title: 'Geschikte blusmiddelen', list: generateFlatTitleList(suitable_extinguishing_media) },
    { title: 'Ongeschikte blusmiddelen', list: generateFlatTitleList(unsuitable_extinguishing_media) },
    { title: 'Gevaren', description: exposure_hazards_and_protection },
  ];
  const chapterSix = [
    { title: 'Persoonlijke voorzorgsmaatregelen', description: personal_precautions },
    { title: 'Milieuvoorzorgsmaatregelen', description: environmental_precautions },
    { title: 'Reinigingsmethoden', description: cleaning_methods },
  ];
  const chapterSeven = [
    { title: 'Hantering', description: handling },
    { title: 'Opslag', description: storage },
    { title: 'Specifieke vorm(en) van gebruik', description: specific_forms_of_use },
  ];
  const chapterEight = [
    { title: 'Bescherming ademhalingsorganen', description: respiratory_protection },
    { title: 'Bescherming handen', description: hand_protection },
    { title: 'Bescherming ogen', description: eye_protection },
    { title: 'Bescherming huid', description: skin_protection },
    { title: 'Bescherming handen', description: ingestion_protection },
    { title: 'Beheersing beroepsmatige blootstelling', description: control_occupational_exposure },
    { title: 'Overige beheersmaatregelen', description: other_control_measures },
    { title: 'Persoonlijke beschermingsmiddelen', description: personal_protective_equipment },
  ];
  const chapterNine = [
    { title: 'Algemene informatie', description: general_information },
    { title: 'Fysische toestand', description: physical_state?.title },
    { title: 'Kleur', description: color },
    { title: 'Geur', description: odor },
    { title: 'pH waarde', description: ph },
    { title: 'Kookpunt/kooktraject', description: boiling_point },
    { title: 'Vlampunt', description: flash_point },
    { title: 'Ontvlambaarheid', description: flammability },
    { title: 'Oxiderende eigenschappen', description: oxidizing_properties },
    { title: 'Ontploffingseigenschappen (ondergrens / bovengrens)', description: explosion_properties },
    { title: 'Dampspanning', description: vapor_pressure },
    { title: 'Relatieve dampdichtheid', description: relative_vapor_density },
    { title: 'Oplosbaarheid in water of ander oplosmiddel', description: solubility },
    { title: 'Verdelingscoëfficiënt: n-octanol/water', description: partition_coefficient },
    { title: 'Viscositeit', description: viscosity },
    { title: 'Dichtheid', description: density },
    { title: 'Verdampingssnelheid', description: evaporation_rate },
    { title: 'Mengbaarheid met water', description: water_miscibility },
    { title: 'Geleidingsvermogen', description: conductivity },
    { title: 'Smeltpunt/smelttrajekt', description: melting_point },
    { title: 'Gasgroep', description: gas_group },
    { title: 'Zelfontbrandingstemperatuur', description: self_ignition_temperature },
  ];

  const chapterTen = [
    { title: 'Chemische stabiliteit', description: stability_and_reactivity },
    { title: 'Gevaarlijke reacties', description: dangerous_reactions },
    { title: 'Te vermijden omstandigheden', description: avoid_conditions },
    { title: 'Te vermijden stoffen', description: avoid_substances },
    { title: 'Gevaarlijke ontledingsproducten', description: hazardous_decomposition_products },
  ];

  const chapterEleven = [
    { title: 'LC 50 (Inhalatie,rat)', description: lc50_inhalation_rat },
    { title: 'LD 50 (dermaal, konijn)', description: ld50_dermal_rabbit },
    { title: 'LD 50 (oraal, rat)', description: ld50_oral_rat },
    { title: 'Inademen', description: inhalation },
    { title: 'Huid', description: skin },
    { title: 'Handen', description: hands },
    { title: 'Ogen', description: eyes },
    { title: 'Inslikken', description: ingestion },
    { title: 'Overige toxicologische informatie', description: other_toxicological_information },
  ];

  const chapterTwelve = [
    { title: 'Milieurisico', description: environmental_risks },
    { title: 'Waterbezwaarlijkheid', description: water_hazard },
    { title: 'Andere schadelijke effecten', description: other_harmful_effects },
  ];

  const chapterThirteen = [
    { title: 'Product', description: product_disposal },
    { title: 'Verpakking', description: packaging_group?.title },
    { title: 'Afval verpakking', description: packaging_disposal },
    { title: 'Afval instructies', description: disposal_instructions },
  ];

  const chapterFourteen = [
    { title: 'UN-nummer', description: un_number },
    { title: 'Juiste ladingsnaam', description: proper_shipping_name },
    { title: 'ADR-gevarenklasse', list: generateFlatTitleList(symbol_adr) },
    { title: 'Andere relevante informatie', description: transport_information },
  ];

  const chapterFifteen = [
    { title: 'Informatie 1', description: info_1 },
    { title: 'Informatie 2', chapterSixteen: info_2 },
    { title: 'Andere relevante informatie', description: other_relevant_information },
    { title: 'Aangepaste informatie', description: modified_information },
    { title: 'Alternatieve bronnen MSD', description: alternative_sources_msd },
    { title: 'Geschiedenis', description: history },
  ];

  function updateSettings(value: string | string[] | number, settingType: SettingTypes) {
    switch (settingType) {
      case SettingTypes.Amount:
        setSettings({ ...settings, amount: value as number });
        break;
      default:
        setSettings({ ...settings, [settingType]: value === undefined ? [] : value });
        break;
    }
  }

  return (
    <ul className="information-list">
      <CollapsableComponentGroup title="Instellingen">
        <TextAreaInputField
          name="notes"
          label="Opmerkingen"
          value={settings.notes}
          onChange={(e) => updateSettings(e.target.value, SettingTypes.Notes)}
        />
        <Button
          title="VIB genereren"
          theme={ButtonTypes.Solid}
          onHandleClick={() => setUpdatedSettings(settings)}
          width={ButtonSizes.Auto}
        />
      </CollapsableComponentGroup>
      <CollapsableComponentGroup
        title="PDF"
        open
      >
        <PDFViewer>
          <SafetyInformationCardPDF
            amount={updatedSettings.amount}
            chapterOne={chapterOne}
            chapterTwo={chapterTwo}
            chapterThree={chapterThree}
            chapterFour={chapterFour}
            chapterFive={chapterFive}
            chapterSix={chapterSix}
            chapterSeven={chapterSeven}
            chapterEight={chapterEight}
            chapterNine={chapterNine}
            chapterTen={chapterTen}
            chapterEleven={chapterEleven}
            chapterTwelve={chapterTwelve}
            chapterThirteen={chapterThirteen}
            chapterFourteen={chapterFourteen}
            chapterFifteen={chapterFifteen}
            otherInformation={updatedSettings.notes}
            hStatements={generateStatements(hazard_statements)}
            dangerSymbols={generateSymbolList(symbol_ghs)}
            pbmSymbols={generateSymbolList(symbol_pbm)}
            adrSymbols={generateSymbolList(symbol_adr)}
            hazardClasses={hazard_class}
            lastModifiedDate={vib_revision_date}
          />
        </PDFViewer>
      </CollapsableComponentGroup>
    </ul>
  );
};
export default SafetyInformationCard;
