import http from '../../Utils/HttpClient';

const getAllSymbols = async (url?: URL) => {
  const { data } = url ? await http.get(url.toString()) : ((await http.get(`symbol`)) as any);

  return data;
};

const getSymbol = async (id: string) => {
  const { data } = (await http.get(`symbol/${id}`)) as any;

  return data;
};

const patchSymbol = async (symbol: { title: string; type: string; image: string }, id: string) => {
  const data = await http.patch(`symbol/${id}`, symbol);

  if (!data) return null;
  return data;
};

const postSymbol = async (symbol: { title: string; type: string; image: string }) => {
  const data = await http.post(`symbol`, symbol);

  if (!data) return null;
  return data;
};

export { getAllSymbols, getSymbol, patchSymbol, postSymbol };
