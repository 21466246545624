import http from '../../Utils/HttpClient';

const getAllHazardStatements = async (url?: URL) => {
  const { data } = url ? await http.get(url.toString()) : ((await http.get(`hazard-statement?page_limit=2500`)) as any);
  return data;
};

const getHazardStatement = async (id: string) => {
  const { data } = (await http.get(`hazard-statement/${id}`)) as any;

  return data;
};

const patchHazardStatement = async (statement: { code: string; description: string }, id: string) => {
  const data = await http.patch(`hazard-statement/${id}`, statement);

  if (!data) return null;
  return data;
};

const postHazardStatement = async (statement: any) => {
  const data = await http.post(`hazard-statement`, statement);

  if (!data) return null;
  return data;
};

export { getAllHazardStatements, getHazardStatement, patchHazardStatement, postHazardStatement };
