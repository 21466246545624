import React, { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import useBem from '../../../Hooks/useBem';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import InputField from '../../../Shared/Components/Form/InputField';
import Button from '../../../Shared/Components/Button/Button';
import { ButtonTypes } from '../../../Shared/Components/Button/enums';
import TextAreaInputField from '../../../Shared/Components/Form/TextAreaInputField';
import handleError from '../../../Shared/Functions/handleError';
import useHazardStatement from '../../../Shared/Hooks/useHazardStatement';
import { Statement } from '../../../Models/Types/Statement';
import { patchHazardStatement } from '../../../Shared/Data/HazardStatementService';

import '../../../Shared/Styling/EditAddGrid.scss';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';
import Checkbox from '../../../Shared/Components/Checkbox/Checkbox';
import FillUpField from '../../../Shared/Components/Form/FillUpField';
import { FillUpSize } from '../../../Shared/Components/Form/enums';

const EditHazardStatement = () => {
  if (!checkUserRight('entity.hazard_statement.PATCH')) {
    return <NoPermission />;
  }

  const [bemClassName] = useBem('edit-add-grid');
  const { id } = useParams();
  const { data: statement, isLoading } = useHazardStatement(id);
  const queryClient = useQueryClient();
  const [cmrChecked, setCmrChecked] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  const submit = useCallback(async (e: any) => {
    const { code, description, cmr } = e.target;
    const editedHazardStatement = {
      code: code.value,
      description: description.value,
      cmr: cmr.checked,
    };
    patchHazardStatement(editedHazardStatement as { code: string; description: string }, id!)
      .catch((err) => {
        setSuccessMessage(undefined);
        setErrorMessage(handleError(err));
      })
      .then((result: any) => {
        if (result) {
          setErrorMessage(undefined);
          setSuccessMessage('H/EUH-zin succesvol aangepast');

          queryClient.invalidateQueries({ queryKey: [`hazard-${id}`] });
        }
      });
  }, []);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await submit(e);
  };

  useEffect(() => {
    if (statement) {
      setCmrChecked((statement as Statement).cmr);
    }
  }, [statement]);

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {}}
            title="H/EUH-zin aanpassen"
            showBackButton
          />
          <div className={bemClassName()}>
            <div className={bemClassName('content')}>
              <h1 className={bemClassName('header')}>Gegevens</h1>

              {isLoading ? (
                <p>Loading...</p>
              ) : (
                <form
                  method="post"
                  onSubmit={(e) => submitHandler(e)}
                >
                  <ul className={bemClassName('input-list')}>
                    <InputField
                      name="code"
                      value={(statement as Statement)?.code}
                      label="Naam H/EUH-zin"
                      placeholder="Naam H/EUH-zin"
                    />
                    <TextAreaInputField
                      name="description"
                      value={(statement as Statement)?.description}
                      label="Beschrijving H/EUH-zin"
                    />
                    <FillUpField size={FillUpSize.Small} />
                    <Checkbox
                      boxChecked={cmrChecked}
                      onToggleChecked={() => setCmrChecked(!cmrChecked)}
                      label="CMR-stof"
                      checkboxId="cmr"
                      showLabel
                    />
                    <FillUpField size={FillUpSize.ExtraLarge} />

                    {errorMessage && <p className={bemClassName('error-message')}>{errorMessage}</p>}
                    {successMessage && <p className={bemClassName('success-message')}>{successMessage}</p>}

                    <Button
                      submit
                      theme={ButtonTypes.Solid}
                      title="H/EUH-zin aanpassen"
                    />
                  </ul>
                </form>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default EditHazardStatement;
