import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getAllDepartments, getDepartment } from '../Data/DepartmentService';

import { Department, DepartmentResponse } from '../../Models/Types/Department';

const useDepartment = (id?: string, hospitalId?: string): UseQueryResult<Department | DepartmentResponse, Error> => {
  if (id) {
    return useQuery({
      queryKey: [`department-${id}`, 'departments'],
      queryFn: () => getDepartment(id),
      ...{
        staleTime: undefined,
        cacheTime: 10,
      },
    });
  }

  return useQuery({
    queryKey: [`department-${hospitalId}`, 'departments'],
    queryFn: () => getAllDepartments(),
    ...{
      staleTime: undefined,
      cacheTime: 10,
    },
  });
};

export default useDepartment;
