import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Supplier, SupplierResponse } from '../../Models/Types/Supplier';
import { getAllSuppliers, getSupplier } from '../Data/SupplierService';

const useSupplier = (id?: string): UseQueryResult<SupplierResponse | Supplier, Error> =>
  useQuery({
    queryKey: [`supplier-${id}`], 
    queryFn: () => (id ? getSupplier(id) : getAllSuppliers()), 
  });
export default useSupplier;
