import React from 'react';
import ManualsFilterBar from './ManualsFilterBar';
import ManualsList from './ManualsList';

import './ManualsList.scss';

type PropType = {
  handleOpenModal: (id: string) => void;
};

const ManualListContent = (props: PropType) => {
  const { handleOpenModal } = props;

  return (
    <>
      <ManualsFilterBar />
      <div className="list-container">
        <ManualsList handleOpenModal={(id) => handleOpenModal(id)} />
      </div>
    </>
  );
};

export default ManualListContent;
