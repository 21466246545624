import React, { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import useBem from '../../../Hooks/useBem';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import InputField from '../../../Shared/Components/Form/InputField';
import Button from '../../../Shared/Components/Button/Button';
import { ButtonTypes } from '../../../Shared/Components/Button/enums';
import FillUpField from '../../../Shared/Components/Form/FillUpField';
import { FillUpSize } from '../../../Shared/Components/Form/enums';
import handleError from '../../../Shared/Functions/handleError';
import useRoom from '../../../Shared/Hooks/useRoom';
import { patchRoom } from '../../../Shared/Data/RoomService';

import '../../../Shared/Styling/EditAddGrid.scss';
import { Room } from '../../../Models/Types/Room';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';

const EditRoom = () => {
  if (!checkUserRight('entity.room.PATCH')) {
    return <NoPermission />;
  }
  const [bemClassName] = useBem('edit-add-grid');
  const { roomId } = useParams();
  const { data: room, isLoading } = useRoom(roomId);
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  const submit = useCallback(async (e: any) => {
    const { value } = e.target.name;
    const editedRoom = {
      name: value,
    };
    patchRoom(editedRoom as { name: string }, roomId!)
      .catch((err) => {
        setSuccessMessage(undefined);
        setErrorMessage(handleError(err));
      })
      .then((result: any) => {
        if (result) {
          setErrorMessage(undefined);
          setSuccessMessage('Kamer succesvol aangepast');

          queryClient.invalidateQueries({ queryKey: [`room-${roomId}`] });
        }
      });
  }, []);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await submit(e);
  };

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {}}
            title="Kamer aanpassen"
            showBackButton
          />
          <div className={bemClassName()}>
            <div className={bemClassName('content')}>
              <h1 className={bemClassName('header')}>Gegevens</h1>

              {isLoading ? (
                <p>Loading...</p>
              ) : (
                <form
                  method="post"
                  onSubmit={(event) => submitHandler(event)}
                >
                  <ul className={bemClassName('input-list')}>
                    <InputField
                      name="name"
                      value={(room as Room)?.name}
                      label="Naam kamer"
                      placeholder="Naam kamer"
                    />
                    <FillUpField size={FillUpSize.ExtraSmall} />

                    {errorMessage && <p className={bemClassName('error-message')}>{errorMessage}</p>}
                    {successMessage && <p className={bemClassName('success-message')}>{successMessage}</p>}

                    <Button
                      submit
                      theme={ButtonTypes.Solid}
                      title="Kamer aanpassen"
                    />
                  </ul>
                </form>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default EditRoom;
