import React, { useRef, useState } from 'react';
import useBem from '../../../Hooks/useBem';
import Button from '../Button/Button';
import handleError from '../../Functions/handleError';
import postFile from '../../Data/UploadFileService';
import { FileTypes } from './enums';
import { File as FileType } from '../../../Models/Types/File';

import './UploadField.scss';

type PropType = {
  name: string;
  label: string;
  document?: FileType;
  type?: FileTypes;
  showPreview?: boolean;
  imgUrl?: string;
  url: string;
  onChange?: (id: string) => void;
};
const UploadField = (props: PropType) => {
  const [bemClassName] = useBem('form-field');
  const { name, label, document, type, showPreview, imgUrl, url, onChange } = props;
  const [uploadedItemId, setUploadedItemId] = React.useState<string | undefined>(document?.id);
  const [selectedFile, setSelectedFile] = React.useState<File | undefined>(undefined);
  const [imageUrl, setImageUrl] = React.useState<string | undefined>(imgUrl);
  const [isSelected, setIsSelected] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const ref = useRef<HTMLInputElement>(null);

  const uploadItem = async () => {
    await postFile(selectedFile!, selectedFile!.name, url)
      .catch((err) => setErrorMessage(handleError(err)))
      .then((result: any) => {
        if (result) {
          if (errorMessage === undefined) setSuccessMessage(`${selectedFile?.name} geuploaded`);
          setUploadedItemId({ ...result }.data.id);
          setImageUrl({ ...result }.data.url);
          onChange!({ ...result }.data.id);
        }
      });
  };

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSelectedFile(event.target.files![0]);
    setIsSelected(!!event.target.files![0]);
  }

  const handleClick = () => {
    ref.current?.click();
  };

  return (
    <div className={bemClassName(null, 'wide-upload')}>
      {label !== '' && (
        <label
          className={bemClassName('label')}
          htmlFor={name}
        >
          {label}
        </label>
      )}

      <div className={bemClassName('upload-container')}>
        <button
          type="button"
          className="upload-field"
          onClick={() => handleClick()}
          aria-label={isSelected ? selectedFile?.name : 'Selecteer bestand'}
          title={isSelected ? selectedFile?.name : 'Selecteer bestand'}
        >
          <span>{isSelected ? selectedFile?.name : 'Selecteer bestand'}</span>
        </button>
        <input
          type="file"
          name="file"
          accept={type}
          onChange={(event) => handleFileChange(event)}
          ref={ref}
          className="hidden"
        />

        <input
          type="hidden"
          name={name}
          value={uploadedItemId}
        />
        {isSelected && (
          <Button
            title="Uploaden"
            onHandleClick={() => uploadItem()}
          />
        )}
      </div>

      {errorMessage && <p className={bemClassName('error-message')}>{errorMessage}</p>}
      {successMessage && <p className={bemClassName('success-message')}>{successMessage}</p>}

      {showPreview && imageUrl && (
        <div className={bemClassName('image-container')}>
          <img
            src={imageUrl}
            alt="logo"
            className={bemClassName('image')}
          />
        </div>
      )}
      {type === FileTypes.Pdf && document?.filename !== undefined && (
        <div className={bemClassName('filename-container')}>
          Huidig bestand:{' '}
          <a
            href={document?.url}
            download={document?.filename}
            target="_blank"
            rel="noreferrer"
          >
            {document?.filename}
          </a>
        </div>
      )}
    </div>
  );
};

UploadField.defaultProps = {
  document: undefined,
  showPreview: false,
  imgUrl: undefined,
  type: FileTypes.Pdf,
  onChange: () => {},
};

export default UploadField;
