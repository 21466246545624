const generateFlatTitleList = (statements: any[]) => {
  const list: string[] = [];
  statements?.forEach((statement) => {
    list.push(`${statement.title}`);
  });

  return list;
};

const generateFlatIdList = (statements: any[]) => {
  const list: string[] = [];
  statements?.forEach((statement) => {
    list.push(`${statement.id}`);
  });

  return list;
};

export { generateFlatIdList };
export default generateFlatTitleList;
