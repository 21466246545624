import React from 'react';
import AppearancesFilterBar from './AppearancesFilterBar';
import AppearancesList from './AppearancesList';

import './AppearancesList.scss';

type PropType = {
  handleOpenModal: (id: string) => void;
};

const AppearancesListContent = (props: PropType) => {
  const { handleOpenModal } = props;

  return (
    <>
      <AppearancesFilterBar />
      <div className="list-container">
        <AppearancesList handleOpenModal={(id) => handleOpenModal(id)} />
      </div>
    </>
  );
};

export default AppearancesListContent;
