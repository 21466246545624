import React from 'react';

import { useParams } from 'react-router-dom';
import useBem from '../../../../Hooks/useBem';
import { SubstanceRegisterEditFormListTabs } from '../../enums';
import GeneralInformationForm from './GeneralInformationForm';
import { Substance } from '../../../../Models/Types/Substance';

import './SubstancesRegisterEditFormList.scss';
import Loading from '../../../../Shared/Components/Loading/Loading';
import OrganisationSpecificForm from './OrganisationSpecificForm';
import useSubstanceRevision from '../../../../Shared/Hooks/useSubstanceRevision';

type PropType = {
  activeTab?: string;
};

const SubstancesRegisterEditFormList = (props: PropType) => {
  const { id, rev_id } = useParams();
  const [bemClassName] = useBem('substances-register-edit-form');
  const { activeTab } = props;
  const { data: substance, isLoading } = useSubstanceRevision(id!, parseInt(rev_id!, 10));

  return (
    <>
      {!isLoading && (
        <div className={bemClassName()}>
          {activeTab === SubstanceRegisterEditFormListTabs.General && (
            <GeneralInformationForm substanceData={substance as Substance} />
          )}
          {activeTab === SubstanceRegisterEditFormListTabs.Organisation && <OrganisationSpecificForm />}
        </div>
      )}
      {isLoading && <Loading />}
    </>
  );
};

SubstancesRegisterEditFormList.defaultProps = {
  activeTab: SubstanceRegisterEditFormListTabs.General,
};

export default SubstancesRegisterEditFormList;
