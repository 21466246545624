import React from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import useBem from '../../../Hooks/useBem';

import './SelectField.scss';
import { SelectItem } from '../../../Models/Types/SelectItem';

type PropType = {
  name: string;
  label: string;
  placeholder?: string;
  allowNewValues?: boolean;
  allowMultiSelect?: boolean;
  value?: SelectItem | SelectItem[];
  options?: SelectItem[];
  onChangedValue?: (selectedValue: MultiValue<SelectItem> | SingleValue<SelectItem> | undefined) => void;
  onInputChange?: (inputValue: string) => void;
  wide?: boolean;
};
const SelectField = (props: PropType) => {
  const [bemClassName, bemClassNames] = useBem('form-field');
  const {
    name,
    label,
    placeholder,
    allowNewValues,
    allowMultiSelect,
    value,
    options,
    onChangedValue,
    onInputChange,
    wide,
  } = props;

  return (
    <div className={bemClassNames([bemClassName(), bemClassName(null, 'wide', allowNewValues || wide)])}>
      <label
        className={bemClassName('label')}
        htmlFor={name}
      >
        {label}
      </label>

      {allowNewValues ? (
        <CreatableSelect
          id={name}
          name={name}
          defaultValue={value}
          placeholder={placeholder}
          options={options}
          isClearable
          isMulti={allowMultiSelect}
          classNamePrefix="select-box"
          onChange={(selectedValue) => onChangedValue!(selectedValue)}
        />
      ) : (
        <Select
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (options && options.length < 0) return;
              e.preventDefault();
            }
          }}
          id={name}
          name={name}
          defaultValue={value}
          placeholder={placeholder}
          options={options}
          isClearable
          isMulti={allowMultiSelect}
          classNamePrefix="select-box"
          onChange={(selectedValue) => onChangedValue!(selectedValue)}
          onInputChange={(inputValue) => {
            if (inputValue && inputValue.length > 2) {
              onInputChange!(inputValue);
            }
          }}
        />
      )}
    </div>
  );
};

SelectField.defaultProps = {
  allowNewValues: false,
  allowMultiSelect: false,
  value: undefined,
  options: [],
  placeholder: 'Maak keuze...',
  onChangedValue: () => {},
  onInputChange: () => {},
  wide: false,
};

export default SelectField;
