import React from 'react';

import { SubstancesRegisterDetailInformationTabs } from '../../enums';
import GeneralInformation from './GeneralInformation';
import WorkInstructionCard from './WorkInstructionCard';
import OrganisationSpecificInformation from './OrganisationSpecificInformation';
import PrintLabel from './PrintLabel';
import useBem from '../../../../Hooks/useBem';
import { Substance } from '../../../../Models/Types/Substance';
import SafetyInformationCard from './SafetyInformationCard';
import SourceDocument from './ShareSubstance/SourceDocument';
import ShareSubstance from './ShareSubstance/ShareSubstance';

import './SubstancesRegisterDetailInformation.scss';
import EHBOInformation from './EHBOInformation';

type PropType = {
  activeTab?: SubstancesRegisterDetailInformationTabs;
  substanceData: Substance;
};

const SubstancesRegisterDetailInformation = (props: PropType) => {
  const [bemClassName] = useBem('substances-register-detail-information');
  const { activeTab, substanceData } = props;

  return (
    <div className={bemClassName()}>
      {activeTab === SubstancesRegisterDetailInformationTabs.General && (
        <GeneralInformation substanceData={substanceData} />
      )}
      {activeTab === SubstancesRegisterDetailInformationTabs.OrganisationSpecific && (
        <OrganisationSpecificInformation />
      )}
      {activeTab === SubstancesRegisterDetailInformationTabs.WorkInstructionCard && (
        <WorkInstructionCard substanceData={substanceData} />
      )}
      {activeTab === SubstancesRegisterDetailInformationTabs.PrintLabel && <PrintLabel substanceData={substanceData} />}
      {activeTab === SubstancesRegisterDetailInformationTabs.EHBO && (
        <EHBOInformation substanceData={substanceData} />
      )}
      {activeTab === SubstancesRegisterDetailInformationTabs.VIB && (
        <SafetyInformationCard substanceData={substanceData} />
      )}
      {activeTab === SubstancesRegisterDetailInformationTabs.SourceDocument && (
        <SourceDocument downloadUrl={substanceData.document?.url} />
      )}
      {activeTab === SubstancesRegisterDetailInformationTabs.Share && <ShareSubstance substanceData={substanceData}/>}
    </div>
  );
};

SubstancesRegisterDetailInformation.defaultProps = {
  activeTab: SubstancesRegisterDetailInformationTabs.General,
};

export default SubstancesRegisterDetailInformation;
