import http from '../../Utils/HttpClient';
import { HospitalProductLocationType } from '../../Models/Types/HospitalProductLocationType';

const getAllSubstances = async (url: URL) => {
  const { data } = (await http.get(url.toString())) as any;

  return data;
};

const getSubstance = async (id: string) => {
  const { data } = (await http.get(`product/${id}`)) as any;

  return data;
};

const getSubstanceRevision = async (id: string, rev_id: number) => {
  const { data } = (await http.get(`product/${id}/${rev_id}`)) as any;

  return data;
};

const getSubstanceOrganisationData = async (id: string) => {
  const { data } = (await http.get(`hospital-product/${id}`)) as any;

  return data;
};

const postSubstanceOrganisationData = async (
  hospitalProduct: any,
  id: string,
  organisation: string,
  locationValues: HospitalProductLocationType[] | undefined,
) => {
  const { name, document, waste_information, waste_information_remarks } = hospitalProduct.target.elements;
  const { data } = (await http.post(`hospital-product`, {
    name: name?.value,
    hospital: organisation,
    product: id,
    enabled: true,
    document: document?.value === '' ? null : document?.value,
    waste_information: waste_information?.value,
    waste_information_remarks: waste_information_remarks?.value,
    locations: locationValues,
  })) as any;

  return data;
};

const patchSubstanceOrganisationData = async (
  hospitalProduct: any,
  productId: string,
  hospitalProductId: string,
  organisation: string,
  locationValues: HospitalProductLocationType[] | undefined,
) => {
  const { name, document, waste_information, waste_information_remarks } = hospitalProduct.target.elements;
  const { data } = (await http.patch(`hospital-product/${hospitalProductId}`, {
    name: name?.value,
    hospital: organisation,
    product: productId,
    document: document?.value === '' ? null : document?.value,
    enabled: true,
    waste_information: waste_information?.value,
    waste_information_remarks: waste_information_remarks?.value,
    locations: locationValues,
  })) as any;

  return data;
};

const toggleSubstanceInOrganisation = async (id: string, action: string) => {
  const { data } = (await http.get(`product/${id}/${action}`)) as any;

  return data;
};

const approveSubstance = async (id: string) => {
  const { data } = (await http.get(`product/${id}/approve`)) as any;

  return data;
};

const postSubstance = async (
  substance: any,
  ghsValues: string[] | undefined,
  pbmValues: string[] | undefined,
  adrValues: string[] | undefined,
  suitableExtinguishingMediaValues: string[] | undefined,
  unsuitableExtinguishingMediaValues: string[] | undefined,
  precautionaryStatementsValues: string[] | undefined,
  hazardClassValues: string[] | undefined,
  mixtures: { component: string; percentage: string }[] | undefined,
) => {
  const {
    product_type,
    name,
    article_number,
    mpz_number,
    synonyms,
    purpose,
    document,
    company,
    vib_version,
    supplier_vib_date,
    vib_revision_date,
    gevaren_mens_en_milieu,
    description_components,
    cas_number,
    eec_number,
    eg_number,
    iupac_name,
    molecule_formula,
    hazard_statements,
    first_aid_inhalation,
    first_aid_ingestion,
    first_aid_skin_contact,
    first_aid_eye_contact,
    special_workplace_provisions,
    exposure_hazards_and_protection,
    personal_precautions,
    environmental_precautions,
    cleaning_methods,
    handling,
    storage,
    specific_forms_of_use,
    mac_value,
    personal_protective_equipment,
    respiratory_protection,
    hand_protection,
    eye_protection,
    skin_protection,
    control_occupational_exposure,
    other_control_measures,
    general_information,
    physical_state,
    color,
    odor,
    ph,
    boiling_point,
    flash_point,
    flammability,
    oxidizing_properties,
    explosion_properties,
    vapor_pressure,
    relative_vapor_density,
    solubility,
    partition_coefficient,
    viscosity,
    density,
    evaporation_rate,
    water_miscibility,
    conductivity,
    melting_point,
    gas_group,
    self_ignition_temperature,
    stability_and_reactivity,
    avoid_conditions,
    avoid_substances,
    hazardous_decomposition_products,
    other_information,
    lc50_inhalation_rat,
    ld50_dermal_rabbit,
    ld50_oral_rat,
    inhalation,
    skin,
    eyes,
    ingestion,
    other_toxicological_information,
    environmental_risks,
    water_hazard,
    other_harmful_effects,
    disposal_instructions,
    product_disposal,
    packaging_disposal,
    transport_information,
    un_number,
    proper_shipping_name,
    packaging_group,
    other_relevant_information,
    signal_words,
    info_1,
    info_2,
    modified_information,
    alternative_sources_msd,
    history,
    status,
    ingestion_protection,
    hands,
    first_aid_hand_contact,
  } = substance.target.elements;

  const result = await http.post(`product`, {
    name: name?.value,
    document: document.value,
    status: status?.value,
    article_number: article_number?.value,
    mpz_number: mpz_number?.value,
    synonyms: synonyms?.value,
    purpose: purpose?.value,
    company: company?.value === '' ? null : company?.value,
    hazard_class: hazardClassValues,
    gevaren_mens_en_milieu: gevaren_mens_en_milieu?.value,
    product_type: product_type?.value === '' ? null : product_type?.value,
    description_components: description_components?.value,
    cas_number: cas_number?.value,
    eec_number: eec_number?.value,
    eg_number: eg_number?.value,
    iupac_name: iupac_name?.value,
    molecule_formula: molecule_formula?.value,
    hazard_statements: hazard_statements?.value === '' ? null : hazard_statements?.value.split(','),
    precautionary_statements: precautionaryStatementsValues || null,
    first_aid_inhalation: first_aid_inhalation?.value,
    first_aid_ingestion: first_aid_ingestion?.value,
    first_aid_skin_contact: first_aid_skin_contact?.value,
    first_aid_eye_contact: first_aid_eye_contact?.value,
    special_workplace_provisions: special_workplace_provisions?.value,
    suitable_extinguishing_media: suitableExtinguishingMediaValues,
    unsuitable_extinguishing_media: unsuitableExtinguishingMediaValues,
    exposure_hazards_and_protection: exposure_hazards_and_protection?.value,
    personal_precautions: personal_precautions?.value,
    environmental_precautions: environmental_precautions?.value,
    cleaning_methods: cleaning_methods?.value,
    handling: handling?.value,
    storage: storage?.value,
    specific_forms_of_use: specific_forms_of_use?.value,
    control_occupational_exposure: control_occupational_exposure?.value,
    mac_value: mac_value?.value,
    personal_protective_equipment: personal_protective_equipment?.value,
    respiratory_protection: respiratory_protection?.value,
    hand_protection: hand_protection?.value,
    eye_protection: eye_protection?.value,
    skin_protection: skin_protection?.value,
    other_control_measures: other_control_measures?.value,
    general_information: general_information?.value,
    physical_state: physical_state?.value === '' ? null : physical_state?.value,
    color: color?.value,
    odor: odor?.value,
    ph: ph?.value,
    boiling_point: boiling_point?.value,
    flash_point: flash_point?.value,
    flammability: flammability?.value,
    oxidizing_properties: oxidizing_properties?.value,
    explosion_properties: explosion_properties?.value,
    vapor_pressure: vapor_pressure?.value,
    relative_vapor_density: relative_vapor_density?.value,
    solubility: solubility?.value,
    partition_coefficient: partition_coefficient?.value,
    viscosity: viscosity?.value,
    density: density?.value,
    evaporation_rate: evaporation_rate?.value,
    water_miscibility: water_miscibility?.value,
    conductivity: conductivity?.value,
    melting_point: melting_point?.value,
    gas_group: gas_group?.value,
    self_ignition_temperature: self_ignition_temperature?.value,
    stability_and_reactivity: stability_and_reactivity?.value,
    avoid_conditions: avoid_conditions?.value,
    avoid_substances: avoid_substances?.value,
    hazardous_decomposition_products: hazardous_decomposition_products?.value,
    other_information: other_information?.value,
    lc50_inhalation_rat: lc50_inhalation_rat?.value,
    ld50_dermal_rabbit: ld50_dermal_rabbit?.value,
    ld50_oral_rat: ld50_oral_rat?.value,
    inhalation: inhalation?.value,
    skin: skin?.value,
    eyes: eyes?.value,
    ingestion: ingestion?.value,
    other_toxicological_information: other_toxicological_information?.value,
    environmental_risks: environmental_risks?.value,
    water_hazard: water_hazard?.value,
    other_harmful_effects: other_harmful_effects?.value,
    disposal_instructions: disposal_instructions?.value,
    product_disposal: product_disposal?.value,
    packaging_disposal: packaging_disposal?.value,
    transport_information: transport_information?.value,
    un_number: un_number?.value,
    proper_shipping_name: proper_shipping_name?.value,
    packaging_group: packaging_group?.value === '' ? null : packaging_group?.value,
    other_relevant_information: other_relevant_information?.value,
    signal_words: signal_words?.value,
    info_1: info_1?.value,
    info_2: info_2?.value,
    history: history?.value,
    vib_version: vib_version?.value,
    supplier_vib_date: supplier_vib_date?.value,
    vib_revision_date: vib_revision_date?.value,
    modified_information: modified_information?.value,
    alternative_sources_msd: alternative_sources_msd?.value,
    symbol_pbm: pbmValues || null,
    symbol_ghs: ghsValues || null,
    symbol_adr: adrValues || null,
    components: mixtures || null,
    ingestion_protection: ingestion_protection?.value,
    hands: hands?.value,
    first_aid_hand_contact: first_aid_hand_contact?.value,
  });

  if (!result) return null;
  return result;
};

const patchSubstance = async (
  substance: any,
  id: string,
  ghsValues: string[] | undefined,
  pbmValues: string[] | undefined,
  adrValues: string[] | undefined,
  suitableExtinguishingMediaValues: string[] | undefined,
  unsuitableExtinguishingMediaValues: string[] | undefined,
  precautionaryStatementsValues: string[] | undefined,
  hazardClassValues: string[] | undefined,
  mixtures: { component: string; percentage: string }[] | undefined,
  revisionId: number | undefined,
  isNewConcept: boolean,
) => {
  const {
    product_type,
    name,
    article_number,
    mpz_number,
    synonyms,
    purpose,
    document,
    company,
    vib_version,
    supplier_vib_date,
    vib_revision_date,
    gevaren_mens_en_milieu,
    description_components,
    cas_number,
    eec_number,
    eg_number,
    iupac_name,
    molecule_formula,
    hazard_statements,
    first_aid_inhalation,
    first_aid_ingestion,
    first_aid_skin_contact,
    first_aid_eye_contact,
    special_workplace_provisions,
    exposure_hazards_and_protection,
    personal_precautions,
    environmental_precautions,
    cleaning_methods,
    handling,
    storage,
    specific_forms_of_use,
    mac_value,
    personal_protective_equipment,
    respiratory_protection,
    hand_protection,
    eye_protection,
    skin_protection,
    control_occupational_exposure,
    other_control_measures,
    general_information,
    physical_state,
    color,
    odor,
    ph,
    boiling_point,
    flash_point,
    flammability,
    oxidizing_properties,
    explosion_properties,
    vapor_pressure,
    relative_vapor_density,
    solubility,
    partition_coefficient,
    viscosity,
    density,
    evaporation_rate,
    water_miscibility,
    conductivity,
    melting_point,
    gas_group,
    self_ignition_temperature,
    stability_and_reactivity,
    avoid_conditions,
    avoid_substances,
    hazardous_decomposition_products,
    other_information,
    lc50_inhalation_rat,
    ld50_dermal_rabbit,
    ld50_oral_rat,
    inhalation,
    skin,
    eyes,
    ingestion,
    other_toxicological_information,
    environmental_risks,
    water_hazard,
    other_harmful_effects,
    disposal_instructions,
    product_disposal,
    packaging_disposal,
    transport_information,
    un_number,
    proper_shipping_name,
    packaging_group,
    other_relevant_information,
    signal_words,
    info_1,
    info_2,
    modified_information,
    alternative_sources_msd,
    history,
    status,
    ingestion_protection,
    hands,
    first_aid_hand_contact,
  } = substance.target.elements;

  const data = {
    name: name?.value,
    document: document.value,
    status: status?.value,
    article_number: article_number?.value,
    mpz_number: mpz_number?.value,
    synonyms: synonyms?.value,
    purpose: purpose?.value,
    company: company?.value === '' ? null : company?.value,
    hazard_class: hazardClassValues,
    gevaren_mens_en_milieu: gevaren_mens_en_milieu?.value,
    product_type: product_type?.value,
    description_components: description_components?.value,
    cas_number: cas_number?.value,
    eec_number: eec_number?.value,
    eg_number: eg_number?.value,
    iupac_name: iupac_name?.value,
    molecule_formula: molecule_formula?.value,
    hazard_statements: hazard_statements?.value === '' ? null : hazard_statements?.value.split(','),
    precautionary_statements: precautionaryStatementsValues,
    first_aid_inhalation: first_aid_inhalation?.value,
    first_aid_ingestion: first_aid_ingestion?.value,
    first_aid_skin_contact: first_aid_skin_contact?.value,
    first_aid_eye_contact: first_aid_eye_contact?.value,
    special_workplace_provisions: special_workplace_provisions?.value,
    suitable_extinguishing_media: suitableExtinguishingMediaValues,
    unsuitable_extinguishing_media: unsuitableExtinguishingMediaValues,
    exposure_hazards_and_protection: exposure_hazards_and_protection?.value,
    personal_precautions: personal_precautions?.value,
    environmental_precautions: environmental_precautions?.value,
    cleaning_methods: cleaning_methods?.value,
    handling: handling?.value,
    storage: storage?.value,
    specific_forms_of_use: specific_forms_of_use?.value,
    control_occupational_exposure: control_occupational_exposure?.value,
    mac_value: mac_value?.value,
    personal_protective_equipment: personal_protective_equipment?.value,
    respiratory_protection: respiratory_protection?.value,
    hand_protection: hand_protection?.value,
    eye_protection: eye_protection?.value,
    skin_protection: skin_protection?.value,
    other_control_measures: other_control_measures?.value,
    general_information: general_information?.value,
    physical_state: physical_state?.value === '' ? null : physical_state?.value,
    color: color?.value,
    odor: odor?.value,
    ph: ph?.value,
    boiling_point: boiling_point?.value,
    flash_point: flash_point?.value,
    flammability: flammability?.value,
    oxidizing_properties: oxidizing_properties?.value,
    explosion_properties: explosion_properties?.value,
    vapor_pressure: vapor_pressure?.value,
    relative_vapor_density: relative_vapor_density?.value,
    solubility: solubility?.value,
    partition_coefficient: partition_coefficient?.value,
    viscosity: viscosity?.value,
    density: density?.value,
    evaporation_rate: evaporation_rate?.value,
    water_miscibility: water_miscibility?.value,
    conductivity: conductivity?.value,
    melting_point: melting_point?.value,
    gas_group: gas_group?.value,
    self_ignition_temperature: self_ignition_temperature?.value,
    stability_and_reactivity: stability_and_reactivity?.value,
    avoid_conditions: avoid_conditions?.value,
    avoid_substances: avoid_substances?.value,
    hazardous_decomposition_products: hazardous_decomposition_products?.value,
    other_information: other_information?.value,
    lc50_inhalation_rat: lc50_inhalation_rat?.value,
    ld50_dermal_rabbit: ld50_dermal_rabbit?.value,
    ld50_oral_rat: ld50_oral_rat?.value,
    inhalation: inhalation?.value,
    skin: skin?.value,
    eyes: eyes?.value,
    ingestion: ingestion?.value,
    other_toxicological_information: other_toxicological_information?.value,
    environmental_risks: environmental_risks?.value,
    water_hazard: water_hazard?.value,
    other_harmful_effects: other_harmful_effects?.value,
    disposal_instructions: disposal_instructions?.value,
    product_disposal: product_disposal?.value,
    packaging_disposal: packaging_disposal?.value,
    transport_information: transport_information?.value,
    un_number: un_number?.value,
    proper_shipping_name: proper_shipping_name?.value,
    packaging_group: packaging_group?.value === '' ? null : packaging_group?.value,
    other_relevant_information: other_relevant_information?.value,
    signal_words: signal_words?.value,
    info_1: info_1?.value,
    info_2: info_2?.value,
    history: history?.value,
    vib_version: vib_version?.value,
    supplier_vib_date: supplier_vib_date?.value,
    vib_revision_date: vib_revision_date?.value,
    modified_information: modified_information?.value,
    alternative_sources_msd: alternative_sources_msd?.value,
    symbol_pbm: pbmValues,
    symbol_ghs: ghsValues,
    symbol_adr: adrValues,
    components: mixtures,
    ingestion_protection: ingestion_protection?.value,
    hands: hands?.value,
    first_aid_hand_contact: first_aid_hand_contact?.value,
  };

  const result = isNewConcept ? await http.patch(`product/${id}`, data) : await http.patch(`product/${id}/${revisionId}`, data);

  if (!result) return null;
  return result;
};

export {
  postSubstance,
  patchSubstance,
  getAllSubstances,
  getSubstance,
  getSubstanceRevision,
  getSubstanceOrganisationData,
  postSubstanceOrganisationData,
  patchSubstanceOrganisationData,
  toggleSubstanceInOrganisation,
  approveSubstance,
};
