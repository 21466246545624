import React, { useRef } from 'react';
import { TabBarItem } from './types';

import './TabBar.scss';
import useBem from '../../../Hooks/useBem';

type PropType = {
  tabBarItems: TabBarItem[];
  onHandleClick: (value: string) => void;
};

const SubstancesRegisterList = (props: PropType) => {
  const [bemClassName, bemClassNames] = useBem('tabbar');
  const { tabBarItems, onHandleClick } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = (e: React.WheelEvent) => {
    const scrollAmount = 50;

    if (containerRef.current) {
      containerRef.current.scrollLeft += e.deltaY > 0 ? scrollAmount : -scrollAmount;
    }
  };

  return (
    <div className="tabbar">
      <div
        className={bemClassName('items')}
        onWheel={(e) => handleScroll(e)}
        ref={containerRef}
      >
        {tabBarItems.map((tabBarItem) => (
          <div
            key={tabBarItem.tab}
            className={bemClassNames([
              bemClassName('item'),
              bemClassName('item', 'active', tabBarItem.active === true),
            ])}
          >
            <button
              className="tabbar-button"
              type="button"
              title={tabBarItem.title}
              onClick={() => onHandleClick(tabBarItem.tab)}
            >
              {tabBarItem.title}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubstancesRegisterList;
