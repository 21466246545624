import React from 'react';
import { useParams } from 'react-router-dom';
import ItemFilterBar from './ItemFilterBar';
import ItemsList from './ItemsList';
import { checkUserRole } from '../../../../../Shared/Data/UserService';

import './OrganisationsList.scss';

type PropType = {
  handleOpenModal: (id: string) => void;
};

const ItemsListContent = (props: PropType) => {
  const { handleOpenModal } = props;
  const { hospital } = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : undefined;
  const { organisationId } = useParams();

  const renderData = () => {
    if (
      (organisationId === hospital?.id && checkUserRole('hospital_administrator')) ||
      checkUserRole('administrator')
    ) {
      return (
        <>
          <ItemFilterBar />
          <div className="list-container">
            <ItemsList handleOpenModal={(id) => handleOpenModal(id)} />
          </div>
        </>
      );
    }

    return (
      <div className="list-container">
        <p>Je hebt niet de juiste rechten</p>
      </div>
    );
  };

  return renderData();
};

export default ItemsListContent;
