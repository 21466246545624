import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { LabelInformation } from './types';

import './PdfGenerator.scss';
import PageSize from './enums';

// Create styles
const labelStyles = StyleSheet.create({
  page: {
    padding: 10,
    width: '100%',
  },
  generalInformationSection: {
    paddingBottom: 10,
    fontSize: 10,
    lineHeight: 1.25,
  },
  generalInformationList: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    gap: '5px',
  },
  generalInformationItem: {
    width: '70%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  generalInformationItemSmall: {
    width: '20%',
  },

  generalInformationItemName: {
    color: '#5776B0',
    paddingTop: 5,
  },
  tableCellImageContainer: {
    display: 'flex',
    gap: 5,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  tableCellImage: {
    width: 30,
  },
});

type PropType = {
  size: PageSize;
  amount: number;
  generalInformation: LabelInformation;
};
const PdfGeneratorLabel = (props: PropType) => {
  const { generalInformation, size, amount } = props;
  const {
    page,
    generalInformationSection,
    generalInformationList,
    generalInformationItem,
    generalInformationItemSmall,
    generalInformationItemName,
    tableCellImageContainer,
    tableCellImage,
  } = labelStyles;

  return (
    <Document>
      {Array.from({ length: amount }, (_, i) => (
        <Page
          key={i}
          size={size}
          orientation="landscape"
          style={page}
        >
          <View style={generalInformationSection}>
            <View style={generalInformationList}>
              <View style={generalInformationItem}>
                <Text style={generalInformationItemName}>{generalInformation.name}</Text>
                <Text>{generalInformation.hStatements.join(', ')}</Text>
                <Text>{generalInformation.pStatements.join(', ')}</Text>
              </View>
              <View style={[generalInformationItem, generalInformationItemSmall]}>
                <View style={tableCellImageContainer}>
                  {generalInformation.dangerSymbols.map((symbol) => (
                    symbol.path && (
                      <Image
                        key={symbol.title}
                        src={symbol.path}
                        style={tableCellImage}
                      />
                    )
                  ))}
                  <Text>{generalInformation.dangerClass}</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default PdfGeneratorLabel;
