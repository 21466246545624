import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import useBem from '../../../Hooks/useBem';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import Button from '../../../Shared/Components/Button/Button';
import { ButtonTypes } from '../../../Shared/Components/Button/enums';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import '../../../Shared/Styling/PdfView.scss';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';

const ShowManual = () => {
  if (!checkUserRight('entity.manual.GET')) {
    return <NoPermission />;
  }

  const [bemClassName, bemClassNames] = useBem('pdf-view');
  const [file, setFile] = useState<string | File | null>('/wik.pdf');
  const [numPages, setNumPages] = useState<number>();
  const { manualId } = useParams();

  pdfjs.GlobalWorkerOptions.workerSrc = new URL('/public/pdf.worker.min.js', import.meta.url).toString();

  function onFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;

    if (files && files[0]) {
      setFile(files[0] || null);
    }
  }

  // TODO: fix the any should be PDFDocumentProxy.
  function onDocumentLoadSuccess({ numPages: nextNumPages }: any) {
    setNumPages(nextNumPages);
  }

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {}}
            title="Handleiding"
            showBackButton
          />
          <div className={bemClassName()}>
            <div className={bemClassNames([bemClassName('content-top'), bemClassName('content-top', 'bordered')])}>
              <h1 className={bemClassName('header')}>{manualId}</h1>
              <Button
                theme={ButtonTypes.Solid}
                title="Downloaden"
                iconPath="/Icons/save.svg"
              />
            </div>
            <div className={bemClassName('content-middle')}>
              A
              <input
                onChange={(event) => onFileChange(event)}
                type="file"
              />
            </div>
            <div className={bemClassName('content-bottom')}>
              <Document
                file={file}
                onLoadSuccess={(ref) => onDocumentLoadSuccess(ref)}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                ))}
              </Document>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ShowManual;
