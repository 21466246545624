import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DataResult, State } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords,
} from '@progress/kendo-react-grid';
import SubstancesLoader from './SubstancesLoader';
import Checkbox from '../../../../Shared/Components/Checkbox/Checkbox';
import { SubstancesRegisterDetailInformationTabs } from '../../enums';
import { checkUserRole } from '../../../../Shared/Data/UserService';
import { toggleSubstanceInOrganisation } from '../../../../Shared/Data/SubstanceService';

type PropType = {
  onTotalUpdated: (total: number) => void;
};

const ProductName = (subCategoryProps: GridCellProps) => {
  const {
    dataItem: { id, name },
  } = subCategoryProps;
  const navigate = useNavigate();

  return (
    <td className="productname">
      <button
        type="button"
        className="stripped-button"
        onClick={() => navigate(`/productenregister/detail/${id}`)}
      >
        {name}
      </button>
    </td>
  );
};

const SupplierName = (subCategoryProps: GridCellProps) => {
  const { dataItem } = subCategoryProps;
  if (!dataItem.company) {
    return <td className="supplier">-</td>;
  }
  const { company } = dataItem;
  const { name } = company;
  return <td className="supplier">{name}</td>;
};

const IsCmr = (subCategoryProps: GridCellProps) => {
  const {
    dataItem: { cmr, zzs },
  } = subCategoryProps;

  return (
    <td className="CMR">
      {cmr && (
        <img
          src="/Icons/warningYellow.svg"
          alt="Cmr stof"
          title="Dit is een CMR stof"
        />
      )}
      {zzs && (
        <img
          src="/Icons/warningRed.svg"
          alt="Zeer zorgwekkende stof"
          title="Dit is een zeer zorgwekkende stof"
        />
      )}
    </td>
  );
};

const Actions = (subCategoryProps: GridCellProps) => {
  const { dataItem } = subCategoryProps;

  return (
    <td className="actions">
      <a
        type="button"
        className="stripped-button"
        href={`/productenregister/detail/${dataItem.id}/${SubstancesRegisterDetailInformationTabs.WorkInstructionCard}`}
      >
        WIK
      </a>
      -
      <a
        type="button"
        className="stripped-button"
        href={`/productenregister/detail/${dataItem.id}/${SubstancesRegisterDetailInformationTabs.VIB}`}
      >
        VIB
      </a>
      -
      <a
        type="button"
        className="stripped-button"
        href={`/productenregister/detail/${dataItem.id}/${SubstancesRegisterDetailInformationTabs.SourceDocument}`}
      >
        Bron
      </a>
      -
      <a
        type="button"
        className="stripped-button"
        href={`/productenregister/detail/${dataItem.id}/${SubstancesRegisterDetailInformationTabs.EHBO}`}
      >
        EHBO
      </a>
    </td>
  );
};

const SubstancesList = (props: PropType) => {
  const { onTotalUpdated } = props;
  const { search } = useParams();
  const [activeTab, setActiveTab] = useState<string[]>([]);
  const [products, setProducts] = React.useState<DataResult>({
    data: [],
    total: 0,
  });
  const [errorMessage, setErrorMessage] = useState<string[]>(['Laden...']);
  const [searchValue, setSearchValue] = useState<string | undefined>(search);
  const [dataState, setDataState] = React.useState<State>({
    take: 10,
    skip: 0,
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState({ ...e.dataState });
  };

  if (search !== searchValue) {
    setSearchValue(search);
    if (search === undefined) {
      delete dataState.filter;
    } else {
      dataState.skip = 0;
      dataState.filter = {
        logic: 'and',
        filters: [
          {
            field: 'name',
            operator: 'contains',
            value: search,
          },
        ],
      };
    }
    setDataState({ ...dataState });
  }

  const dataReceived = (productList: DataResult) => {
    onTotalUpdated(productList.total);
    setProducts(productList);
  };

  function handleToggleChecked(id: string, checked: boolean) {
    if (!checked) {
      const index = activeTab.indexOf(id);
      if (index !== -1) {
        activeTab.splice(index, 1);
        toggleSubstanceInOrganisation(id, 'disable');
        products.data.forEach((product) => {
          if (product.id === id) {
            // eslint-disable-next-line no-param-reassign
            product.enabled = false;
          }
        });
      }
    } else {
      activeTab.push(id);
      toggleSubstanceInOrganisation(id, 'enable');
    }

    setActiveTab([...activeTab]);
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const Checked = (subCategoryProps: GridCellProps) => {
    const { dataItem } = subCategoryProps;

    if (dataItem.enabled && !activeTab.find((item) => item === dataItem.id)) {
      handleToggleChecked(dataItem.id, true);
    }

    return (
      <td className="filter">
        <div>
          <Checkbox
            checkboxId={dataItem.id}
            label={activeTab.find((item) => item === dataItem.id) ? 'Aanwezig' : 'Afwezig'}
            boxChecked={!!activeTab.find((item) => item === dataItem.id)}
            onToggleChecked={(id, checked) => handleToggleChecked(id, checked)}
            showLabel
          />
        </div>
      </td>
    );
  };

  function handleError(error: string[]) {
    setErrorMessage(error);
  }

  return (
    <>
      <Grid
        sortable
        pageable
        {...dataState}
        data={products}
        onDataStateChange={dataStateChange}
      >
        <GridNoRecords>
          <div className="error-message">
            {errorMessage.length > 0 ? (
              errorMessage.map((message) => (
                <span
                  key={message}
                  className="error-message__content"
                >
                  {message}
                </span>
              ))
            ) : (
              <span className="error-message__content">Geen data gevonden...</span>
            )}
          </div>
        </GridNoRecords>

        <Column
          field="name"
          cell={ProductName}
          title="Productnaam"
          className="productname"
          headerClassName="productname"
        />
        <Column
          cell={SupplierName}
          title="Leverancier"
          className="supplier"
          headerClassName="supplier"
        />
        <Column
          field="mpz_number"
          title="MPZ nummer"
          className="mpznumber"
          headerClassName="mpznumber"
        />
        <Column
          cell={IsCmr}
          title="CMR / ZZS"
          className="CMR"
          headerClassName="CMR"
        />
        <Column
          cell={Actions}
          sortable={false}
          title="Acties"
          className="actions"
          headerClassName="actions"
        />
        {checkUserRole('hospital_administrator') && (
          <Column
            field="enabled"
            cell={Checked}
            sortable={false}
            title="Status"
            className="filter"
            headerClassName="filter"
          />
        )}
      </Grid>
      <SubstancesLoader
        dataState={dataState}
        onDataReceived={dataReceived}
        onError={(error) => handleError(error)}
      />
    </>
  );
};

export default SubstancesList;
