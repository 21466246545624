import React from 'react';
import SymbolFilterBar from './SymbolFilterBar';
import SymbolList from './SymbolList';

import './SymbolList.scss';

type PropType = {
  handleOpenModal: (id: string) => void;
};

const SymbolListContent = (props: PropType) => {
  const { handleOpenModal } = props;

  return (
    <>
      <SymbolFilterBar />
      <div className="list-container">
        <SymbolList handleOpenModal={(id) => handleOpenModal(id)} />
      </div>
    </>
  );
};

export default SymbolListContent;
