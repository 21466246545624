import http from '../../Utils/HttpClient';

const getAllExtinguishingMedia = async (url?: URL) => {
  const { data } = url ? await http.get(url.toString()) : ((await http.get(`extinguishing-media`)) as any);

  return data;
};

const getExtinguishingMedia = async (id: string) => {
  const { data } = (await http.get(`extinguishing-media/${id}`)) as any;

  return data;
};

const patchExtinguishingMedia = async (medium: { title: string }, id: string) => {
  const data = await http.patch(`extinguishing-media/${id}`, medium);

  if (!data) return null;
  return data;
};

const postExtinguishingMedia = async (medium: any) => {
  const data = await http.post(`extinguishing-media`, medium);

  if (!data) return null;
  return data;
};

export { getAllExtinguishingMedia, getExtinguishingMedia, patchExtinguishingMedia, postExtinguishingMedia };
