import React from 'react';
import { createPortal } from 'react-dom';

const LoadingPanel = () => {
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Laden</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  );

  const gridContent = document && document.querySelector('.k-grid-content');
  return gridContent ? createPortal(loadingPanel, gridContent) : loadingPanel;
};

export default LoadingPanel;
