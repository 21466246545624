import http from '../../Utils/HttpClient';
import { ComponentType } from '../../Models/Types/Component';

const getAllComponents = async (url: URL) => {
  const { data } = (await http.get(url.toString())) as any;

  return data;
};

const getComponent = async (id: string) => {
  const { data } = (await http.get(`component/${id}`)) as any;

  return data;
};

const patchComponent = async (component: ComponentType, id: string) => {
  const data = await http.patch(`component/${id}`, component);

  if (!data) return null;
  return data;
};

const postComponent = async (component: ComponentType) => {
  const data = await http.post(`component`, component);

  if (!data) return null;
  return data;
};

export { getAllComponents, getComponent, patchComponent, postComponent };
