import React, { useRef } from 'react';

import { useParams } from 'react-router-dom';
import { toODataString } from '@progress/kendo-data-query';
import getListItems from '../../../../../Shared/Data/ListService';
import LoadingPanel from '../../../../../Shared/Components/LoadingPanel/LoadingPanel';
import { ItemLoaderType } from '../../../../../Models/Types/ItemLoaderType';

const OrganisationsLoader = (props: ItemLoaderType) => {
  const { search } = useParams();
  const { dataState, onDataReceived, onError } = props;
  const base = window.__RUNTIME_CONFIG__.BACKEND_BASEURL;

  // TODO: load actual user data

  const url = new URL(`${base}hospital`);

  const lastSuccess = useRef<string>('');
  const pending = useRef<string>('');

  url.searchParams.append('page', dataState.skip ? (dataState.skip / 10).toString() : '0');
  url.searchParams.append('page_limit', '10');
  url.searchParams.append('sort', 'Name');

  if (search) {
    url.searchParams.append('search', search);
  }

  const requestDataIfNeeded = () => {
    if (pending.current || toODataString(dataState) === lastSuccess.current) {
      return;
    }
    pending.current = toODataString(dataState);
    getListItems(url)
      .catch((error) => {
        switch (error.status) {
          case 500:
            onDataReceived.call(undefined, {
              data: [],
              total: 0,
            });

            onError([
              'Er ging iets fout op onze server.',
              'Probeer het later opnieuw of neem contact op met de beheerder.',
            ]);

            break;
          case 403:
            onDataReceived.call(undefined, {
              data: [],
              total: 0,
            });

            onError(['Authenticatie is vereist.', 'Log opnieuw in of gebruik een account met meer rechten. ']);

            break;
          default:
            onError(['Er ging iets fout.', 'Probeer het later opnieuw of neem contact op met de beheerder.']);
            break;
        }
      })
      .then((response) => {
        lastSuccess.current = pending.current;
        pending.current = '';
        if (response !== undefined) {
          if (toODataString(dataState) === lastSuccess.current) {
            onDataReceived.call(undefined, {
              data: response.items,
              total: response.total,
            });

            if (response.total === 0) {
              onError(['Geen data gevonden...']);
            }
          } else {
            requestDataIfNeeded();
          }
        }
      });
  };

  requestDataIfNeeded();
  return pending.current ? <LoadingPanel /> : null;
};

export default OrganisationsLoader;
