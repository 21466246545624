import React, {useState} from 'react';
import classNames from 'classnames';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Navigation from '../../Shared/Components/Navigation/Navigation';
import SubstancesRegisterHeader from './Components/SubstancesRegisterHeader';
import SubstancesRegisterList from './Components/SubstancesList/SubstancesRegisterList';
import SubstancesRegisterSideDashboard from './Components/SubstancesRegisterSideDashboard';
import {TabBarItem} from '../../Shared/Components/TabBar/types';
import {FilterListItem} from './types';
import {ProductTypes} from './enums';
import {checkUserRight} from '../../Shared/Data/UserService';
import NoPermission from '../../Shared/Components/NoPermission/NoPermission';

const SubstancesRegister = () => {
  const [searchParams] = useSearchParams();
  const userLogin = searchParams.get('userlogin');
  if (userLogin) {
    sessionStorage.setItem('userLogin', userLogin);
  }

  if (!checkUserRight('entity.product.GET') && !userLogin) {
    return <NoPermission/>;
  }
  const [activeSideMenu, setActiveSideMenu] = useState<boolean>(localStorage.sideMenuState === 'true');
  const [tabBarItems, setTabBarItems] = useState<TabBarItem[]>(
    sessionStorage.substanceRegisterCorrectTabBarItems
      ? JSON.parse(sessionStorage.substanceRegisterCorrectTabBarItems)
      : [
        {title: 'Alle', tab: ProductTypes.All, active: true},
        {title: 'Stoffen', tab: ProductTypes.Product},
        {title: 'Mengsels', tab: ProductTypes.Preparation},
      ],
  );
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : undefined;
  const title = user?.hospital
    ? `Productenregister ${user?.hospital.name === undefined ? '' : `${user?.hospital.name}`}`
    : 'Productenregister';

  const navigate = useNavigate();

  function handleTabBarClick(selectedTab: string) {
    tabBarItems.forEach((tabBarItem) => {
      // eslint-disable-next-line no-param-reassign
      tabBarItem.active = tabBarItem.tab === selectedTab;
    });
    if (sessionStorage.substanceRegisterCorrectTabBarItems) {
      sessionStorage.substanceRegisterCorrectTabBarItems = JSON.stringify(tabBarItems);
    } else {
      sessionStorage.setItem('substanceRegisterCorrectTabBarItems', JSON.stringify(tabBarItems));
    }
    setTabBarItems([...tabBarItems]);
    window.location.reload();
  }

  function handleSearchRequest(searchValue: string) {
    if (searchValue === '') {
      navigate(`/productenregister/`);
      window.location.reload();
    } else {
      navigate(`/productenregister/search/${encodeURIComponent(searchValue)}`);
    }
  }

  function handleNewFilterList(newFilterList: FilterListItem[]) {
    if (sessionStorage.filterList) {
      sessionStorage.filterList = JSON.stringify(newFilterList);
    } else {
      sessionStorage.setItem('filterList', JSON.stringify(newFilterList));
    }
    navigate(0);
  }

  function toggleSideMenu(newSideMenuState: boolean) {
    if (localStorage.sideMenuState) {
      localStorage.sideMenuState = (!activeSideMenu).toString();
    } else {
      localStorage.setItem('sideMenuState', (!activeSideMenu).toString());
    }
    setActiveSideMenu(newSideMenuState);
  }

  return (
    <>
      <Navigation/>
      <div
        className={classNames('content-container', 'content-container__with-sidemenu', {
          'content-container__with-sidemenu--active': activeSideMenu,
        })}
      >
        <section>
          <SubstancesRegisterHeader
            title={title}
            tabBarItems={tabBarItems}
            onHandleTabBarClick={(selectedTab) => handleTabBarClick(selectedTab)}
            onHandleSearchRequest={(searchValue) => handleSearchRequest(searchValue)}
            showSearchBar
          />
          <SubstancesRegisterList onHandleNewFilterList={(newFilterList) => handleNewFilterList(newFilterList)}/>
        </section>
        <section className="variable-width">
          <SubstancesRegisterSideDashboard
            activeSideMenu={activeSideMenu}
            onToggleSideMenu={(state) => toggleSideMenu(state)}
          />
        </section>
      </div>
    </>
  );
};

export default SubstancesRegister;
