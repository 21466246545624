import http from '../../Utils/HttpClient';

const getAllManuals = async (url?: URL) => {
  const { data } = url ? await http.get(url.toString()) : ((await http.get(`manual`)) as any);

  return data;
};

const getManual = async (id: string) => {
  const { data } = (await http.get(`manual/${id}`)) as any;

  return data;
};

const patchManual = async (manual: { title: string; text: string; file: string }, id: string) => {
  const data = await http.patch(`manual/${id}`, manual);

  if (!data) return null;
  return data;
};

const postManual = async (manual: { title: string; text: string; file: string }) => {
  const data = await http.post(`manual`, manual);

  if (!data) return null;
  return data;
};

export { getAllManuals, getManual, patchManual, postManual };
