import React, {useCallback, useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';
import useBem from '../../../Hooks/useBem';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import InputField from '../../../Shared/Components/Form/InputField';
import Button from '../../../Shared/Components/Button/Button';
import {ButtonTypes} from '../../../Shared/Components/Button/enums';
import SelectField from '../../../Shared/Components/Form/SelectField';
import {StatementResponse} from '../../../Models/Types/Statement';
import {generateStatementResponseSelectFieldList} from '../../../Shared/Functions/generateStatements';
import useHazardStatement from '../../../Shared/Hooks/useHazardStatement';
import {HazardClass} from '../../../Models/Types/HazardClass';
import useHazardClass from '../../../Shared/Hooks/useHazardClass';
import handleError from '../../../Shared/Functions/handleError';
import {patchHazardClass} from '../../../Shared/Data/HazardClassService';

import '../../../Shared/Styling/EditAddGrid.scss';
import {checkUserRight} from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';
import {FillUpSize} from '../../../Shared/Components/Form/enums';
import FillUpField from '../../../Shared/Components/Form/FillUpField';
import {SelectItem} from "../../../Models/Types/SelectItem";

const EditHazardClass = () => {
  if (!checkUserRight('entity.hazard_class.PATCH')) {
    return <NoPermission/>;
  }

  const [bemClassName] = useBem('edit-add-grid');
  const {id} = useParams();
  const {data: hazardClassData, isLoading} = useHazardClass(id);
  const {data: hazardStatements, isLoading: statementsLoading} = useHazardStatement();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  // Get the selected hazards and parse it to selected items.
  let selectedHazards: SelectItem[] = [];
  if (hazardClassData) {
    selectedHazards = (hazardClassData as HazardClass).hazard_statements.map((item) => ({
      'value': item.id,
      'label': item.code
    }));
  }

  const submit = useCallback(async (e: any) => {
    const {hazard_statements, title} = e.target;
    const hazardStatementsArray: string[] = []

    if (hazard_statements.length) {
      hazard_statements.forEach((item: any) => {
        hazardStatementsArray.push(item.value);
      });
    }

    const editedHazardClass = {
      title: title.value,
      hazard_statements: hazardStatementsArray,
    };

    patchHazardClass(editedHazardClass as { title: string; hazard_statements: string[] }, id!)
      .catch((err) => {
        setSuccessMessage(undefined);
        setErrorMessage(handleError(err));
      })
      .then((result: any) => {
        if (result) {
          setErrorMessage(undefined);
          setSuccessMessage('Geverarenklasse succesvol aangepast');

          queryClient.invalidateQueries({queryKey: [`hazardclass-${id}`]});
        }
      });
  }, []);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await submit(e);
  };

  return (
    <>
      <Navigation/>
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {
            }}
            title="Gevarenklasse aanpassen"
            showBackButton
          />
          <div className={bemClassName()}>
            <div className={bemClassName('content')}>
              <h1 className={bemClassName('header')}>Gegevens</h1>

              {isLoading || statementsLoading ? (
                <p>Loading...</p>
              ) : (
                <form
                  method="post"
                  onSubmit={(e) => submitHandler(e)}
                >
                  <ul className={bemClassName('input-list')}>
                    <InputField
                      name="title"
                      value={(hazardClassData as HazardClass).title}
                      label="Naam gevarenklasse"
                      placeholder="Naam gevarenklasse"
                    />
                    <SelectField
                      name="hazard_statements"
                      value={selectedHazards}
                      options={generateStatementResponseSelectFieldList(hazardStatements as StatementResponse)}
                      label="H-Code"
                      allowMultiSelect
                    />

                    <FillUpField size={FillUpSize.Small}/>

                    {errorMessage && <p className={bemClassName('error-message')}>{errorMessage}</p>}
                    {successMessage && <p className={bemClassName('success-message')}>{successMessage}</p>}

                    <Button
                      submit
                      theme={ButtonTypes.Solid}
                      title="Gevarenklasse aanpassen"
                    />
                  </ul>
                </form>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default EditHazardClass;
