import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ManualResponseType, ManualType } from '../../Models/Types/ManualType';
import { getAllManuals, getManual } from '../Data/ManualService';

const useManual = (id?: string): UseQueryResult<ManualResponseType | ManualType, Error> =>
  useQuery({
    queryKey: [`manual-${id}`],
    queryFn: () => (id ? getManual(id) : getAllManuals()),
  });
export default useManual;
