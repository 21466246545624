import http from '../../Utils/HttpClient';

const Login = async (loginData: { name: string; pass: string }) => {
  const {data} = (await http.post(`session/login?_format=json`, loginData)) as any;
  return data;
};

const LoginWithUsername = async (loginData: { name: string }) => {
  const {data} = (await http.post(`session/login-by-name?_format=json`, loginData)) as any;
  return data;
};

const Logout = () => {
  localStorage.removeItem('userToken');
  localStorage.removeItem('user');
  sessionStorage.removeItem('filterList');
  sessionStorage.removeItem('substanceRegisterCorrectTabBarItems');
  window.location.href = '/';
};

export {Login, LoginWithUsername, Logout};
