import React from 'react';
import useBem from '../../../Hooks/useBem';
import { FillUpSize } from './enums';

import './InputField.scss';

type PropType = {
  size?: FillUpSize;
};

const FillUpField = (props: PropType) => {
  const { size } = props;
  const [bemClassName, bemClassNames] = useBem('form-field');

  return <div className={bemClassNames([bemClassName(), bemClassName(size)])} />;
};

FillUpField.defaultProps = {
  size: FillUpSize.Default,
};

export default FillUpField;
