import React from 'react';

import './DataCard.scss';

type PropType = {
  number: number;
  explanation: string;
};

const DataCard = (props: PropType) => {
  const { number, explanation } = props;

  return (
    <div className="data-card">
      <div className="data-card__number">{number}</div>
      <p className="data-card__explanation">{explanation}</p>
    </div>
  );
};

export default DataCard;
