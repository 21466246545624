import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getSubstance } from '../Data/SubstanceService';

import { Substance } from '../../Models/Types/Substance';

const useSubstance = (id: string): UseQueryResult<Substance, Error> =>
  useQuery({
    queryKey: [`substance-${id}`],
    queryFn: () => (id ? getSubstance(id) : null),
  });

export default useSubstance;
