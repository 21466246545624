import React from 'react';
import PackagingGroupsFilterBar from './PackagingGroupsFilterBar';
import PackagingGroupsList from './PackagingGroupsList';

import './PackagingGroupsList.scss';

type PropType = {
  handleOpenModal: (id: string) => void;
};

const PackagingGroupsListContent = (props: PropType) => {
  const { handleOpenModal } = props;
  return (
    <>
      <PackagingGroupsFilterBar />
      <div className="list-container">
        <PackagingGroupsList handleOpenModal={(id) => handleOpenModal(id)} />
      </div>
    </>
  );
};

export default PackagingGroupsListContent;
