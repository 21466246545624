import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';

import './Modal.scss';
import useBem from '../../../Hooks/useBem';
import { ButtonTypes } from '../Button/enums';

type PropType = {
  showModal?: boolean;
  modalTitle: string;
  modalDescription: string;
  modalButtonRightText: string;
  modalButtonLeftText: string;
  subText?: { __html: string };
  rightButtonEnabled?: boolean;
  onLeftAction: () => void;
  onRightAction?: () => void;
  onHandleClose: () => void;
  children?: React.ReactNode;
};
const Modal = (props: PropType) => {
  const {
    showModal,
    modalTitle,
    modalDescription,
    modalButtonRightText,
    modalButtonLeftText,
    subText,
    rightButtonEnabled,
    onRightAction,
    onLeftAction,
    onHandleClose,
    children
  } = props;
  const [modalActive, setModalActive] = useState(showModal);
  const [bemClassName] = useBem('modal');

  useEffect(() => {
    setModalActive(showModal);
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();

        onHandleClose();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [showModal]);

  return (
    <div>
      {modalActive && (
        <div className={bemClassName('background')}>
          <div className={bemClassName('content')}>
            <div className={bemClassName('close')}>
              <button
                className="stripped-button"
                type="button"
                onClick={() => onHandleClose()}
              >
                <img
                  src="/Icons/closeIcon.svg"
                  alt="Sluiten"
                />
              </button>
            </div>
            <h2>{modalTitle}</h2>
            <p>{modalDescription}</p>
            {children}
            <div className={bemClassName('buttons')}>
              <Button
                title={modalButtonLeftText}
                onHandleClick={() => onLeftAction!()}
                theme={ButtonTypes.BorderedGreen}
              />
              {rightButtonEnabled && (
                <Button
                  title={modalButtonRightText}
                  onHandleClick={() => onRightAction!()}
                  theme={ButtonTypes.Solid}
                />
              )}
            </div>
            {subText && (
              <p
                className="subtext"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={subText}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

Modal.defaultProps = {
  showModal: false,
  rightButtonEnabled: false,
  subText: undefined,
  onRightAction: () => {},
  children: undefined
};

export default Modal;
