import { StyleSheet } from '@react-pdf/renderer';

const styling = StyleSheet.create({
  page: {
    padding: 30,
    paddingBottom: 45,
  },
  titleSection: {
    paddingBottom: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 20,
    textTransform: 'uppercase',
  },
  subtitle: {
    fontSize: 14,
    color: '#96A5B5',
  },
  image: {
    maxWidth: 132,
    maxHeight: 60,
  },
  sectionHeader: {
    backgroundColor: '#F0F3F6',
    fontSize: 8,
    textTransform: 'uppercase',
    display: 'flex',
    flexDirection: 'row',
  },
  sectionHeaderText: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
  },

  genaralInformationSection: {
    paddingBottom: 10,
    fontSize: 10,
    lineHeight: 1.25,
  },
  generalInformationList: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    padding: 11,
    gap: '5px 20px',
    borderBottom: '1px solid #C3D0DC',
  },
  generalInformationItem: {
    width: '30%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  generalInformationItemName: {
    color: '#5776B0',
  },
  generalInformationBottom: {
    paddingTop: 12,
    paddingLeft: 12,
    paddingRight: 12,
    display: 'flex',
    flexDirection: 'row',
    gap: 40,
  },
  generalInformationExtendedInformation: {
    flex: 2,
  },
  generalInformationCompositionElements: {
    flex: 1,
  },
  purposesOfUseStyle: {
    paddingTop: 6,
    fontSize: 8,
  },
  compoundList: {
    paddingTop: 6,
    display: 'flex',
    fontSize: 8,
  },
  compoundItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  compoundItemName: {
    width: '40%',
  },
  compoundItemValue: {
    width: '55%',
  },

  tableContainer: {
    display: 'flex',
    borderBottom: '1px solid #C3D0DC',
    borderLeft: '1px solid #C3D0DC',
    borderRight: '1px solid #C3D0DC',
  },

  tableSection: {
    paddingTop: 20,
    paddingBottom: 10,
    fontSize: 10,
    lineHeight: 1.25,
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableCell: {
    padding: 10,
  },
  tableCellBorder: {
    borderRight: '1px solid #C3D0DC',
  },
  tableCellLarge: {
    flex: 3,
  },
  tableCellMedium: {
    flex: 2,
  },
  tableCellSmall: {
    flex: 1,
  },
  tableCellTitle: {
    color: '#5776B0',
    fontSize: 10,
  },
  tableCellText: {
    fontSize: 8,
  },
  tableCellContent: {
    fontSize: 8,
  },
  tableCellImageContainer: {
    display: 'flex',
    gap: 5,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  tableCellImage: {
    maxWidth: 35,
    maxHeight: 35,
    marginTop: 6,
  },

  codeList: {
    paddingTop: 6,
    display: 'flex',
    fontSize: 8,
  },
  codeItem: {
    display: 'flex',
    gap: 20,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  codeItemName: {
    width: 35,
  },
  codeItemValue: {
    width: 200,
  },
  noteText: {
    padding: 12,
  },

  footNote: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    width: '100%',
    textAlign: 'right',
    right: 30,
    bottom: 30,
  },
  footNoteDate: {
    color: '#96A5B5',
    fontSize: 8,
  },
});

export default styling;
