import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBem from '../../../Hooks/useBem';
import Navigation from '../../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import InputField from '../../../Shared/Components/Form/InputField';
import { ButtonTypes } from '../../../Shared/Components/Button/enums';
import Button from '../../../Shared/Components/Button/Button';
import handleError from '../../../Shared/Functions/handleError';
import { postRoom } from '../../../Shared/Data/RoomService';

import '../../../Shared/Styling/EditAddGrid.scss';
import { checkUserRight } from '../../../Shared/Data/UserService';
import NoPermission from '../../../Shared/Components/NoPermission/NoPermission';

const AddRoom = () => {
  if (!checkUserRight('entity.room.POST')) {
    return <NoPermission />;
  }

  const [bemClassName] = useBem('edit-add-grid');
  const { organisationId, departmentId } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const submit = useCallback(async (e: any) => {
    const { value } = e.target.name;
    const room = {
      name: value,
      department: departmentId,
    };
    postRoom(
      room as {
        name: string;
        department: string;
      },
    )
      .catch((err) => setErrorMessage(handleError(err)))
      .then((result: any) => {
        if (result) {
          navigate(`/beheer/organisatiestructuur/${organisationId}/afdelingen/${departmentId}`);
        }
      });
  }, []);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await submit(e);
  };

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {}}
            title="Kamer toevoegen"
            showBackButton
          />
          <div className={bemClassName()}>
            <div className={bemClassName('content')}>
              <h1 className={bemClassName('header')}>Gegevens</h1>

              <form
                method="post"
                onSubmit={(event) => submitHandler(event)}
              >
                <ul className={bemClassName('input-list')}>
                  <InputField
                    name="name"
                    label="Naam kamer"
                    placeholder="Naam kamer"
                  />

                  {errorMessage && <p className={bemClassName('error-message')}>{errorMessage}</p>}

                  <Button
                    submit
                    theme={ButtonTypes.Solid}
                    title="Kamer toevoegen"
                  />
                </ul>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AddRoom;
