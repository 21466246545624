import React, { useCallback, useState } from 'react';
import Navigation from '../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import useBem from '../../Hooks/useBem';
import InputField from '../../Shared/Components/Form/InputField';
import TextAreaInputField from '../../Shared/Components/Form/TextAreaInputField';
import Button from '../../Shared/Components/Button/Button';
import TextAreaSizes from '../../Shared/Components/Form/enums';
import { ButtonTypes } from '../../Shared/Components/Button/enums';
import handleError from '../../Shared/Functions/handleError';
import postContactForm from '../../Shared/Data/ContactFomService';

import './Contact.scss';
import { checkUserRight } from '../../Shared/Data/UserService';
import NoPermission from '../../Shared/Components/NoPermission/NoPermission';

const Contact = () => {
  if (!checkUserRight('contact.POST')) {
    return <NoPermission />;
  }

  const [bemClassName] = useBem('contact');
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  const submit = useCallback(async (e: any) => {
    const { name, organisation, email, telephone, message } = e.target;
    const contactform = {
      name: name.value,
      organisation: organisation.value,
      email: email.value,
      telephone: telephone.value,
      message: message.value,
    };

    postContactForm(
      contactform as {
        name: string;
        organisation: string;
        email: string;
        telephone: string;
        message: string;
      },
    )
      .catch((err) => {
        setSuccessMessage(undefined);
        setErrorMessage(handleError(err));
      })
      .then((result: any) => {
        if (result && !errorMessage) {
          setErrorMessage('');
          setSuccessMessage('Bericht succesvol verstuurd');
        }
      });
  }, []);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await submit(e);
  };

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {}}
            title="We helpen u graag!"
          />
          <div className={bemClassName()}>
            <div className={bemClassName('content')}>
              <h1 className={bemClassName('header')}>Contactformulier</h1>
              <p className={bemClassName('text')}>
                Heeft u een vraag of opmerking? Neem dan contact met ons op via onderstaand formulier.
              </p>

              <form
                method="post"
                onSubmit={(e) => submitHandler(e)}
              >
                <ul className={bemClassName('input-list')}>
                  {successMessage !== undefined && <p className={bemClassName('success-message')}>{successMessage}</p>}
                  {errorMessage !== '' && (
                    <>
                      <InputField
                        name="name"
                        label="Uw naam"
                        placeholder="Uw naam"
                      />
                      <InputField
                        name="organisation"
                        label="Organisatie"
                        placeholder="Organisatie"
                      />
                      <InputField
                        name="email"
                        label="E-mailadres"
                        placeholder="E-mailadres"
                        type="email"
                      />
                      <InputField
                        name="telephone"
                        label="Telefoon"
                        placeholder="Telefoonnummer"
                        type="phone"
                      />
                      <TextAreaInputField
                        name="message"
                        label="Uw bericht"
                        width={TextAreaSizes.Full}
                      />
                    </>
                  )}

                  {errorMessage && <p className={bemClassName('error-message')}>{errorMessage}</p>}

                  <Button
                    submit
                    theme={ButtonTypes.Solid}
                    title="Versturen"
                  />
                </ul>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Contact;
