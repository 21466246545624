enum TextAreaSizes {
  Default = 'default',
  Full = 'full',
}

export enum FillUpSize {
  Default = 'default',
  Small = 'small',
  ExtraSmall = 'xSmall',
  Large = 'large',
  ExtraLarge = 'extra-large',
}

export enum FileTypes {
  Image = '.jpg, .png, .svg',
  Pdf = '.pdf',
}

export default TextAreaSizes;
