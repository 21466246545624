import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Phase, PhaseResponse } from '../../Models/Types/Phase';
import { getAllPhases, getPhase } from '../Data/PhaseService';

const usePhase = (id?: string): UseQueryResult<PhaseResponse | Phase, Error> =>
  useQuery({
    queryKey: [`phase-${id}`],
    queryFn: () => (id ? getPhase(id) : getAllPhases()),
  });
export default usePhase;
