import { Statement, StatementResponse } from '../../Models/Types/Statement';
import { PdfListItem } from '../Components/PdfGenerator/types';
import { SelectItem } from '../../Models/Types/SelectItem';
import { HazardClass } from '../../Models/Types/HazardClass';

const generateStatements = (statements: Statement[]) => {
  const statementList: PdfListItem[] = [];
  statements?.forEach((statement) => {
    statementList.push({ name: statement.code, value: statement.description });
  });

  return statementList;
};

function generateStatementResponseSelectFieldList(data: StatementResponse) {
  const list: SelectItem[] = [];
  data?.items?.forEach((statement: any) => {
    list.push({
      label: statement.code,
      value: statement.id,
    });
  });
  return list;
}

function generateStatementSelectFieldList(data: Statement[]) {
  const list: SelectItem[] = [];
  data?.forEach((statement: any) => {
    list.push({
      label: statement.code,
      value: statement.id,
    });
  });
  return list;
}

function generateClassSelectFieldList(data: HazardClass[]) {
  const list: SelectItem[] = [];
  data?.forEach((statement: any) => {
    list.push({
      label: statement.title,
      value: statement.id,
    });
  });
  return list;
}

const generateFlatStatements = (statements: Statement[], short?: boolean) => {
  const statementList: string[] = [];
  statements?.forEach((statement) => {
    if (short) {
      statementList.push(statement.code);
    } else {
      statementList.push(`${statement.code} - ${statement.description}`);
    }
  });

  return statementList;
};

export {
  generateStatements,
  generateStatementResponseSelectFieldList,
  generateStatementSelectFieldList,
  generateFlatStatements,
  generateClassSelectFieldList,
};
