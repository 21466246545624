import React from 'react';
import HazardClassesFilterBar from './HazardClassesFilterBar';
import HazardClassesList from './HazardClassesList';

import './HazardClassesList.scss';

type PropType = {
  handleOpenModal: (id: string) => void;
};
const HazardClassesListContent = (props: PropType) => {
  const { handleOpenModal } = props;

  return (
    <>
      <HazardClassesFilterBar />
      <div className="list-container">
        <HazardClassesList handleOpenModal={(id) => handleOpenModal(id)} />
      </div>
    </>
  );
};

export default HazardClassesListContent;
