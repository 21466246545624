import React from 'react';
import { useLocation, useRouteError } from 'react-router-dom';
import Navigation from '../../Shared/Components/Navigation/Navigation';
import TitleWithSearchbar from '../../Shared/Components/TitleWithSearchbar/TitleWithSearchbar';
import useBem from '../../Hooks/useBem';

import './Error.scss';

const ErrorPage = () => {
  const [bemClassName] = useBem('error');
  const { pathname } = useLocation();
  const error: any = useRouteError();

  const { statusText, message } = error;

  return (
    <>
      <Navigation />
      <div className="content-container">
        <section>
          <TitleWithSearchbar
            onHandleEnterKeyDown={() => {}}
            title="Foutmelding"
            showBackButton
          />
          <div className={bemClassName()}>
            <div className={bemClassName('content')}>
              <h1 className={bemClassName('header')}>Error.</h1>
              <p className={bemClassName('text')}>
                Er is een fout opgetreden op <b>{pathname}</b>. Je zou ons erg helpen om een screenshot te maken van
                deze foutmelding en contact op te nemen met de beheerder.
              </p>

              <p className={bemClassName('text')}>
                <b>Foutmelding</b>
                <br />
                <span className={bemClassName('text', 'error-msg')}>{statusText || message}</span>
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ErrorPage;
