import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import styling from './Components/WikStyling';
import ListItem from './Components/ListItem';
import { PdfActionListItem, PdfFirePreventionListItem, PdfListItem } from './types';
import { SymbolListItemType } from '../CollapsableContent/types';

import './PdfGenerator.scss';

import { HazardClass } from '../../../Models/Types/HazardClass';
import { PackagingGroup } from '../../../Models/Types/PackagingGroup';

type PropType = {
  selectedOptions: string[];
  amount: number;
  generalInofrmationItems: PdfListItem[];
  compoundItems: PdfListItem[];
  purposesOfUse: string;
  productTitle: string;
  dangerSymbols: SymbolListItemType[];
  pbmSymbols: SymbolListItemType[];
  adrSymbols: SymbolListItemType[];
  hazardClasses: HazardClass[];
  hStatementList: PdfListItem[];
  pStatementList: PdfListItem[];
  actionList: PdfActionListItem[];
  personalPrecautions: string;
  cleaningMethods: string;
  fireInformationList: PdfFirePreventionListItem[];
  handlingList: string[];
  storageList: string[];
  lastModifiedDate: string;
  environmentalRisks: string;
  environmentalPrecautions: string;
  otherInformation: string;
  unNumber: string;
  properShippingName: string;
  packagingGroup: PackagingGroup;
};
const WorkInstructionCardPDF = (props: PropType) => {
  const {
    selectedOptions,
    amount,
    generalInofrmationItems,
    compoundItems,
    purposesOfUse,
    dangerSymbols,
    pbmSymbols,
    adrSymbols,
    hStatementList,
    pStatementList,
    actionList,
    personalPrecautions,
    cleaningMethods,
    fireInformationList,
    handlingList,
    storageList,
    lastModifiedDate,
    productTitle,
    environmentalRisks,
    environmentalPrecautions,
    otherInformation,
    hazardClasses,
    unNumber,
    properShippingName,
    packagingGroup,
  } = props;

  const {
    page,
    image,
    titleSection,
    title,
    subtitle,
    genaralInformationSection,
    sectionHeader,
    sectionHeaderText,
    generalInformationList,
    generalInformationItem,
    generalInformationItemName,
    generalInformationBottom,
    generalInformationExtendedInformation,
    purposesOfUseStyle,
    generalInformationCompositionElements,
    compoundList,
    compoundItem,
    compoundItemName,
    compoundItemValue,
    tableContainer,
    tableSection,
    tableRow,
    tableCell,
    tableCellBorder,
    tableCellLarge,
    tableCellMedium,
    tableCellSmall,
    tableCellTitle,
    tableCellText,
    tableCellImageContainer,
    tableCellImage,
    codeList,
    codeItem,
    codeItemName,
    codeItemValue,
    noteText,
    footNote,
    footNoteDate,
  } = styling;

  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : undefined;
  const organisation = user?.hospital;

  return (
    <Document>
      {Array.from({ length: amount }, (_, i) => (
        <Page
          key={i}
          size="A4"
          style={page}
        >
          <View
            fixed
            style={titleSection}
          >
            <View>
              <Text style={title}>Werkinstructiekaart</Text>
              <Text style={subtitle}>{productTitle}</Text>
            </View>
            <View>
              <Image
                src={
                  organisation?.logo?.styles?.png !== undefined ? organisation.logo.styles.png : '/Images/mpzLogo.png'
                }
                style={image}
              />
            </View>
          </View>
          <View style={genaralInformationSection}>
            <View style={sectionHeader}>
              <Text style={sectionHeaderText}>Productinformatie</Text>
            </View>
            <View style={generalInformationList}>
              {generalInofrmationItems.map((item) => (
                <View
                  key={item.name}
                  style={generalInformationItem}
                >
                  <Text style={generalInformationItemName}>{item.name}</Text>
                  <Text>{item.value}</Text>
                </View>
              ))}
            </View>
            <View style={generalInformationBottom}>
              <View style={generalInformationExtendedInformation}>
                <Text style={generalInformationItemName}>Gebruikersdoeleinden</Text>
                <Text style={purposesOfUseStyle}>{purposesOfUse}</Text>
              </View>
              <View style={generalInformationCompositionElements}>
                <Text style={generalInformationItemName}>Samenstelling</Text>
                <View style={compoundList}>
                  {compoundItems.map((item) => (
                    <View
                      key={item.name}
                      style={compoundItem}
                    >
                      <Text style={compoundItemName}>{item.name}</Text>
                      <Text style={compoundItemValue}>{item.value}</Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          </View>
          <View style={tableSection}>
            <View
              style={sectionHeader}
              wrap={false}
              fixed
            >
              <Text style={sectionHeaderText}>Wettelijke informatie</Text>
            </View>
            <View
              wrap={false}
              style={tableContainer}
            >
              <View style={tableRow}>
                <View style={[tableCell, tableCellMedium, tableCellBorder]}>
                  <Text style={tableCellTitle}>Gevarenklassen</Text>
                  <View style={codeList}>
                    {hazardClasses.map((item) => (
                      <View
                        key={item.title}
                        style={codeItem}
                      >
                        <Text style={codeItemValue}>{item.title}</Text>
                      </View>
                    ))}
                  </View>
                  <View style={tableCellImageContainer}>
                    {dangerSymbols.map((symbol) => (
                      <Image
                        key={symbol.title}
                        src={symbol.path}
                        style={tableCellImage}
                      />
                    ))}
                  </View>
                </View>
                <View style={[tableCell, tableCellLarge, tableCellBorder]}>
                  <Text style={tableCellTitle}>H-zinnen</Text>
                  <View style={codeList}>
                    {hStatementList.map((item) => (
                      <View
                        key={item.name}
                        style={codeItem}
                      >
                        <Text style={codeItemName}>{item.name}</Text>
                        <Text style={codeItemValue}>{item.value}</Text>
                      </View>
                    ))}
                  </View>
                </View>
                <View style={[tableCell, tableCellSmall]}>
                  <Text style={tableCellTitle}>P-zinnen</Text>
                  <View style={codeList}>
                    {pStatementList.map((item) => (
                      <View
                        key={item.name}
                        style={codeItem}
                      >
                        <Text style={codeItemName}>{item.name}</Text>
                      </View>
                    ))}
                  </View>
                </View>
              </View>
            </View>
          </View>
          {pbmSymbols.length > 0 && (
            <View style={tableSection}>
              <View
                style={sectionHeader}
                wrap={false}
                fixed
              >
                <Text style={sectionHeaderText}>Persoonlijke beschermingsmiddelen</Text>
              </View>
              <View
                wrap={false}
                style={tableContainer}
              >
                <View style={tableRow}>
                  <View style={[tableCell, tableCellMedium]}>
                    <View style={tableCellImageContainer}>
                      {pbmSymbols.map((symbol) => (
                        <Image
                          key={symbol.title}
                          src={symbol.path}
                          style={tableCellImage}
                        />
                      ))}
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )}
          {selectedOptions.includes('1') && (
            <View style={tableSection}>
              <View
                fixed
                style={sectionHeader}
                wrap={false}
              >
                <Text style={[sectionHeaderText, tableCellMedium]}>Risico + Symptomen</Text>
                <Text style={[sectionHeaderText, tableCellMedium]}>Preventie</Text>
                <Text style={[sectionHeaderText, tableCellMedium]}>EHBO</Text>
              </View>
              {actionList.map((item) => (
                <View
                  wrap={false}
                  style={tableContainer}
                >
                  <View
                    wrap={false}
                    style={tableRow}
                  >
                    <View style={[tableCell, tableCellMedium, tableCellBorder]}>
                      <Text style={tableCellTitle}>{item.riskAndSymptom.name}</Text>
                      <Text style={tableCellText}>{item.riskAndSymptom.value}</Text>
                    </View>
                    <View style={[tableCell, tableCellMedium, tableCellBorder]}>
                      <View style={codeList}>
                        {item.prevention.preventionList.map((listItem) => (
                          <ListItem text={listItem} />
                        ))}
                        {item.prevention.symbol && (
                          <Image
                            src={item.prevention.symbol.path}
                            style={tableCellImage}
                          />
                        )}
                      </View>
                    </View>
                    <View style={[tableCell, tableCellMedium]}>
                      <View style={codeList}>
                        {item.EHBO.map((ehboItem) => (
                          <ListItem text={ehboItem} />
                        ))}
                      </View>
                    </View>
                  </View>
                </View>
              ))}
            </View>
          )}
          {selectedOptions.includes('2') && (
            <View style={tableSection}>
              <View
                style={sectionHeader}
                wrap={false}
                fixed
              >
                <Text style={[sectionHeaderText, tableCellMedium]}>Bij brand</Text>
              </View>
              {fireInformationList.map((item) => (
                <View
                  wrap={false}
                  style={tableContainer}
                >
                  <View style={tableRow}>
                    <View
                      wrap={false}
                      style={[tableCell, tableCellMedium, tableCellBorder]}
                    >
                      <Text style={tableCellTitle}>Gevaren</Text>
                      <Text style={tableCellText}>{item.dangers}</Text>
                    </View>
                    <View style={[tableCell, tableCellMedium, tableCellBorder]}>
                      <View style={codeList}>
                        <Text style={tableCellTitle}>Geschikte blusmiddelen</Text>
                        {item.suitable.map((listItem) => (
                          <ListItem text={listItem} />
                        ))}
                      </View>
                    </View>
                    <View
                      wrap={false}
                      style={[tableCell, tableCellMedium]}
                    >
                      <View style={codeList}>
                        <Text style={tableCellTitle}>Ongeschikte blusmiddelen</Text>
                        {item.unsuitable.map((listItem) => (
                          <ListItem text={listItem} />
                        ))}
                      </View>
                    </View>
                  </View>
                </View>
              ))}
            </View>
          )}
          {selectedOptions.includes('3') && (
            <View style={tableSection}>
              <View
                style={sectionHeader}
                wrap={false}
                fixed
              >
                <Text style={sectionHeaderText}>Bij calamiteiten</Text>
              </View>

              <View
                wrap={false}
                style={tableContainer}
              >
                <View style={tableRow}>
                  <View
                    wrap={false}
                    style={[tableCell]}
                  >
                    <Text style={tableCellTitle}>Persoonlijke maatregelen</Text>
                    <Text style={tableCellText}>{personalPrecautions}</Text>
                    <Text style={tableCellText}>&nbsp;</Text>
                    <Text style={tableCellTitle}>Opruimen</Text>
                    <Text style={tableCellText}>{cleaningMethods}</Text>
                  </View>
                </View>
              </View>
            </View>
          )}
          {selectedOptions.includes('4') && (
            <View style={tableSection}>
              <View
                style={sectionHeader}
                wrap={false}
                fixed
              >
                <Text style={sectionHeaderText}>Milieu</Text>
              </View>

              <View
                wrap={false}
                style={tableContainer}
              >
                <View style={tableRow}>
                  <View
                    wrap={false}
                    style={[tableCell]}
                  >
                    <Text style={tableCellTitle}>Risico</Text>
                    <Text style={tableCellText}>{environmentalRisks}</Text>
                    <Text style={tableCellText}>&nbsp;</Text>
                    <Text style={tableCellTitle}>Voorzorg</Text>
                    <Text style={tableCellText}>{environmentalPrecautions}</Text>
                  </View>
                </View>
              </View>
            </View>
          )}
          {selectedOptions.includes('5') && (
            <View style={tableSection}>
              <View
                style={sectionHeader}
                wrap={false}
                fixed
              >
                <Text style={sectionHeaderText}>Opslag</Text>
              </View>

              <View
                wrap={false}
                style={tableContainer}
              >
                <View style={tableRow}>
                  <View
                    wrap={false}
                    style={[tableCell]}
                  >
                    <Text style={tableCellTitle}>Opslag</Text>
                    <View style={codeList}>
                      {storageList.map((item) => (
                        <ListItem text={item} />
                      ))}
                    </View>
                    <Text style={tableCellText}>&nbsp;</Text>
                    <Text style={tableCellTitle}>Hantering</Text>
                    <View style={codeList}>
                      {handlingList.map((item) => (
                        <ListItem text={item} />
                      ))}
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )}
          {selectedOptions.includes('6') && (
            <View style={tableSection}>
              <View
                style={sectionHeader}
                wrap={false}
                fixed
              >
                <Text style={sectionHeaderText}>Transportinformatie</Text>
              </View>

              <View
                wrap={false}
                style={tableContainer}
              >
                <View style={tableRow}>
                  <View
                    wrap={false}
                    style={[tableCell]}
                  >
                    <Text style={tableCellTitle}>UN-nummer</Text>
                    <Text style={tableCellText}>{unNumber}</Text>
                    <Text style={tableCellText}>&nbsp;</Text>
                    <Text style={tableCellTitle}>Ladingsnaam</Text>
                    <Text style={tableCellText}>{properShippingName}</Text>
                    <Text style={tableCellText}>&nbsp;</Text>
                    <Text style={tableCellTitle}>Verpakkingsgroep</Text>
                    <Text style={tableCellText}>{packagingGroup?.title}</Text>
                  </View>
                </View>
                <View style={tableRow}>
                  <View style={[tableCell, tableCellMedium]}>
                    <Text style={tableCellTitle}>Adr symbolen</Text>
                    <View style={tableCellImageContainer}>
                      {adrSymbols.map((symbol) => (
                        <Image
                          key={symbol.title}
                          src={symbol.path}
                          style={tableCellImage}
                        />
                      ))}
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )}
          {selectedOptions.includes('7') && (
            <View
              wrap={false}
              style={tableSection}
            >
              <View
                style={sectionHeader}
                fixed
                wrap={false}
              >
                <Text
                  wrap={false}
                  style={sectionHeaderText}
                >
                  Opmerkingen
                </Text>
              </View>
              <Text
                wrap={false}
                style={noteText}
              >
                {otherInformation}
              </Text>
            </View>
          )}
          <View
            fixed
            style={footNote}
          >
            <Text style={footNoteDate}>Bijgewerkt tot: {lastModifiedDate}</Text>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default WorkInstructionCardPDF;
